import { TailSpin } from 'react-loader-spinner';

export const Loading = () => (
  <div className="loading">
    {/* <center><TailSpin color="#e12329" height={180} width={180} /></center> */}
    <img
                        src={require("../../assets/loader.gif").default}
                        className="play-icon"
                      />

    <center></center>
  </div>
);