import React, { Component } from "react";
import { Link } from "react-router-dom";
import Text from "react-text";
import SearchInput, { createFilter } from "react-search-input";
import * as Constants from "../../Constants/index";
import axios from "axios";
import Modal from "react-modal";

import CookieConsent from "react-cookie-consent";
import { Loading } from "./loader.component";

import { reveal as Menu } from "react-burger-menu";
import MenuSlider from "../menu/menuslider.component";
import Login from "../../pages/login.component";
let arabicmodal =
  localStorage.getItem("language") === "ar" ? "arabicmodal" : "";
let crossarabic =
  localStorage.getItem("language") === "ar" ? "crossarabic" : "";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      activeTab: false,
      activeOverlay: false,
      menuOpen: false,
      
      searchResult: [],
      loading: true,
      showModal: false,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }
  componentDidMount() {
    if(localStorage.getItem('needLogin'))
    {
      this.setState({showModal:true});
      localStorage.removeItem('needLogin')
    }
    if (
      !localStorage.getItem("language") ||
      localStorage.getItem("language").trim() === ""
    ) {
      localStorage.setItem("language", "en");
    }
    // nowhshowing
    const token = localStorage.getItem("token");

    axios
      .post(Constants.api + "content/nowshowing", "", {
        headers: {
          "Accept-Language": localStorage.getItem("language"),
          Authorization: `Bearer ${token}`,
          appversion: Constants.av,
          platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
        },
      })
      .then((res) => {
        let nowShowing = [];

        console.log(res);
        Object.keys(res.data.output).forEach(function (key2) {
          console.log("nowshowing");
          nowShowing.push(res.data.output[key2]);
        });
        this.setState({
          searchResult: nowShowing,
          loading: false,
        });
        console.log(this.state.searchResult);
        // this.setState({
        //   nowShowing: res.data.output.cinemas,
        // });
      });
  }
  changeLang = () => {
    if (localStorage.getItem("language") === "en")
      localStorage.setItem("language", "ar");
    else localStorage.setItem("language", "en");
    window.location.reload();
  };
  closeOverlay = () => {
    return this.setState({
      activeOverlay: !this.state.activeOverlay,
      activeTab: !this.state.activeTab,
      searchTerm: "",
    });
  };
  handleLogout = () => {
    localStorage.setItem("needLogin",true);

    this.logout();

    // window.location = "/";
  };
  logout = () => {
    axios
      .post(
        Constants.api + "customer/logout",
        {
          refreshToken: localStorage.getItem("refreshtoken"),
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
          },
        }
      )
      .then((res) => {
        console.log(res);
        this.clearLocalStorageExcept("language");
        localStorage.setItem('needLogin',true)
        window.location = "/";
        // else window.location = "/login";
        // console.log(this.state.shows[0]["showTime"]);
      });
  };
  clearLocalStorageExcept = (keyToKeep) => {
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i);
      if (key !== keyToKeep) {
        localStorage.removeItem(key);
      }
    }
  };
  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    let searchTerm = this.state.searchTerm;
    let navmenuarabic =
      localStorage.getItem("language") === "ar" ? "nav-menu-arabic" : "";
    let searcharabic =
      localStorage.getItem("language") === "ar" ? "search-arabic" : "";

    console.log(localStorage.getItem(""));
    return (
      <>
        <nav
          className="header-nav justify-flex"
          style={{
            top: "0px",
            // position: this.state.menuOpen === true ? "fixed" : "fixed"  ,
            width: "100%",
            overflow: "hidden",
            position: "fixed",
            zIndex: "999999999999",
            backgroundColor: "#000"


          }}
        >
          <div className="logo">
            <Link to="/">
              <img
                src={require("../../assets/logo.png").default}
                alt="cinescape-logo"
                className="web-logo"
              />
              <img
                src={require("../../assets/logo-mobile.png").default}
                alt="cinescape-logo"
                className="mob-logo"
              />
            </Link>
          </div>
          <div className="nav-right justify-flex">
            <div
              className="search-input-contain"
              onClick={() =>
                this.setState({ activeTab: true, activeOverlay: true })
              }
            >
              <img
                src={require("../../assets/searchicon.png").default}
                className="search-icon"
              />
              {/* <i class="fa fa-search gray-info" aria-hidden="true"></i> */}
              <p className="search-tag">
                {localStorage.getItem("language") !== "ar" ? (
                  "SEARCH"
                ) : (
                  <Text>بحث</Text>
                )}
              </p>
            </div>
            {this.state.activeOverlay ? (
              <div className="overlay" onClick={this.closeOverlay}></div>
            ) : (
              ""
            )}

            {this.state.activeTab ? (
              <>
                <div className={`search-input-active ${searcharabic}`}>
                  <SearchInput
                    className="search-input"


                    // {localStorage.getItem("language") !== "ar" ? (
                    //   "HOME"
                    // ) : (
                    //   <Text>الصفحة الرئيسية</Text>
                    // )}



                    placeholder={localStorage.getItem("language") !== "ar" ? "Search" : "بحث"}

                    onChange={this.searchUpdated}
                  />
                </div>
                {this.state.searchResult.length > 0 ? (
                  <div className={`search-result ${searcharabic}`}>
                    {this.state.searchResult.map(function (item, i) {
                      let nameForUrl = item.title
                        .toString()
                        .toLowerCase()
                        .replaceAll(" ", "-");
                      let linkUrl =
                        "/moviesessions" +
                        "/" +
                        nameForUrl +
                        "/" +
                        item.id +
                        "?language=" +
                        item.language;
                      if (
                        item.title
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                        return (
                          <p>
                            <a href={linkUrl}> {item.title}</a>
                          </p>
                        );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {localStorage.getItem("language") !== "ar" ? (
              <div className="symbol" onClick={this.changeLang}>
                <a href="#">
                  <img src={require("../../assets/ع.png").default} />
                </a>
              </div>
            ) : (
              <div className="symbol" onClick={this.changeLang}>
                <a href="#">EN </a>
              </div>
            )}
            {localStorage.getItem("userid") == "" ||
              localStorage.getItem("userid") == "undefined" ||
              !localStorage.getItem("userid") ? (
              <>
                <a href="#">
                  <div
                    className="user-profile"
                    onClick={() => this.setState({ showModal: true })}
                  >
                    <img src={require("../../assets/user.png").default} />
                  </div>
                </a>
              </>
            ) : (
              <>
                <a href="/myaccount" className="profile-myaccount">
                  <div className="user-profile">
                    <img src={require("../../assets/user.png").default} />
                    &nbsp;&nbsp;
                    <p className="profile-name">
                      <a href="/myaccount">
                        {localStorage.getItem("username")}
                      </a>
                    </p>
                  </div>
                </a>
              </>
            )}

            {/* {localStorage.getItem("userid") != "" ? (
                <a href="/myaccount">
                  <img src={require("../../assets/user.png").default} />
                  <>
                    <p className="profile-name">
                      <a href="/myaccount">
                        {localStorage.getItem("username")}
                      </a>
                    </p>
                    &nbsp;
                  </>
                </a>
              ) : (
                <a href="/login">
                  <img src={require("../../assets/user.png").default} />
                </a>
              )} */}
            {/* <MenuSlider /> */}
            <div className={`nav-menu-detail ${navmenuarabic}`}>
              <div
                className="nav-menu pointer-cursor"
                onClick={() =>
                  this.setState({ menuOpen: !this.state.menuOpen })
                }
              >
                <span className="menu-content">
                  {localStorage.getItem("language") !== "ar" ? (
                    "MENU"
                  ) : (
                    <Text>القائمة</Text>
                  )}
                </span>
                <Link to="#">
                  {this.state.menuOpen ? (
                    <img
                      src={require("../../assets/Group 2671.png").default}
                      className="menu-cross"
                    />
                  ) : (
                    <img src={require("../../assets/hamburger.png").default} />
                  )}
                </Link>
              </div>
              {this.state.menuOpen ? (
                <div className="nav-menu-open">
                  <p>
                    <a href="/">
                      {localStorage.getItem("language") !== "ar" ? (
                        "HOME"
                      ) : (
                        <Text>الصفحة الرئيسية</Text>
                      )}
                    </a>
                  </p>
                  <p>
                    <a href="/movies">
                      {localStorage.getItem("language") !== "ar" ? (
                        "MOVIES"
                      ) : (
                        <Text>الأفلام</Text>
                      )}
                    </a>
                  </p>
                  <p>
                    <a href="/experiences">
                      {localStorage.getItem("language") !== "ar" ? (
                        "EXPERIENCE"
                      ) : (
                        <Text>التجارب </Text>
                      )}
                    </a>
                  </p>
                  <p>
                    <a href="/promotion">
                      {localStorage.getItem("language") !== "ar" ? (
                        "PROMOTIONS"
                      ) : (
                        <Text>العروض</Text>
                      )}
                    </a>
                  </p>
                  <p>
                    <a href="#">
                      {localStorage.getItem("language") !== "ar" ? (
                        "NEWS"
                      ) : (
                        <Text>الأخبار</Text>
                      )}
                    </a>
                  </p>
                  <p>
                    <a href="http://corporate.kncc.com/">
                      {localStorage.getItem("language") !== "ar" ? (
                        "CORPORATE"
                      ) : (
                        <Text>عن الشركة
                        </Text>
                      )}
                    </a>
                  </p>
                  <p>
                    <a href="/contactus">
                      {localStorage.getItem("language") !== "ar" ? (
                        "CONTACT US"
                      ) : (
                        <Text>تواصل معنا</Text>
                      )}
                    </a>
                  </p>
                  {localStorage.getItem("userid") ? (
                    <p>
                      <a href="#" onClick={() => this.handleLogout()}>
                        {localStorage.getItem("language") !== "ar" ? (
                          "LOGOUT"
                        ) : (
                          <Text>تسجيل خروج </Text>
                        )}
                      </a>

                      {/* <a href="#" onClick={() => this.handleLogout()}>
                        LOGOUT
                      </a> */}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </nav>

        <>
          <Modal
            isOpen={this.state.showModal}
            contentLabel="Example Modal"
            portalClassName={`trai-modal modal-name modal-login ${arabicmodal}`}
          >
            {" "}
            <button
              className={`trailer-cross modal-cross ${crossarabic}`}
              onClick={() =>
                this.setState({ showModal: false, autoplay: true })
              }
            >
              <i class="fas fa-times"></i>
            </button>
            <Login />
          </Modal>
        </>

        {/* <CookieConsent
          overlay
          location="top"
          buttonText="Accept & Continue"
          cookieName="cinescapecookie"
          style={{
            background: "#fff",
            color: "black",
            borderColor: "#9ecaed",
            boxShadow: "0 0 20px #ff0000",
          }}
          buttonStyle={{
            background: "#e12329",
            color: "white",
            fontSize: "18px",
            boxShadow: "0 0 8px #ff0000",
          }}
          expires={150}
        >
          <h3> This website uses cookies to enhance the user experience.</h3>
        </CookieConsent> */}
      </>
    );
  }
}

export default Header;
