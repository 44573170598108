
import React, { Component } from "react";

class TrailerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trailerModal: true,
      trailerUrl: this.props.trailerUrl,
      name: this.props.name,
    };
  }

  render() {

    console.log(this.state.trailerUrl);
    const trailer = this.state.trailerUrl.split("/");
    const trailerCode = trailer.slice(-1)[0];
    console.log(trailerCode);

    return (
      <>
        <div className="video-title">
          <img src="https://yt3.ggpht.com/ytc/AMLnZu9wZc5lH6Al6z1klVnb0TsEfR7Q3b4i7S5Zod9H8w=s88-c-k-c0x00ffffff-no-rj" className="logo-video" alt="logo"></img>
          <h5>{this.state.name}</h5>
        </div>

        <div className="video-player video-background">

          <iframe

            src={`https://www.youtube.com/embed/${trailerCode}?controls=0&showinfo=0&modestbrandig=1&autohide=1&rel`}

            //   src={this.state.trailerUrl}
            class="customyoutubeframe"
            title="YouTube video player"
            width="100%"
            height="550"

            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allow='autoplay; fullscreen'
            allowfullscreen
            frameborder="0"

          ></iframe>
          {/* <iframe width="853" height="480" src="https://www.youtube.com/embed/po6WgmsJidU" title="Avatar Way of Water" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}

          {/* <iframe width="853" height="480" src="https://www.youtube.com/embed/MWG6Q7IRHgE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
      // </div>
      </>
    );
  }
}


export default TrailerModal;
