import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="faq-banner seat-banner">
          <img src={require("../assets/my-account.png").default} alt="" />
          <h3>PRIVACY POLICY</h3>
        </div>
        <div className="faqs-container padbothvertical-40">
          <ul class="cc-faqs">
            <h3>INTRODUCTION</h3>
            <h4>Kuwait National Cinema Company (KNCC), Cinescape</h4>
            <p>
              Kuwait National Cinema Company (KNCC), Cinescape, was founded in
              October 5th 1954, and for more than half a century, has been the
              first exhibitor and distributor in the Gulf Region for Arabic,
              English, and Indian films. With more than 60 screens across more
              than 13 locations, KNCC became the Pioneer of the regions cinema
              industry and the prominent actor of the entertainment landscape in
              the State of Kuwait. KNCC lead the way by introducing the first
              ever online booking system in 2001, before the Middle-East,
              Africa, and Eastern Europe, allowing movie-goers to pre-book
              tickets with the freedom of reserving their favorite seat via the
              internet and the Emirate of Dubai followed a couple of years
              later. In addition, the first and only call center in the region
              to date was launched in 2004 where movie-goers could book tickets,
              and have all their inquiries and concerns answered around the
              clock, and shortly after, KNCC became only the second country in
              the world to initiate SMS Mobile Booking system known as M-Net in
              2005. With technology evolving rapidly, KNCC offered its customers
              the first Smart Phone application in 2011, which hit #1 in the
              Regional Store for Android and Apple users for more than 12 weeks
              with 60,000 plus downloads in the first 24 hours of its launch
              only and currently, the number stands at 350,000 users and
              counting! This cemented KNCC as the leader of the industry in
              offering a variety of methods to serve our movie-goers! Through
              these varied operations, KNCC from time to time gathers
              information from Customers and other parties with whom we deal for
              a variety of reasons including, in particular, to enable us to
              improve the nature of the services we provide. KNCC understands
              individuals' concerns regarding confidentiality and takes
              seriously its obligations in respect of all information it
              gathers. This Privacy Policy outlines how KNCC deals with Personal
              Information for KNCC website and KNCC Mobile Apps.
            </p>
            <h3>WHY KNCC COLLECTS PERSONAL INFORMATION</h3>
            <h4>Nature of Information</h4>
            <p>
              The information KNCC collects in most cases relates to Customers.
              Information is gathered for a range of purposes, including the
              provision of ongoing Customer support and informing customers the
              transaction details. The type of information we collect includes
              such things as contact details, and transactional details. KNCC
              treats all such information which is collected and identifiable as
              relating to an individual as Personal Information. It is this
              information which is described as “Personal Information” for the
              purposes of this Privacy Policy.
            </p>
            <h4>Collection of Information</h4>
            <p>
              KNCC frequently finds that it is necessary to collect Personal
              Information during the course of online transactions.
              <br />
              Personal Information may be collected about Customers via the
              following means:
            </p>
            <ul>
              <li>registration forms;</li>

              <li>application forms;</li>
              <li>competition entry forms;</li>
              <li>email;</li>
              <li>websites (including in some instances cookies);</li>
              <li>online transactions;</li>
              <li>over the phone</li>
            </ul>
            <h4>Information given to Customers</h4>
            <p>
              When Personal Information is collected from a Customer, they are
              advised at the time of collection or as soon as practical after
              its collection as to: <br />
              Personal Information may be collected about Customers via the
              following means:
            </p>
            <ul>
              <li>KNCC’s contact details;</li>

              <li>the purpose of collection;</li>

              <li>
                the fact that the Customer can gain access to the Personal
                Information;
              </li>

              <li>
                whether the Personal Information will be disclosed to overseas
                recipients;
              </li>
            </ul>
            <h4>Sensitive Information</h4>
            <p>
              KNCC will not ask a Customer for information revealing racial or
              ethnic origin, political opinions, religious or philosophical
              beliefs, trade union membership, or details of health, disability
              or sexual activity or orientation.
            </p>
            <h4>Customer consent</h4>
            <p>
              Wherever practical, KNCC will obtain the express consent of
              Customers to the collection of Personal Information.
              <br />
              In some situations, KNCC will interpret a Customer's actions as
              indicating that a Customer consents to the collection of Personal
              Information. This may occur where the Customer has provided
              Personal Information for a particular purpose and that purpose
              cannot be achieved without KNCC making use of the Customer's
              Personal Information.
            </p>
            <h4>What happens if Personal Information is not provided?</h4>
            <p>
              Where relevant, KNCC will endeavor to make Customers aware that
              KNCC may not be able to provide a proper service to them if
              Personal Information is not provided upon request.
            </p>
            <h3>MAINTENANCE AND MANAGEMENT OF PERSONAL INFORMATION</h3>
            <h4>Who may view Personal Information</h4>
            <p>
              The information KNCC collects in most cases relates to Customers.
              Information is gathered for a range of purposes, including the
              provision of ongoing Customer support and informing customers the
              transaction details. The type of information we collect includes
              such things as contact details, and transactional details. KNCC
              treats all such information which is collected and identifiable as
              relating to an individual as Personal Information. It is this
              information which is described as “Personal Information” for the
              purposes of this Privacy Policy.
            </p>
            <h4>Identification</h4>
            <p>
              KNCC does not use any identifiers (such as a Civil ID number or
              Bank Card number) to identify its Customers.
            </p>
            <h4>Storage and Security</h4>
            <p>
              Personal Information may be stored in either hard copy form or on
              computer or both. Personal Information stored on computer is
              wherever practical, password protected. Where possible and
              practical, KNCC will endeavor to comply with recognized Kuwait and
              international standards relating to information security. KNCC
              regularly deletes and de-identifies Personal Information if the
              information is no longer needed by KNCC.
            </p>
            <h4>Updating Personal Information</h4>
            <p>
              Customers can update their Personal Information at any time using
              KNCC’s website or mobile apps.
            </p>
            <h3>ACCESS TO PERSONAL INFORMATION</h3>
            <p>
              Customers may access their Personal Information by logging into
              KNCC’s website or mobile app.
            </p>
            <h3>WHEN KNCC MAY WITHHOLD ACCESS TO PERSONAL INFORMATION</h3>
            <h4>When access can be withheld</h4>
            <p>
              Access to Personal Information may be withheld in a number of
              circumstances. These include where:
            </p>
            <ul>
              <li>
                providing access would have an unreasonable impact on the
                privacy of others;
              </li>
              <li>the request is vexatious or frivolous;</li>
              <li>
                customer will be denied to login from KNCC’s website or mobile
                apps if the customer is found to misuse KNCC’s website or mobile
                apps. Misuse may be in form of trying to make fraudulent
                transactions, tamper website or mobile apps in any manner,
                create disturbing scenes at cinema premises. Under these
                circumstances, KNCC reserves the right to delete/disable the
                customer account without prior notification to the customer.
              </li>
            </ul>
            <h3>WHO TO CONTACT</h3>
            <p>
              KNCC is committed to working with its Customers to obtain a fair
              resolution of any complaint or concern about privacy. To contact
              us with a compliment or complaint or a privacy question, you can:
            </p>
            <ul>
              <li>Email us at: ask@cinescape.com.kw</li>
            </ul>
            <h3>MODIFICATIONS TO THIS DOCUMENT</h3>
            <p>
              Any changes to our Privacy Policy will be posted on qa.kncc.com
              and with respect to any Personal Information and other information
              we collect in the future, will become effective as of the date of
              posting (see date posted above). It is your responsibility to
              review the Privacy Policy from time to time to make sure you are
              aware of any changes.
            </p>
          </ul>
        </div>
      </>
    );
  }
}
export default Privacy;
