import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import Text from "react-text";
import Modal from "react-modal";

class screenx extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="experience-banner">
          <img src={require("../../assets/bg-topbanner (1).jpg").default} />
        </div>
        <div className="experience-page home-content-container">
          <div className="experience-title padbothvertical-40 p-b-0">
            <div className="experience-head m-b-0 screenx-head">
              <h3>  {localStorage.getItem("language") !== "ar" ? (
                "WHAT IS "
              ) : (
                <Text>ما هو</Text>
              )}</h3>
              <img src={require("../../assets/bg-title01.png").default} />

            </div>

          </div>
          <div className="experience-white-bg padbothvertical-40">
            <div className="experience-info-box align-items">
              <div className="exp-info-img">
                <img src={require("../../assets/bg-fold1-imgrgt.jpg").default} />
              </div>
              <div className="exp-info-box">

                <div className="exp-info-box-detail">
                  <p><span className="color-screen">    {localStorage.getItem("language") !== "ar" ? (
                    "ScreenX"
                  ) : (
                    <Text>سكرين اكس</Text>
                  )}</span>    {localStorage.getItem("language") !== "ar" ? (
                    "is the world's first multi-projection immersive cinematic platform which provides moviegoers a"
                  ) : (
                    <Text>هي أول منصة سينمائية غامرة متعددة العرض في العالم توفر لرواد السينما أ</Text>
                  )} <span className="color-screen">     {localStorage.getItem("language") !== "ar" ? (
                    "270-degree viewing experience"
                  ) : (
                    <Text>تجربة مشاهدة 270 درجة</Text>
                  )}</span>   {localStorage.getItem("language") !== "ar" ? (
                    "by expanding the scene onto the side walls."
                  ) : (
                    <Text>من خلال توسيع المشهد على الجدران الجانبية.</Text>
                  )}</p>

                </div>
              </div>
            </div>
          </div>



          <div className="experience-grey-bg">
            <div className="experience-info-box">
              <div className="exp-info-box screenx-box">
                <div className="experience-head m-b-0 screenx-head">

                  <img src={require("../../assets/bg-title01.png").default} />
                  <h3>{localStorage.getItem("language") !== "ar" ? (
                    "CONCEPT"
                  ) : (
                    <Text>مفهوم</Text>
                  )}</h3>

                </div>

                <div className="screenx-concept">
                  <div className="screenx-body">
                    <div className="screen-img">
                      <img src={require("../../assets/screen-concept1.gif").default} />
                    </div>
                    <h5 className="text-center">{localStorage.getItem("language") !== "ar" ? (
                      "EXPAND"
                    ) : (
                      <Text>يوسع</Text>
                    )}</h5>

                    <p className="text-center">{localStorage.getItem("language") !== "ar" ? (
                      "Expand space allows for more creative possibilities."
                    ) : (
                      <Text>تسمح المساحة المتسعة بإمكانيات أكثر إبداعًا.</Text>
                    )}</p>

                  </div>

                  <div className="screenx-body">
                    <div className="screen-img">
                      <img src={require("../../assets/screen-concept2.gif").default} />
                    </div>
                    <h5 className="text-center">  {localStorage.getItem("language") !== "ar" ? (
                      "EXPRESS"
                    ) : (
                      <Text>يعبر</Text>
                    )}</h5>
                    <p className="text-center">{localStorage.getItem("language") !== "ar" ? (
                      "3 sides of the theatre are used express more of the story."
                    ) : (
                      <Text>3 جوانب من المسرح تستخدم للتعبير عن المزيد من القصة.</Text>
                    )}</p>

                  </div>

                  <div className="screenx-body">
                    <div className="screen-img">
                      <img src={require("../../assets/screen-concept3.gif").default} />
                    </div>
                    <h5 className="text-center">{localStorage.getItem("language") !== "ar" ? (
                      "EXPERIENCE"
                    ) : (
                      <Text>خبرة</Text>
                    )}</h5>
                    <p className="text-center"> {localStorage.getItem("language") !== "ar" ? (
                      "3-sided surrounding images provide the most immersive experience."
                    ) : (
                      <Text>توفر الصور المحيطة ثلاثية الجوانب تجربة غامرة أكثر.</Text>
                    )}</p>

                  </div>

                </div>


              </div>

            </div>
          </div>

          <div className="experience-white-bg">
            <div className="experience-head m-b-0 screenx-head">
              <h3>  {localStorage.getItem("language") !== "ar" ? (
                "WHAT IS "
              ) : (
                <Text>ما هو</Text>
              )}</h3>
              <img src={require("../../assets/bg-title01.png").default} />

            </div>
            <div className="experience-info-box">


              <div className="exp-info-img screenx-img">
                <img src={require("../../assets/bg-sxaudi-left.jpg").default} />
                <img
                  src={
                    require("../../assets/bg-sxaudi-right.jpg")
                      .default
                  }
                />


              </div>

            </div>
          </div>



        </div>
      </>
    );
  }
}
export default screenx;
