import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./promotion.style.css";
import Text from "react-text";
import * as Constants from "../Constants/index";
import axios from "axios";
import Modal from "react-modal";
class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoTab: "faq",
      activeIdx: 0,
      cinemas: [],
      ratings: [],
      tncs: [],
      pp: [],
      faqs: [],
      showmap: false
    };
  }
  componentDidMount() {
    const token = localStorage.getItem("token");

    axios
      .post(Constants.api + "more/tabs", "", {
        headers: {
          "Accept-Language": localStorage.getItem("language"),
          Authorization: `Bearer ${token}`,
          appversion: Constants.av,
          platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
        },
      })
      .then((res) => {
        console.log(res);

        console.log("more");
        this.setState({
          cinemas: res.data.output.cinemas,
          ratings: res.data.output.ratings,

          tncs: res.data.output.tncs,
          pp: res.data.output.privacy,
          faqs: res.data.output.faqs,

        });
      });
  }
  render() {

    const openmap = (lat, long) => {
      let map =
        "https://www.google.com/maps/embed/v1/place?key=AIzaSyAgURva0yoMANv5oC9zAsn_bCMuZGLlSFc&q=" +
        lat +
        "," +
        long;
      this.setState({ mapurl: map, showmap: true });
    };

    console.log(this.state.infoTab);
    return (
      <>
        <div className="faq-banner seat-banner">
          {" "}
          <img src={require("../assets/my-account.png").default} alt="" />
          <h3>
            {localStorage.getItem("language") !== "ar" ? (
              "INFORMATION"
            ) : (
              <Text>الشروط والأحكام
              </Text>
            )}
          </h3>{" "}
        </div>
        <div className="black-width"></div>
        <div className="tab-information">
          <div className="privacy-info privacy-tabs">
            <ul class="react-tabs__tab-list" role="tablist">

            <li
                class={`${this.state.infoTab === "tc"
                  ? "react-tabs__tab tab-selected"
                  : "react-tabs__tab"
                  }`}
                onClick={() => this.setState({ infoTab: "tc" })}
              >
                <img src={require("../assets/terms2.png").default} alt="" />
                <img src={require("../assets/Group 2929.png").default} alt="" />
                <p>
                  {localStorage.getItem("language") !== "ar" ? (
                    "TERMS & CONDITIONS"
                  ) : (
                    <Text>الشروط والأحكام
                    </Text>
                  )}
                </p>
              </li>

              <li
                class={`${this.state.infoTab === "age"
                  ? "react-tabs__tab tab-selected"
                  : "react-tabs__tab"
                  }`}
                onClick={() => this.setState({ infoTab: "age" })}
              >
                <img src={require("../assets/terms1.png").default} alt="" />
                <img src={require("../assets/Group 2928.png").default} alt="" />
                <p>
                  {" "}
                  {localStorage.getItem("language") !== "ar" ? (
                    "AGE RATING"
                  ) : (
                    <Text>الفئات العمرية
                    </Text>
                  )}
                </p>
              </li>
            
              <li
                class={`${this.state.infoTab === "pp"
                  ? "react-tabs__tab tab-selected"
                  : "react-tabs__tab"
                  }`}
                onClick={() => this.setState({ infoTab: "pp" })}
              >
                <img src={require("../assets/terms3.png").default} alt="" />
                <img src={require("../assets/Group 2931.png").default} alt="" />

                <p>
                  {localStorage.getItem("language") !== "ar" ? (
                    "PRIVACY POLICY"
                  ) : (
                    <Text>سياسة الخصوصية
                    </Text>
                  )}
                </p>
              </li>
              <li
                class={`${this.state.infoTab === "faq"
                  ? "react-tabs__tab tab-selected"
                  : "react-tabs__tab"
                  }`}
                onClick={() => this.setState({ infoTab: "faq" })}
              >
                <img src={require("../assets/terms4.png").default} alt="" />
                <img
                  src={
                    require("../assets/Icon material-question-answer.png")
                      .default
                  }
                  alt=""
                />
                <p>
                  {" "}
                  {localStorage.getItem("language") !== "ar" ? (
                    "FAQ"
                  ) : (
                    <Text>أسئلة شائعة
                    </Text>
                  )}
                </p>
              </li>
              <li
                class={`${this.state.infoTab === "loc"
                  ? "react-tabs__tab tab-selected"
                  : "react-tabs__tab"
                  }`}
                onClick={() => this.setState({ infoTab: "loc" })}
              >
                <img src={require("../assets/terms5.png").default} alt="" />
                <img
                  src={require("../assets/Icon metro-location.png").default}
                  alt=""
                />

                <p>
                  {localStorage.getItem("language") !== "ar" ? (
                    "LOCATIONS"
                  ) : (
                    <Text>المواقع</Text>
                  )}
                </p>
              </li>
            </ul>
          </div>
          {this.state.infoTab === "age" ? (
            <div class="infomation_general">

              <h2>
                {localStorage.getItem("language") !== "ar" ? (
                  "AGE RULES"
                ) : (
                  <Text>الفئات العمرية </Text>
                )}
              </h2>


              <p>
                {localStorage.getItem("language") !== "ar" ? (
                  "In Compliance with the Ministry of Information’s resolution number 30 / 2016 with regards to manage & prohibit the admission of children into the cinema. Cinescape management has the right to check official identification carrying a photo before allowing the child to watch the movie. Customers younger than the stipulated age / movie rating by the Ministry of Information will NOT BE ALLOWED to watch the movie."
                ) : (
                  <Text>امتثالاً لقرار وزارة الإعلام رقم 30/2016 بشأن إدارة وحظر دخول الأطفال إلى السينما. يحق لإدارة سينسكيب التحقق من الهوية الرسمية التي تحمل صورة قبل السماح للطفل بمشاهدة الفيلم. لن يُسمح للعملاء الأصغر من العمر / تصنيف الفيلم المحدد من قبل وزارة الإعلام بمشاهدة الفيلم.     </Text>
                )}
              </p>



              {this.state.ratings.map(function (item, i) {

                return (
                  <div class="general_info">
                    <div className="rating-box">
                      <div className="start-rating">
                        {item.more_key.length > 0 ?
                          <span class="color_g color" style={{ backgroundColor: `${item.colorCode}` }}>{item.more_key}</span> : ''}
                      </div>
                      {/* <div className="rating-title">
                        <h3>

                          {item.name}

                        </h3>
                      </div> */}
                    </div>
                    <p>
                      {item.description}
                    </p>
                  </div>

                )

              }

              )}

            </div>
          ) : (
            ""
          )}
          {this.state.infoTab === "tc" ? (
            <div class="infomation_section">
              <div class="infomation_general term_info">

                {this.state.tncs.map(function (item, i) {

                  return (
                    <div class="age_rules privacy-text border-terms">
                      <h2>
                        {item.name}
                      </h2>
                      <p>
                        {item.description}
                      </p>



                    </div>

                  )

                }

                )}

              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.infoTab === "pp" ? (
            <div class="infomation_section">
              <div class="infomation_general term_info privacy_policy_info">


                {this.state.pp.map(function (item, i) {

                  return (
                    <div class="age_rules privacy-text">
                      <h2>
                        {item.name}
                      </h2>
                      <p>
                        {item.description}
                      </p>

                    </div>

                  )

                }

                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.infoTab === "faq" ? (
            <div class="infomation_section">
              <div class="infomation_general term_info privacy_policy_info ">

                {this.state.faqs.map(function (item, i) {

                  return (
                    <div class="age_rules privacy-text">
                      <h2>{item.name}</h2>
                      {item.faqs.map(function (item, i) {

                        return (<>
                          {/* <h3 class="m-b-15">{item.name}</h3> */}
                          <h4>
                            {item.ques}                      </h4>
                          <p className="m-l-15">
                            {" "}
                            {item.answer}{" "}
                          </p></>)
                      })}
                    </div>

                  )

                }

                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.infoTab === "loc" ? (
            <div className="location-content new-location">

              <div class='home-content-container'>
                <h2>
                  {localStorage.getItem("language") !== "ar" ? (
                    "OUR LOCATIONS"
                  ) : (
                    <Text>مواقع سينسكيب</Text>
                  )}
                </h2>
                <div className="cinescap_info">
                  {this.state.cinemas.map(function (item, i) {
                    let url = "/cinemasessions/" + item.id
                    return (
                      <>
                        <div className="cinescap_location">
                          <div className="flim_info img-location">
                            <a href={url} > <img
                              src={item.webThumbImageUrl}
                              alt="img"
                            /></a>
                            <div className="house_text">
                              <h3>{item.name}</h3>
                              <div className="location_info">
                                <h4>
                                  {" "}
                                  {localStorage.getItem("language") !== "ar" ? (
                                    "Location"
                                  ) : (
                                    <Text>موقع</Text>
                                  )}
                                </h4>
                                <p>{item.city} </p>
                                <p
                                  className="map"
                                  onClick={() =>
                                    openmap(item.latitude, item.longitude)
                                  }
                                >
                                  <div className="img-map">
                                    <img
                                      src={require("../assets/location.png").default}
                                    />
                                  </div>
                                  <div className="text-map">
                                    {" "}

                                    {localStorage.getItem("language") !== "ar" ? (

                                      "Maps"
                                    ) :
                                      (
                                        <Text>خرائط</Text>
                                      )}
                                  </div>
                                </p>
                              </div>

                              <div className="cinescap_imgs">
                                <div className="cinescap_img-logo">
                                  <img
                                    src={require("../assets/Standard Normal@2x.png").default}
                                    alt="img"
                                  />
                                </div>
                                <div className="cinescap_img-logo">
                                  <img
                                    src={require("../assets/VIP Normal@2x.png").default}
                                    alt="img"
                                  />
                                </div>
                                <div className="cinescap_img-logo">
                                  <img
                                    src={require("../assets/Premium Normal@2x.png").default}
                                    alt="img"
                                  />
                                </div>
                                <div className="cinescap_img-logo">
                                  <img
                                    src={require("../assets/Dolby Cinema Normal@2x.png").default}
                                    alt="img"
                                  />
                                </div>
                                <div className="cinescap_img-logo">
                                  <img
                                    src={require("../assets/IMAX Normal@2x.png").default}
                                    alt="img"
                                  />
                                </div>
                                <div className="cinescap_img-logo">
                                  <img
                                    src={require("../assets/4DX Normal@2x.png").default}
                                    alt="img"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>





              {/* <Tabs>


                <TabList>
                  <div className="tab-horizontal">
                    {this.state.cinemas.map(function (item, i) {
                      return <> <Tab>{item.name} </Tab>   </>
                    })}

                  </div>
                </TabList>

                <div className="tab-content-section">
                  {this.state.cinemas.map(function (item, i) {

                    return <TabPanel>

                      <div className="tab-content">
                        <h2>
                          <iframe
                            width="970"
                            height="400"
                            frameborder="0"
                            scrolling="no"
                            marginheight="0"
                            marginwidth="0"
                            id="gmap_canvas"
                            src={`https://maps.google.com/maps?q='+${item.latitude}+','+${item.latitude}+'&t=&z=15&ie=UTF8&iwloc=&output=embed`}

                          ></iframe>{" "}

                          <script
                            type="text/javascript"
                            src="https://embedmaps.com/google-maps-authorization/script.js?id=86c3b9b3007475b630bc75d936f4041e37b78a7b"
                          ></script>
                        </h2>{" "}
                      </div>
                    </TabPanel>
                  })}
                </div>

              </Tabs> */}


            </div>
          ) : (
            ""
          )}
        </div>
        {
          this.state.showmap ? (
            <>

              <Modal
                isOpen={this.state.showmap}
                contentLabel="Example Modal"
                portalClassName="trai-modal modal-map"
              >
                {" "}
                <button
                  className="trailer-cross trailer-croos-one"
                  onClick={() => this.setState({ showmap: false })}
                >
                  <i class="fas fa-times fa-fa-time-one"></i>
                </button>
                <iframe
                  src={this.state.mapurl}
                  width="100%"
                  height="500"
                ></iframe>
              </Modal>

            </>
          ) : (
            ""
          )
        }
      </>
    );
  }
}
export default Faq;
