import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import Text from "react-text";
import Modal from "react-modal";

class fourdx extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="experience-banner">
          <img src={require("../../assets/4dxbanner.jpg").default} />
        </div>
        <div className="experience-page home-content-container">
          <div className="experience-title padbothvertical-40 p-b-0">
            <div className="experience-head m-b-0">
              <h3>   {localStorage.getItem("language") !== "ar" ? (
                "WHAT IS 4DX?"
              ) : (
                <Text>ما هو 4DX؟</Text>
              )}</h3>
            </div>

          </div>
          <div className="experience-white-bg padbothvertical-40">
            <div className="experience-info-box">
              <div className="exp-info-img">
                <img src={require("../../assets/bg-4dx.jpg").default} />
              </div>
              <div className="exp-info-box">

                <div className="exp-info-box-detail">
                  <p>

                    {localStorage.getItem("language") !== "ar" ? (
                      " Advancing the movie theater experience from watching the movie to almost living it, 4DX is the ultimate in state-of-the-art technology delivering a fully immersive cinematic experience. Inside every dedicated 4DX auditorium, motion chairs and environmental effects such as wind, bubbles, and scent work in perfect synchronicity with the action on screen."
                    ) : (
                      <Text>تقدم تجربة السينما السينمائية من مشاهدة الفيلم إلى عيشه تقريبًا ، 4DX هي الأفضل في أحدث التقنيات التي تقدم تجربة سينمائية غامرة بالكامل. داخل كل قاعة مخصصة 4DX ، تعمل الكراسي المتحركة والمؤثرات البيئية مثل الرياح والفقاعات والرائحة بتزامن مثالي مع الحركة على الشاشة.</Text>
                    )}
                  </p>
                  <p>
                    {/* With only the best technologies, 4DX motion chairs are equipped with three base movements of heave (move up and down), roll (move left and right) and pitch (tilt backward and forward) which can create an endless expanse of possible combinations to mimic such actions as flying and driving. The skilled team of 4DX editors, "i-Studio", are experts in maximizing the feeling of immersion within every movie without overstepping comfort bounds.  */}

                    {localStorage.getItem("language") !== "ar" ? (
                      " With only the best technologies, 4DX motion chairs are equipped with three base movements of heave (move up and down), roll (move left and right) and pitch (tilt backward and forward) which can create an endless expanse of possible  combinations to mimic such actions as flying and driving. The skilled team of 4DX editors, i-Studio, are experts in maximizing the feeling of immersion within every movie without overstepping comfort bounds.  "
                    ) : (
                      <Text>مع أفضل التقنيات فقط ، تم تجهيز الكراسي المتحركة 4DX بثلاث حركات أساسية من heave (تحريك لأعلى ولأسفل) ، ولف (تحريك لليسار واليمين) والميل (إمالة للخلف وللأمام) والتي يمكن أن تخلق مساحة لا نهاية لها من المجموعات الممكنة لتقليد مثل هذه الإجراءات مثل الطيران والقيادة. إن الفريق الماهر من محرري 4DX ، "i-Studio" ، هم خبراء في زيادة الشعور بالانغماس في كل فيلم دون تجاوز حدود الراحة.</Text>
                    )}

                  </p>
                  <p>

                    {localStorage.getItem("language") !== "ar" ? (
                      "  Movies are now no longer bound by their visual and aural limits. 4DX is the next step in the movie-going evolution taking audiences on a journey into the full feature film. Free yourself from the confines of the flat screen and live the experience with 4DX. "
                    ) : (
                      <Text>لم تعد الأفلام الآن مقيدة بحدودها المرئية والسمعية. 4DX هي الخطوة التالية في تطور السينما حيث تأخذ الجماهير في رحلة إلى الفيلم الروائي الكامل. حرر نفسك من قيود الشاشة المسطحة واستمتع بتجربة 4DX.</Text>
                    )}
                  </p>

                  <p> {localStorage.getItem("language") !== "ar" ? (
                    "With its ceaselessly growing brand value, 4DX is recognized as a mainstream option in the global premium cinema marketplace and a leader in the newest trends of the next-generation movie theatre."
                  ) : (
                    <Text>من خلال قيمة علامتها التجارية المتزايدة باستمرار ، يتم التعرف على 4DX كخيار رئيسي في سوق السينما العالمية المتميز ورائد في أحدث الاتجاهات في السينما من الجيل التالي.</Text>
                  )}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="experience-grey-bg">
            <div className="experience-info-box">
              <div className="exp-info-box">
                <h5 className="text-center">   {localStorage.getItem("language") !== "ar" ? (
                  "IT'S REAL 4D MOVIE!"
                ) : (
                  <Text>إنه فيلم حقيقي رباعي الأبعاد!</Text>
                )}</h5>
                <h4>{localStorage.getItem("language") !== "ar" ? (
                  "20 STUNNING REALISTIC EFFECTS"
                ) : (
                  <Text>20 تأثيرات واقعية مذهلة</Text>
                )}</h4>
                <div className="exp-info-box-detail">
                  <h4 className="text-left">   {localStorage.getItem("language") !== "ar" ? (
                    "Motion Seats"
                  ) : (
                    <Text>مقاعد الحركة</Text>
                  )}</h4>
                  <p>

                    {localStorage.getItem("language") !== "ar" ? (
                      " Each chair is equipped with motion simulators and programmed for three basic movements. Through the combination of these movements in various degrees, a fluid and dynamic motion is created to mimic the action on the screen."
                    ) : (
                      <Text>كل كرسي مجهز بأجهزة محاكاة الحركة ومبرمج لثلاث حركات أساسية. من خلال الجمع بين هذه الحركات بدرجات مختلفة ، يتم إنشاء حركة سلسة وديناميكية لتقليد الحركة على الشاشة.</Text>
                    )}


                  </p>




                </div>
                <div className="exp-info-box-img motion-seats">
                  <img src={require("../../assets/stunning-list.png").default} />
                </div>
              </div>
              <div className="exp-info-img ">
                <img
                  src={
                    require("../../assets/bg-realisticeffects.jpg")
                      .default
                  }
                />
              </div>
            </div>
          </div>
          <div className="experience-white-bg">
            <div className="experience-info-box">
              <div className="exp-info-img">
                <img src={require("../../assets/bg-environmentaleffects.jpg").default} />
              </div>
              <div className="exp-info-box">
                <h4>  {localStorage.getItem("language") !== "ar" ? (
                  "Environmental Effects "
                ) : (
                  <Text>   {localStorage.getItem("language") !== "ar" ? (
                    "Environmental Effects"
                  ) : (
                    <Text>تأثيرات بيئيه</Text>
                  )}</Text>
                )}</h4>
                <div className="exp-info-box-detail">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "In addition to the movements, the theater is equipped with environmental effects to further enhance the experience. From a malicious storm to a gentle breeze, from the smoky explosions to the warm smell of coffee, the 4DX technology  enhances these experiences with appropriate effects."
                    ) : (
                      <Text>بالإضافة إلى الحركات ، تم تجهيز المسرح بتأثيرات بيئية لزيادة تعزيز التجربة. من عاصفة خبيثة إلى نسيم لطيف ، من الانفجارات المدخنة إلى رائحة القهوة الدافئة ، تعزز تقنية 4DX هذه التجارب بتأثيرات مناسبة.</Text>
                    )}

                  </p>

                </div>
                <div className="exp-info-box-img fourdx-environmental">
                  <img
                    src={
                      require("../../assets/envoronment-effect-list.png")
                        .default
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default fourdx;
