import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import Text from "react-text";
import Modal from "react-modal";

class experienceimax extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="experience-banner">
          <img src={require("../../assets/bg-topbanner (2).jpg").default} />
        </div>
        <div className="experience-page home-content-container">
          <div className="experience-title padbothvertical-40 p-b-0 ">
            <div className="experience-head m-b-0">
              <h3>   {localStorage.getItem("language") !== "ar" ? (
                "IMAX CAMERAS/ASPECT RATIO"
              ) : (
                <Text>كاميرات IMAX / نسبة العرض إلى الارتفاع</Text>
              )}</h3>
            </div>

          </div>
          <div className="experience-white-bg padbothvertical-40 p-t-0">
            <div className="experience-info-box align-items">
              <div className="exp-info-img">
                <img src={require("../../assets/bg-IMX.jpg").default} />
              </div>
              <div className="exp-info-box ">

                <div className="exp-info-box-detail">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      " The IMAX expanded aspect ratio is 1:90:1, which offers up to 26% more picture for select sequences compare to the standard screen, meaning more of the action is visible on screen, just as the filmmakers intended."
                    ) : (
                      <Text>نسبة العرض إلى الارتفاع الموسعة لـ IMAX هي 1: 90: 1 ، والتي توفر ما يصل إلى 26٪ صورة أكثر لتسلسلات محددة مقارنة بالشاشة القياسية ، مما يعني أن المزيد من الحركة مرئية على الشاشة ، تمامًا كما أراد صانعو الأفلام.</Text>
                    )}</p>

                </div>
              </div>
            </div>
          </div>

          <div className="experience-head">
            <h3>  {localStorage.getItem("language") !== "ar" ? (
              "HEART-POUNDING AUDIO "
            ) : (
              <Text>صوت ينبض بالقلب</Text>
            )}</h3>
          </div>

          <div className="experience-grey-bg">

            <div className="experience-info-box">
              <div className="exp-info-box">

                <h4>  {localStorage.getItem("language") !== "ar" ? (
                  "IMAX Sounds"
                ) : (
                  <Text>أصوات IMAX</Text>
                )}</h4>
                <div className="exp-info-box-detail">
                  <h4 className="text-left">   {localStorage.getItem("language") !== "ar" ? (
                    "Motion Seats"
                  ) : (
                    <Text>مقاعد الحركة</Text>
                  )}</h4>
                  <p>


                    {localStorage.getItem("language") !== "ar" ? (
                      "  An immense explosion. A whisper heard over your shoulder. The rumbling bass you feel instead of hear. The combination of our perfectly tuned integrated sound system and our precise speaker orientation ensures you can hear a pin drop and be able to tell exactly where it landed."
                    ) : (
                      <Text>انفجار هائل. سمعت همسة فوق كتفك. صوت الجهير الهادر الذي تشعر به بدلاً من سماعه. يضمن الجمع بين نظام الصوت المدمج المضبوط تمامًا والتوجيه الدقيق للسماعات أنه يمكنك سماع قطرة الدبوس وأن تكون قادرًا على تحديد مكان هبوطها بالضبط.</Text>
                    )}
                  </p>


                </div>
                <div className="exp-info-box-img motion-seats">
                  <img src={require("../../assets/bg-imaxsounds-content.png").default} />
                </div>
              </div>
              <div className="exp-info-img ">
                <img
                  src={
                    require("../../assets/bg-imax-sounds.jpg")
                      .default
                  }
                />
              </div>
            </div>
          </div>



          <div className="experience-head p-t-40 m-b-0">
            <h3>   {localStorage.getItem("language") !== "ar" ? (
              "AWE-INSPIRING IMAGES"
            ) : (
              <Text>صور مذهلة</Text>
            )}</h3>
          </div>
          <div className="experience-white-bg">
            <div className="experience-info-box">
              <div className="exp-info-img">
                <img src={require("../../assets/bg-imax-picture.jpg").default} />
              </div>
              <div className="exp-info-box">
                <h4>  {localStorage.getItem("language") !== "ar" ? (
                  "IMAX Picture "
                ) : (
                  <Text>صورة IMAX</Text>
                )}</h4>
                <div className="exp-info-box-detail">
                  <p>


                    {localStorage.getItem("language") !== "ar" ? (
                      "Clarity, detail and size make IMAX more than a movie. Our remastering – or DMR – process fully transforms every frame of a film to produce the best possible version of a filmmaker’s vision. Two projectors run simultaneously to provide the perfect image with a balance of warmth and sharpness. IMAX draws you into something as close to reality as you have ever experienced."
                    ) : (
                      <Text>الوضوح والتفاصيل والحجم تجعل IMAX أكثر من مجرد فيلم. تعمل عملية إعادة الصياغة - أو DMR - على تحويل كل إطار للفيلم بالكامل لإنتاج أفضل نسخة ممكنة من رؤية المخرج. يعمل جهازي عرض في وقت واحد لتوفير صورة مثالية مع توازن بين الدفء والحدة. يجذبك IMAX إلى شيء قريب من الواقع كما سبق لك أن جربته.</Text>
                    )}


                  </p>

                </div>
                <div className="exp-info-box-img fourdx-environmental">
                  <img
                    src={
                      require("../../assets/bg-imaxpicture-content.png")
                        .default
                    }
                  />
                </div>
              </div>
            </div>
          </div>




          <div className="experience-grey-bg">

            <div className="experience-info-box">
              <div className="exp-info-box">

                <h4>   {localStorage.getItem("language") !== "ar" ? (
                  "IMAX Sounds"
                ) : (
                  <Text>أصوات IMAX</Text>
                )}</h4>
                <div className="exp-info-box-detail">
                  <h4 className="text-left">  {localStorage.getItem("language") !== "ar" ? (
                    "IMAX Theatre "
                  ) : (
                    <Text>مسرح IMAX</Text>
                  )}</h4>
                  <p>

                    {localStorage.getItem("language") !== "ar" ? (
                      " Seeing a movie in an IMAX® theatre is so much more than watching a movie. That's what we call The IMAX Experience®. Every element in our theatres is planned, designed and positioned with exacting standards to create the most intense experience. This science is our theatre geometry, and it ensures movie magic every time the lights go down."
                    ) : (
                      <Text>إن مشاهدة فيلم في مسرح IMAX® هو أكثر بكثير من مجرد مشاهدة فيلم. هذا ما نسميه The IMAX Experience®. كل عنصر في مسارحنا يتم تخطيطه وتصميمه ووضعه وفقًا لمعايير صارمة لخلق تجربة أكثر كثافة. هذا العلم هو هندسة المسرح لدينا ، وهو يضمن سحر الفيلم في كل مرة تنطفئ فيها الأضواء.</Text>
                    )}

                  </p>


                </div>
                <div className="exp-info-box-img motion-seats">
                  <img src={require("../../assets/bg-imaxtheatre-content.png").default} />
                </div>
              </div>
              <div className="exp-info-img ">
                <img
                  src={
                    require("../../assets/bg-imax-theatre.jpg")
                      .default
                  }
                />
              </div>
            </div>
          </div>



        </div>
      </>
    );
  }
}
export default experienceimax;
