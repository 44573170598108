import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class FAQs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="faq-banner seat-banner">
          <img src={require("../assets/my-account.png").default} alt="" />
          <h3>FREQUENTLY ASKED QUESTIONS</h3>
        </div>
        <div className="faqs-container padbothvertical-40">
          <ul class="cc-faqs">
            <h3>General</h3>
            <li>
              <h4>
                What are the rules and regulation of covid-19 in the cinema?{" "}
              </h4>
              <ul>
                <li>
                  Only vaccinated or recovered individuals can enter the cinema
                  (Age categories exempted from MOH vaccination plan -below the
                  age of 12- are excluded).
                </li>
                <li>
                  Masks must be worn at all times except when sitting in
                  designated seat and eating.
                </li>
                <li>
                  Social distancing is to be practiced at all times and in all
                  areas of the cinema.
                </li>
                <li>
                  Moving to seats other than those in your booking is prohibited
                </li>
              </ul>
            </li>
            <li>
              <h4>Who is supposed to be with me in the same booking? </h4>
              <ul>
                <li>
                  Only members of the same family or household are allowed to be
                  in the same booking, this is to be confirmed upon booking your
                  tickets.
                </li>
              </ul>
            </li>
            <li>
              <h4>Who is allowed in the cinema?</h4>
              <ul>
                <li>
                  Only those who are vaccinated with are allowed in the cinemas
                  (Age categories exempted from MOH vaccination plan -below the
                  age of 12- are excluded). Present your “Immune app” or “Kuwait
                  mobile ID app” page, if your status is in green/yellow/orange,
                  you are allowed in the cinema. otherwise, you will not be
                  allowed in cinema.
                </li>
              </ul>
            </li>
            <li>
              <h4>How is Cinescape going to check vaccinated individuals?</h4>
              <ul>
                <li>
                  Our staff members will be at the entrance to check your status
                  on the IMMUNE app or the Kuwait Mobile ID app, and crosscheck
                  it with your civil ID. You will need to bring your original
                  civil ID or Kuwait mobile ID app to the cinema and comply with
                  these regulations to enter. No refunds will be given if you do
                  not have these documents with you.
                </li>
              </ul>
            </li>
            <li>
              <h4>
                What might happen if I violated the rules and regulations of
                covid-19 in cinemas?
              </h4>
              <ul>
                <li>
                  Cinescape management reserves the right to force any person to
                  leave the cinema if they do not abide by the cinema’s safety
                  rules and regulations, and tickets to that booking will not be
                  refunded.
                </li>
              </ul>
            </li>
            <h3>General</h3>
            <li>
              <h4>What is the deference between each movie rating?</h4>
              <ul>
                <li>
                  <strong>E</strong> ( Everyone - open for all ages )
                </li>
                <li>
                  <strong>PG</strong> ( Parental Guidance - ages under 13 are
                  required to have parental attendance )
                </li>
                <li>
                  <strong>T</strong> ( Teens - Admission only for ages 13 and
                  above )
                </li>
                <li>
                  <strong>18+</strong> ( Admission only for ages 18 and above )
                </li>
              </ul>
            </li>

            <li>
              <h4>Are there any places for guests on wheelchair?</h4>
              <p>
                Yes, there are places for wheelchairs in all cinema locations,
                also guests on wheelchair can have a lifetime free membership
                with Cinescape.
              </p>
            </li>

            <h3>Account</h3>
            <li>
              <h4>I forgot my username and password. What can I do?</h4>
              <p>
                Enter you e-mail address{" "}
                <a href='applicationfullurl+"/User/ForgotPassword"'>here</a> and
                choose how you'd like to retrieve your password.
              </p>
              <p>
                If you have forgotten your username and password, please contact
                our Call Center 180-3456 to help you out in retrieving them
              </p>
            </li>

            <li>
              <h4>How do I recharge my Cinescape Club Card?</h4>
              <p>
                You can buy a recharge code from any of our cinemas, or you can{" "}
                <a href='applicationfullurl+"/MyAccount?Page=Recharge"'>
                  recharge
                </a>{" "}
                it online through our website.
              </p>
            </li>

            <h3>Booking</h3>
            <li>
              <h4>How early can I book a ticket?</h4>
              <p>
                Starting from Wednesday of every week, you may book any movie
                released throughout week.
              </p>
            </li>
            <li>
              <h4>How can I bookonline ticketsat Cinescape?</h4>
              <p>
                After creating your Cinescape account, you can book tickets
                online by using your registered, <u>Cinescape Club Card</u> or
                Bank card.
              </p>
            </li>
            <li>
              <h4>Can I cancel my online reservation?</h4>
              <p>
                Yes, you can cancel your reservation 2 hours before your show
                starts.
              </p>
            </li>
            <li>
              <h4>How many tickets can I book online?</h4>
              <p>You can book up to 10 tickets per time.</p>
            </li>
            <li>
              <h4>
                How will Cinescape refund me if I cancel my online reservation?
              </h4>
              <p>It depends on how your booking was made, if by:</p>
              <ul>
                <li>
                  <u>Credit card</u>: the amount will be credited to your
                  Cinescape account.
                </li>
                <li>
                  <u>Cinescape Club Card</u>: the amount will be credited back
                  to the Club Card.{" "}
                </li>
              </ul>
            </li>
            <li>
              <h4>How will I know my reservation is successful?</h4>
              <p>
                Once your reservation has been successful, a confirmation
                message will be displayed on yourdevice.
              </p>
              <p>
                In addition, Cinescape will send SMS and Email confirmation
                messages to your contact information saved on your Cinescape
                profile.
              </p>
            </li>
            <li>
              <h4>How can I collect my tickets after I reserve online?</h4>
              <p>
                After your reservation is successful, simply head to the kiosk
                machine at the location of your booking and follow the easy
                instructions displayed and enter your booking code, then your
                ticket/s will be printed.
              </p>
            </li>
            <li>
              <h4>Can I reserve ticket/s through phone?</h4>
              <p>
                {" "}
                If you have a Cinescape club card or any credit card, yes, all
                you have to do is call 180-3456 and our call center agents will
                be glad to help.
              </p>
            </li>
            <li>
              <h4>Can we book a whole cinema theatre?</h4>
              <p>
                {" "}
                Of course, you can book any theatre for your birthday parties or
                meetings, please <a href="/ContactUs">contact us</a> for more
                info.
              </p>
            </li>

            <li>
              <h3 className="con-us">
                Do you have any other question? Don't hesitate to{" "}
                <a href="/ContactUs">contact us</a>
              </h3>
            </li>
          </ul>
        </div>
      </>
    );
  }
}
export default FAQs;
