import React, { Component } from "react";
import * as Constants from "../Constants/index";

import "./promotion.style.css";
import Text from "react-text";
import axios from "axios";


class Promotion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offers: [],
    };
  }
  componentDidMount() {
    const token = localStorage.getItem("token");

    axios
      .post(`${Constants.api}more/offer`, "", {
        headers: {
          "Accept-Language": localStorage.getItem("language"),
          Authorization: `Bearer ${token}`,
          appversion: Constants.av,
          platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
        },
      })
      .then((res) => {
        console.log(res);
        const offersArr = res.data.output;
        const offers = [];
        Object.keys(offersArr).map((offer) => offers.push(offersArr[offer]));
        this.setState({ offers: offers });
        console.log(this.state.offers);
      });
  }
  render() {
    const offers = this.state.offers;
    return (
      <>
        <div className="promotion_events">
          <h2>
            {localStorage.getItem("language") !== "ar" ? (
              "PROMOTION & EVENTS"
            ) : (
              <Text>الترويج والأحداث</Text>
            )}
          </h2>
          <div className="black-width"></div>
        </div>
        <div className="home-content-container">
          <div className="promotion_discount">
            {offers.map((offer) => {
              return (
                <a href={`/promotion/${offer.id}`}>
                  <div className="promotion_body">
                    <div
                      className="promotion_img"
                    // style={{
                    //   backgroundImage: `url(${offer.webThumbImageUrl})`,
                    // }}
                    >
                      <img src={offer.webThumbImageUrl} />
                      {/* <h3>
                      MAD
                      <br />
                      Monday
                    </h3>
                    <h2>50%</h2> */}
                    </div>
                    <div className="promotion_text">
                      <h3>{offer.name}</h3>
                      <p dangerouslySetInnerHTML={{ __html: offer.detailText }}></p>

                      <div className="promotion_icon">

                        <img src={require("../assets/icon.png").default} />

                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}
export default Promotion;
