import React, { useEffect } from "react";
import axios from "axios";
import * as Constants from "../../Constants/index";
import { useLocation } from 'react-router-dom';
import swal from "sweetalert";
import { Loading } from "../header/loader.component";

function BookingStatusCheck() {
   const location = useLocation();
console.log(location);
   // Access the FormData object from the request
  
   const checkPaymentStatus = () => {
    const params = {
        reqid:localStorage.getItem('bookingid'),
      };
      const token = localStorage.getItem("token");
      axios.post( Constants.api + "payment/knet/knetstatusweb", {}, {
        params,
        headers: {
            "Accept-Language": localStorage.getItem("language"),
            Authorization: `Bearer ${token}`,
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
        }
      }).then((resp) => {
           console.log(resp);
           if(resp.data.result=="success")
           {
           if (resp.data.output.p === "PAID") {
               window.location = "/bookingconfirm?result=success&bookingid=" + localStorage.getItem("bookingid") + "&type=BOOKING";
           } else
               window.location = "/bookingconfirm?result=fail&bookingid=" + localStorage.getItem("bookingid") + "&type=BOOKING";
        }
        else
        window.location = "/bookingconfirm?result=fail&bookingid=" + localStorage.getItem("bookingid") + "&type=BOOKING";

       }).catch(error => {
           console.error("Error:", error);
           // Handle error if needed
       });
   };

   useEffect(() => {
       checkPaymentStatus(); // Initial call to check payment status
   }, []); // Empty dependency array ensures this effect runs only once
  
   return (
    
    <div class="cinescape_booking_section cinescape-booking-details">
  
       <div class="cinescape_booking home-content-container">
          <div class="cinescape_booking_id">
             <div class="cinescape_img">
                <div class="booking_img">
                </div>
             </div>
             <div class="booking_text_section">
                <div class="booking_location_section booking-confirm-feild">
                   <div class="cinescape_id">
                      <div class="booking_id_text booking-feild">
                      <Loading />

                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>

 </div>
   );
}

export default BookingStatusCheck;
