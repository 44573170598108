import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Text from "react-text";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import { Loading } from "../header/loader.component";
class WalletConfirm extends Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         qrdata: '',
         posterhori: '',
         result: new URLSearchParams(window.location.search).get("result"),
         tckDetailsOutput: {}
      };
   }

   render() {

      const handleButtonClick = () => {
         window.location ='/myaccount';
       };

      return (
         <>
            {this.state.result == "success" ?
               <div class="cinescape_booking_section cinescape-booking-details">
                  <div
                     class="cinescape_section"

                  >
                     <div class="cinescape_booking ">
                        <div class="cinescape_booking_id">

                        <div class="booking_text_section">
                              <div class="booking_location_section booking-confirm-feild">
                                 <div class="cinescape_id">

                                 <div style={{ textAlign: 'center', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
     
      <div style={{ marginBottom: '30px' }}>
        <p>Your wallet recharge was successful!</p>
      </div>
    
   

                            <center>
                                       <p>
                               
                                             <button class="btnc btnc-primary"         onClick={handleButtonClick}
>
                                             Go back to Profile </button>
                                       
                                       </p>
                                       </center>
                                    </div>
                            
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

               </div>
               :
               <div class="cinescape_booking_section cinescape-booking-details">
                  <div
                     class="cinescape_section"

                  >
                     
                     <div class="cinescape_booking ">
                        <div class="cinescape_booking_id">

                        <div class="booking_text_section">
                              <div class="booking_location_section booking-confirm-feild">
                                 <div class="cinescape_id">

                                 <div style={{ textAlign: 'center', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <div class="btnc btnc-primary" style={{ marginBottom: '30px' }}>
        Sorry, Your transaction FAILED!
      </div>
      <div style={{ marginBottom: '30px' }}>
        <p>Your wallet recharge was unsuccessful, kindly contact your bank.</p>
      </div>
    
   

                            <center>
                                       <p>
                               
                                             <button class="btnc btnc-primary"         onClick={handleButtonClick}
>
                                             Go back to Profile </button>
                                       
                                       </p>
                                       </center>
                                    </div>
                            
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

               </div>
            }
         </>
      );
   }
}
export default WalletConfirm;