import "./App.css";
import "./queries.css";

import Header from "./components/header/header.component";
import Homepage from "./pages/homepage.pages";
import Footer from "./components/footer/footer.component";
// import { Routes, Route } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import MovieSession from "./components/moviesessions/MovieSession.component";
import MovieDetails from "./components/moviesessions/MovieDetails.component";
import Movies from "./pages/movies.pages";

import CinemaSession from "./components/moviesessions/CinemaSession.component";
import SeatLayout from "./components/seatlayout/SeatLayout.component";
import Food from "./components/food/food.component";
import BookingConfirm from "./components/bookingconfirm/BookingConfirm.component";
import Location from "./pages/location.pages";
import CinescapeLocation from "./pages/cinescapelocation.component";
import Login from "./pages/login.component";
import Maintenance from "./pages/maintenance.pages";


import MyAccount from "./components/myaccount/MyAccount.component";
import Promotion from "./pages/promotion.pages";
import FAQs from "./pages/faqs.component";
import ContactUs from "./pages/contact.component";
import AppContact from "./pages/appcontact.component";
import AppArabicContact from "./pages/apparabiccontact.component";

import TNC from "./pages/tnc.component";
import Privacy from "./pages/privacy.component";
import Information from "./pages/information.pages";
import AppTnc from "./pages/apptnc.pages";
import AppArabicTnc from "./pages/apparabictnc.pages";

import AppArabicPrivacy from "./pages/apparabicprivacy.pages";
import AppPrivacy from "./pages/appprivacy.pages";

import allExperiences from "./pages/allexperiences.component";
import experiencePage from "./components/experiencepage/experiencepage.component";
import fourdx from "./components/experiencepage/fourdx.component";
import infoFixed from "./components/infofixed/infofixed.component";
import Loader from "react-loader-spinner";
import Payment from "./components/payment/payment.component";
import PromotionDetails from "./components/promotiondetails/promotiondetails.component";
import MenuSlider from "./components/menu/menuslider.component";
import screenx from "./components/experiencepage/screenx.component";
import eleven from "./components/experiencepage/eleven.component";
import vip from "./components/experiencepage/vip.component";

import experienceimax from "./components/experiencepage/experienceimax.component";
import NewPayment from "./components/payment/newpayment.component";
import WalletConfirm from "./components/bookingconfirm/walletConfirm.component";
import AgeRating from "./pages/agerating.pages";
import Terms from "./pages/terms.pages";
import PrivacyPolicy from "./pages/privacypolicy.pages";
import Faqs from "./pages/faq.pages";
import Faq from "./pages/faq.pages";
import Locations from "./pages/locations.pages";
import BookingStatusCheck from "./components/bookingconfirm/BookingStatusCheck.component";
import RechargeStatusCheck from "./components/bookingconfirm/RechargeStatusCheck.component";

let navmenuarabic2 = localStorage.getItem("language") === 'ar' ? "ar-language-info" : '';
function App() {
  return (
    <>
      <MenuSlider />
      <div id={`outer-container ${navmenuarabic2}`}>

        <main id="page-wrap">
        {window.location.href.indexOf("/app") == -1  ? <Header /> : ""}
          <Switch>
            <Route exact path="/" component={Homepage} />
            {/* <Route exact path="/" component={Maintenance} /> */}
            <Route
              exact
              path="/moviesessions/:moviename/:amcode"
              component={MovieSession}
            />
            <Route
              exact
              path="/movie-details/:moviename/:amcode"
              component={MovieDetails}
            />
            <Route
              exact
              path="/cinemasessions/:amcode"
              component={CinemaSession}
            />
            <Route exact path="/seatlayout" component={SeatLayout} />
            <Route exact path="/food/:ccode" component={Food} />
            <Route exact path="/payment/:ccode" component={NewPayment} />
            <Route exact path="/app/tnc" component={AppTnc} />
            <Route exact path="/app/ar/tnc" component={AppArabicTnc} />
            <Route exact path="/app/privacy" component={AppPrivacy} />
            <Route exact path="/app/ar/privacy" component={AppArabicPrivacy} />
            <Route exact path="/app/contactus" component={AppContact} />
            <Route exact path="/app/ar/contactus" component={AppArabicContact} />
            <Route exact path="/booking-ticket/:status" component={BookingStatusCheck} />
            <Route exact path="/clubrecharge-ticket/:status" component={RechargeStatusCheck} />

            <Route exact path="/bookingconfirm" component={BookingConfirm} />
            <Route exact path="/walletconfirm" component={WalletConfirm} />
            <Route exact path="/cinelocation" component={CinescapeLocation} />
            <Route exact path="/location" component={Location} />
            <Route exact path="/myaccount" component={MyAccount} />
            <Route exact path="/promotion" component={Promotion} />
            <Route exact path="/promotion/:id" component={PromotionDetails} />
            <Route exact path="/faqs" component={FAQs} />
            <Route exact path="/terms-and-conditions" component={TNC} />

            <Route exact path="/terms" component={Terms} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/privacypolicy" component={PrivacyPolicy} />
            <Route exact path="/locations" component={Locations} />
            <Route exact path="/agerating" component={AgeRating} />

            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/information/:type" component={Information} />
            <Route exact path="/selected-location" component={Location} />
            <Route exact path="/experiences" component={allExperiences} />
            <Route exact path="/dolby" component={experiencePage} />
            <Route exact path="/4dx" component={fourdx} />
            <Route exact path="/screenX" component={screenx} />
            <Route exact path="/eleven" component={eleven} />
            <Route exact path="/imax" component={experienceimax} />
            <Route exact path="/vip" component={vip} />

            <Route exact path="/infofixed" component={infoFixed} />
            <Route exact path="/contactus" component={ContactUs} />
            <Route exact path="/movies" component={Movies} />

            <Route exact path="/newpayment" component={NewPayment} />

            {/* <Route path="/cinelocation" element={<CinescapeLocation />} /> */}
          </Switch>
          {/* <Routes> */}
          {/* <Route exact path="/" element={<Homepage />} />
        <Route
          path="/moviesessions/:city/:moviename/:amcode"
          element={<MovieSession />}
        />
        <Route path="/seatlayout" element={<SeatLayout />} />
        <Route path="/food" element={<Food />} />
        <Route path="/bookingconfirm" element={<BookingConfirm />} />
        <Route path="/location" element={<Location />} />
        <Route path="/cinelocation" element={<CinescapeLocation />} />
        <Route path="/login" element={<Login />} />
        <Route path="/myaccount" element={<MyAccount />} /> */}
          {/* </Routes> */}
          {window.location.href.indexOf("/app") == -1 ? <Footer /> : ""}
        </main>
      </div>
    </>
  );
}

export default App;
