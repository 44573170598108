import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class TNC extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="faq-banner seat-banner">
          <img src={require("../assets/my-account.png").default} alt="" />
          <h3>TERMS AND CONDITIONS</h3>
        </div>
        <div class="black-width"></div>
        <div className="faqs-container padbothvertical-40 proper">
          <ul class="cc-faqs">
            <h3>Booking Rules</h3>
            <p>
              In compliance with the Ministerial Decree 30 of 2016 Concerning
              The Prevention And Regulation Of Children's Entry To The Cinema.
              <br />
              I herby confirm that all guests are above the age limit for all
              the booked tickets.
              <br />
              Cinescape management reserves the right to check the official
              identification carrying a photo before allowing the child to watch
              the movie.
              <br />
              Customers younger than the stipulated age for the movie rating by
              the Ministry of Information will NOT BE ALLOWED to watch the
              movie.
            </p>
            <h3>Seating Rules</h3>
            <p>
              By selecting the seating section, I hereby accept the fact that
              Cinescape has separate seating sections for both families and
              bachelors. In the event that my booking or purchases are made to
              the wrong seating section, I agree that I will be in violation of
              Cinescape's seating regulations and could therefore lose my
              chances to attend the movie and have my booking refunded.
            </p>
            <h3>Seat Selection & Payment Selection Page Rules</h3>
            <p>
              Tickets can only be collected at the theatre using your Booking
              ID.If you do not have your Booking ID with you, the tickets will
              not be issued.
              <br />
              Cancellations are accepted at least two hours before the show
              starts. No refunds Will be issued for bank card transactions as we
              will provide you with credit valid for 30 days which can be used
              to book online.
              <br />
              Children of all ages need tickets for admission.
              <br />{" "}
            </p>
            <ul>
              <li>
                Children younger than 3 years are granted free admissions on
                Tuesdays.
              </li>
              <li>
                Children younger than 3 years are granted free admissions to
                Indian movies.
              </li>
              <li>
                Only one child per guardian is allowed, as children may not
                occupy a seat.
              </li>
            </ul>
            <p>
              Cinescape reserves the right to shift your seat in case a free
              seat is left next or in between your seats.
            </p>
            <h3>Club Card</h3>
            <p>
              REMINDER: You have to have your Booking ID in order to collect
              your tickets. Without the ID your tickets will not be issued and
              cannot be refunded.
            </p>
            <h3>KNET</h3>
            <p>
              REMINDER: You have to have your Booking ID in order to collect
              your tickets. Without the Booking ID your tickets will not be
              issued and cannot be refunded.
            </p>
            <h3>Credit Card</h3>
            <p>
              REMINDER: You have to have your Booking ID in order to collect
              your tickets. Without the ID your tickets will not be issued and
              cannot be refunded.
            </p>
            <h3>Cancelation Rules</h3>
            <p>
              By cancelling a reservation made by Bank Card, NO credits will be
              issued to my bank account. Instead, Cinescape will issue me a
              temporary credit valid for 30 calendar days.
              <br />
              Cancellations are accepted at least 2 hours before the show
              starts.
              <br />
              On clicking Cancel movie <br />
              For reservations made by Bank Card/K-NET, I agree that no credits
              will be issued to my bank account.
              <br />
              For reservations made by Bank Card/ K-NET, Cinescape will issue a
              temporary credit for 30 days for me to book online again.
              <br />
              Do you want to continue?
              <br />
            </p>
          </ul>
        </div>
      </>
    );
  }
}
export default TNC;
