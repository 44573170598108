import axios from "axios";
import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { act } from "react-dom/cjs/react-dom-test-utils.production.min";
import ShowDetails from "../showdetails/ShowDetails.component";
import Modal from "react-modal";
import TrailerModal from "../trailer/trailer.component";
import { Link, Redirect } from "react-router-dom";
import Text from "react-text";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Loading } from "../header/loader.component";
import swal from "sweetalert";

class MovieDetails extends Component {
  // constructor(props) {
  //   super(props);
  // this.
  state = {
    city: this.props.match.params.city,
    amcode: this.props.match.params.amcode,
    mname: this.props.match.params.moviename,
    lat: 0.0,
    lng: 0.0,
    date: "",
    lang: "ALL",
    format: "ALL",
    price: "ALL",
    time: "ALL",
    cinetype: "ALL",
    special: "ALL",
    hc: "ALL",
    output: {},
    movies: {},
    similar: [],
    showTrailer: false,
    trailerUrl: ''

  };
  // }

  componentDidMount() {

    axios
      .post(
        Constants.api + "content/getmovie?mid=" + this.state.amcode,
        '',

        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
          },
        }
      )
      .then((res) => {
        console.log(res);
        let days = [];
        let movies = {};
        if (res.data.output != null) {
          console.log(res.data.output.days);


          movies = res.data.output;
          this.setState({
            movies: res.data.output.movie,
            similar: res.data.output.similar
          });
        }
        else
          swal(res.data.msg);
        // console.log(this.state.shows[0]["showTime"]);
      });

  }


  render() {


    let movieinfo = { ...this.state.movies };
    let director = { ...movieinfo.director };
    let cast = { ...movieinfo.cast };
    let mh = Math.floor(movieinfo.runTime / 60);
    let mm = movieinfo.runTime % 60;
    const entries = Object.entries(cast);
    const renderCast = [];
    for (const [key, values] of entries) {
      let fullName = `${values.firstName} ${values.lastName}`;
      renderCast.push(fullName);
    }
    console.log(movieinfo);
    console.log(this.state.selectedDay);
    console.log(this.state.selectedDate);


    return (
      <>
        <div className="banner-item movie-banner movie-banner-img">
          <div className="banner-item-img">
            {movieinfo.webimgbig && movieinfo.webimgbig.length > 0 ?

              <img src={movieinfo.webimgbig} alt="" /> : <img
                src={
                  require("../../assets/bean.jpg").default
                }

                alt={movieinfo.title}
              />}
          </div>
          <div className="movie-banner-bottom">
            <div className="banner-info">
              <div className="banner-info-upper">
                <h3>{movieinfo.title}</h3>
                <div className="age-highlight" style={{ backgroundColor: movieinfo.ratingColor }}>{movieinfo.rating}</div>
                <div className="banner-info-lower-part banner-trailer">
                  {movieinfo.trailerUrl != "" ? (
                    <a
                      className="pointer-cursor"
                      onClick={() => {
                        this.setState({
                          showTrailer: true,
                          trailerUrl: movieinfo.trailerUrl,
                          moviename: movieinfo.title
                        });
                      }}
                    >
                      <img
                        src={require("../../assets/play.png").default}
                        className="play-icon"
                      />
                      <p>
                        {" "}
                        {localStorage.getItem("language") !== "ar" ? (
                          "Watch Trailer"
                        ) : (
                          <Text>مشاهدة اعلان</Text>
                        )}
                      </p>
                    </a>
                  ) : (
                    ""
                  )}
                  {/* <a href={movieinfo.trailerUrl} target="_blank">
                    <img
                      src={require("../../assets/play.png").default}
                      className="play-icon"
                    />
                    <p>Watch Trailer</p>
                  </a> */}
                </div>
              </div>
            </div>
            <div className="banner-info-lower justify-flex pad-60">
              <div className="banner-info-lower-part movie-info">
                <div className="movie-info-box">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Language"
                    ) : (
                      <Text>لغة</Text>
                    )}
                  </p>
                  <p>{movieinfo.language}</p>
                </div>
                <div className="movie-info-box">
                  <p>
                    {" "}
                    {localStorage.getItem("language") !== "ar" ? (
                      "Genre"
                    ) : (
                      <Text>النوع</Text>
                    )}
                  </p>
                  <p>{movieinfo.genre}</p>
                </div>
                <div className="movie-info-box">
                  <p>Subtitle</p>
                  <p>{movieinfo.subTitle}</p>
                </div>
                <div className="movie-info-box">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Run Time"
                    ) : (
                      <Text>مدة العرض</Text>
                    )}
                  </p>
                  <p>
                    {movieinfo.runTimeStr}
                  </p>
                </div>
                <div className="movie-info-box">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Director"
                    ) : (
                      <Text>مخرج</Text>
                    )}
                  </p>
                  <p>
                    {director.firstName} {director.lastName}
                  </p>
                </div>
                <div className="movie-info-box">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Cast"
                    ) : (
                      <Text>
                       بطولة
                      </Text>
                    )}
                  </p>
                  <p>{renderCast.join("  |  ")}</p>
                  {/* <p>Daniel Craig | Ralph Fiennes | Lea Seydoux | Rami Malek</p> */}
                </div>
              </div>
              <div className="movie-info">
                <div className="movie-info-box">
                  <p>
                    {" "}
                    {localStorage.getItem("language") !== "ar" ? (
                      "synopsis"
                    ) : (
                      <Text>ملخص</Text>
                    )}
                  </p>
                  <p>{movieinfo.synopsis}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home-content-container">
          <section class="now-showing padbothvertical-40">
            <div class="main-head"><h3>
              {localStorage.getItem("language") !== "ar" ?
                <Text>
                  Similar movies now showing
                </Text>
                :
                <Text>
                  أفلام مماثلة معروضة الآن
                </Text>}
            </h3></div><div class="now-showing-container">


              {this.state.loading ? <Loading /> : ""}
              {this.state.similar.map(function (item, i) {

                let nameForUrl = item.title ? item.title
                  .toString()
                  .toLowerCase()
                  .replaceAll(" ", "-") : '';
                let linkUrl =
                  "/moviesessions" +
                  "/" +
                  nameForUrl +
                  "/" +
                  item.id;
                if (item.id != movieinfo.id) {
                  return (

                    <div className="now-showing-preview">
                      <div className="now-showing-preview-img">
                        {item.mobimgsmall ? (
                          <img src={item.mobimgsmall} alt={item.title} />
                        ) : (
                          <div className="dummy-img">
                            <img
                              src={
                                require("../../assets/logo-mobile.png").default
                              }
                              alt={item.title}
                            />
                            <p>{item.title}</p>
                          </div>
                        )}
                        {/* <img src={item.mobimgsmall} alt={item.title} /> */}
                        <div className="age-highlight" style={{ backgroundColor: item.ratingColor }}>{item.rating}</div>
                        <div className="now-showing-hover">
                          {/* <a
                          onClick={() => {
                            this.setState({
                              showTrailer: true,
                              trailerUrl: item.trailerUrl,
                            });
                          }}
                          className="block-anchor"
                        >
                          <img
                            src={require("../../assets/play.png").default}
                            className="play-icon"
                          />
                        </a> */}
                          <a href={linkUrl}>
                            <button className="btnc btnc-primary">
                              {localStorage.getItem("language") !== "ar" ? (
                                "Book Now"
                              ) : (
                                <Text>احجز الآن</Text>
                              )}
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </section>
        </div>
        {
          this.state.showTrailer ? (
            <>
              <Modal
                isOpen={this.state.showTrailer}
                contentLabel="Example Modal"
                portalClassName="trai-modal"
              >
                {" "}
                <button
                  className="trailer-cross"
                  onClick={() => this.setState({ showTrailer: false })}
                >
                  <i class="fas fa-times"></i>
                </button>
                <TrailerModal trailerUrl={this.state.trailerUrl} name={this.state.moviename} />
              </Modal>
            </>
          ) : (
            ""
          )
        }
      </>
    );
  }
}
export default MovieDetails;
