import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import Text from "react-text";
import Modal from "react-modal";
class Vip extends Component {
   constructor(props) {
      super(props);
      this.state = {};
   }
   render() {
      return (
         <>
            <div className="experience-banner">
               <img src={require("../../assets/bg-topbanner-img.jpg").default} />
            </div>

            <div className="experience-page home-content-container">
               <div className="experience-title padbothvertical-40 p-b-0">
                  <div className="experience-head m-b-0 screenx-head">
                     <h3>   {localStorage.getItem("language") !== "ar" ? (
                        "LUXURY, FROM START TO FINISH"
                     ) : (
                        <Text>فاخرة ، من البداية إلى النهاية</Text>
                     )}</h3>

                  </div>
               </div>
               <div className="experience-white-bg padbothvertical-40">
                  <div className="experience-info-box align-items">
                     <div className="exp-info-img">
                        <img src={require("../../assets/bg-vip.jpg").default} />
                     </div>
                     <div className="exp-info-box">
                        <div className="exp-info-box-detail">
                           <p> {localStorage.getItem("language") !== "ar" ? (
                              "From the first moment you visit the VIP area, you’ll feel all the immersive details even prior to the start of the movie, from the entrance to the passage way to the lobby, every thing was designed and thought of to provide an experience that Cinescape visitors will never forget"
                           ) : (
                              <Text>من اللحظة الأولى التي تزور فيها منطقة VIP ، ستشعر بكل التفاصيل الغامرة حتى قبل بدء الفيلم ، من المدخل إلى طريق الممر إلى الردهة ، كل شيء تم تصميمه وفكر فيه لتوفير تجربة لن ينسى زوار سينسكيب أبدًا</Text>
                           )}</p>
                        </div>
                     </div>
                  </div>
               </div>


               <div className="experience-title padbothvertical-40 p-b-0">
                  <div className="experience-head m-b-0 screenx-head">
                     <h3>  {localStorage.getItem("language") !== "ar" ? (
                        "THE MOST COMFORTABLE SEATS: "
                     ) : (
                        <Text>المقاعد الأكثر راحة:</Text>
                     )}</h3>
                  </div>
               </div>
               <div className="experience-white-bg padbothvertical-40">
                  <div className="experience-info-box align-items bg-colour">
                     <div className="exp-info-box">
                        <div className="exp-info-box-detail">

                           <p>

                              {localStorage.getItem("language") !== "ar" ? (
                                 "The VIP screen has a limited number of leather seats that provide a very comfortable experience, the seats are larger and more spacious compared to the regular seats and you have the option to adjust the back & legs incline for a better viewing experience."
                              ) : (
                                 <Text>تحتوي شاشة VIP على عدد محدود من المقاعد الجلدية التي توفر تجربة مريحة للغاية ، والمقاعد أكبر وأكثر اتساعًا مقارنة بالمقاعد العادية ولديك خيار ضبط انحدار الظهر والساقين للحصول على تجربة مشاهدة أفضل.</Text>
                              )}

                           </p>
                        </div>
                     </div>
                     <div className="exp-info-img">
                        <img src={require("../../assets/bg-environmentaleffect.jpg").default} />
                     </div>
                  </div>
               </div>


               <div className="experience-title padbothvertical-40 p-b-0">
                  <div className="experience-head m-b-0 screenx-head">
                     <h3>   {localStorage.getItem("language") !== "ar" ? (
                        "VIP concession, Just for you:"
                     ) : (
                        <Text>امتياز VIP ، فقط لأجلك:</Text>
                     )}</h3>
                  </div>
               </div>
               <div className="experience-white-bg padbothvertical-40">
                  <div className="experience-info-box align-items">
                     <div className="exp-info-img">
                        <img src={require("../../assets/bg-environmentaleffects-img.jpg").default} />
                     </div>
                     <div className="exp-info-box">
                        <div className="exp-info-box-detail">

                           <p>
                              {localStorage.getItem("language") !== "ar" ? (
                                 " A separate concession with many of your favorite and some exclusive items as well, where you can order and head directly to your seats and you’ll be served with your favorite snacks and beverages from our dedicated staff."
                              ) : (
                                 <Text>امتياز منفصل مع العديد من العناصر المفضلة لديك وبعض العناصر الحصرية أيضًا ، حيث يمكنك الطلب والتوجه مباشرة إلى مقاعدك وسيتم تقديم الوجبات الخفيفة والمشروبات المفضلة لديك من موظفينا المتفانين.</Text>
                              )}
                           </p>
                        </div>
                     </div>
                  </div>
               </div>











            </div>
         </>
      );
   }
}
export default Vip;