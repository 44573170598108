import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import Text from "react-text";
import Modal from "react-modal";

class eleven extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="experience-banner">
          <img src={require("../../assets/eleven.jpg").default} />
        </div>
        <div className="experience-page home-content-container">
          <div className="experience-title padbothvertical-40 p-b-0 ">
            <div className="experience-head m-b-0">
              <h3>   {localStorage.getItem("language") !== "ar" ? (
                "DOLBY ATMOS FOR CINEMA: BENEFITS"
              ) : (
                <Text>دولبي أتموس للسينما: الفوائد</Text>
              )}</h3>
              <p>ELEVEN was introduced by Cinescape as an Amplified cinema experience. Found exclusively at Cinescape Avenues, we placed the Ultimate sound and laser technology in one screen, creating a breathtaking three dimensional sound system with Dolby Atmos, and outstanding 2D & 3D picture performance with Barco laser projection.

                {localStorage.getItem("language") !== "ar" ? (
                  "ELEVEN was introduced by Cinescape as an Amplified cinema experience. Found exclusively at Cinescape Avenues, we placed the Ultimate sound and laser technology in one screen, creating a breathtaking three dimensional sound system with Dolby Atmos, and outstanding 2D & 3D picture performance with Barco laser projection."
                ) : (
                  <Text>تم تقديم ELEVEN بواسطة Cinescape كتجربة سينمائية مضخمة. توجد حصريًا في سينسكيب أفينيوز ، وضعنا تقنية Ultimate للصوت والليزر في شاشة واحدة ، وخلقنا نظام صوت ثلاثي الأبعاد مذهل مع Dolby Atmos ، وأداء صور ثنائي وثلاثي الأبعاد مذهل مع إسقاط ليزر Barco.</Text>
                )} </p>
            </div>

          </div>
          <div className="experience-white-bg padbothvertical-40 p-t-0">
            <div className="experience-info-box align-items">
              <div className="exp-info-img">
                <img src={require("../../assets/bg-dolbyatmos-benefits.jpg").default} />
              </div>
              <div className="exp-info-box ">

                <div className="exp-info-box-detail">
                  <h5><b> {localStorage.getItem("language") !== "ar" ? (
                    "Fills the Cinema with Breathtaking Sound"
                  ) : (
                    <Text>تملأ السينما بصوت يخطف الأنفاس</Text>
                  )}</b> </h5>

                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Sound comes from all directions, including overhead, to fill the cinema with astonishing clarity, richness, detail, and depth."
                    ) : (
                      <Text>يأتي الصوت من جميع الاتجاهات ، بما في ذلك الجزء العلوي ، لملء السينما بوضوح مذهل وثراء وتفاصيل وعمق.</Text>
                    )}
                  </p>

                  <h5><b>   {localStorage.getItem("language") !== "ar" ? (
                    "Puts Sounds into Motion All Around You"
                  ) : (
                    <Text>يضع الأصوات في الحركة من حولك</Text>
                  )}</b></h5>
                  <p>   {localStorage.getItem("language") !== "ar" ? (
                    "The sounds of the onscreen story move all around you in three-dimensional space, so you feel like you're inside the action.."
                  ) : (
                    <Text>تتحرك أصوات القصة التي تظهر على الشاشة من حولك في مساحة ثلاثية الأبعاد ، بحيث تشعر وكأنك في قلب الحدث ..</Text>
                  )}
                  </p>

                  <h5><b>   {localStorage.getItem("language") !== "ar" ? (
                    "Creates a Powerfully Moving Entertainment Experience"
                  ) : (
                    <Text>يخلق تجربة ترفيهية مؤثرة بقوة</Text>
                  )}</b> </h5>

                  <p>   {localStorage.getItem("language") !== "ar" ? (
                    "It's sound that excites your senses and inspires your emotions to deliver the full impact of entertainment."
                  ) : (
                    <Text>إنه الصوت الذي يثير حواسك ويلهم عواطفك لتقديم التأثير الكامل للترفيه.</Text>
                  )}</p>


                  <h5><b>  {localStorage.getItem("language") !== "ar" ? (
                    "Conveys the Artist's Intent "
                  ) : (
                    <Text>ينقل نية الفنان</Text>
                  )}</b></h5>
                  <p>  {localStorage.getItem("language") !== "ar" ? (
                    "Dolby Atmos® gives artists amazing new capabilities to tell their stories, accent their games, or perform their music-so you can experience the power of the artist's vision."
                  ) : (
                    <Text>يمنح Dolby Atmos® الفنانين إمكانيات جديدة مذهلة لرواية قصصهم أو إبراز ألعابهم أو أداء موسيقاهم - حتى تتمكن من تجربة قوة رؤية الفنان.</Text>
                  )}
                  </p>

                </div>
              </div>
            </div>
          </div>

          <div className="experience-head">
            <h5>   {localStorage.getItem("language") !== "ar" ? (
              "FREEING SOUND FROM CHANNELS"
            ) : (
              <Text>تحرير الصوت من القنوات</Text>
            )}</h5>
            <h3> {localStorage.getItem("language") !== "ar" ? (
              "THE DOLBY ATMOS CONCEPT"
            ) : (
              <Text>مفهوم دولبي أتموس</Text>
            )}</h3>
          </div>

          <div className="experience-grey-bg">

            <div className="experience-info-box">
              <div className="exp-info-box">


                <div className="exp-info-box-detail">

                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      " Dolby Atmos creates powerful, moving audio by introducing two important concepts to cinema sound: audio objects and overhead speakers. Together, these completely change how soundtracks are created and heard."
                    ) : (
                      <Text>ينشئ Dolby Atmos صوتًا قويًا ومؤثرًا من خلال تقديم مفهومين مهمين لصوت السينما: الكائنات الصوتية ومكبرات الصوت العلوية. معًا ، هذه التغييرات تغير تمامًا كيفية إنشاء الموسيقى التصويرية وسماعها.</Text>
                    )}</p>


                  <p> {localStorage.getItem("language") !== "ar" ? (
                    "Traditional surround soundtracks confine all sounds to a small set of channels that can deliver sound to you from only a few perceived angles."
                  ) : (
                    <Text>تحصر مسارات الصوت المحيطي التقليدية جميع الأصوات في مجموعة صغيرة من القنوات التي يمكنها توصيل الصوت إليك من زوايا قليلة محسوسة.</Text>
                  )}</p>


                  <p>  {localStorage.getItem("language") !== "ar" ? (
                    "In Dolby Atmos, by contrast, sound can be freed from channels. It enables artists to treat specific sounds as individual entities, called audio objects. These can be precisely placed and moved by the soundtrack creator to anywhere in the cinema’s three dimensional space. The Dolby Atmos cinema processor then determines which of a cinema's huge array of front, back, side, and overhead speakers it will use to recreate this lifelike movement. "
                  ) : (
                    <Text>على النقيض من ذلك ، في Dolby Atmos ، يمكن تحرير الصوت من القنوات. إنه يمكّن الفنانين من معالجة أصوات معينة ككيانات فردية تسمى كائنات صوتية. يمكن لمصمم الموسيقى التصويرية وضعها بدقة ونقلها إلى أي مكان في الفضاء ثلاثي الأبعاد للسينما. ثم يحدد معالج السينما Dolby Atmos أيًا من مجموعة السينما الضخمة من مكبرات الصوت الأمامية والخلفية والجانبية والعلوية التي ستستخدمها لإعادة إنشاء هذه الحركة النابضة بالحياة.</Text>
                  )}</p>


                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "As a result, a Dolby Atmos soundtrack brings alive the onscreen story as never before possible. The movie's sounds flow all around you to completely immerse you in the action, heightening the impact of the story and creating a powerfully moving cinema experience."
                    ) : (
                      <Text>ونتيجة لذلك ، فإن الموسيقى التصويرية Dolby Atmos تحيي القصة التي تظهر على الشاشة بشكل لم يسبق له مثيل. تتدفق أصوات الفيلم من حولك لتغمرك تمامًا في الحركة ، مما يزيد من تأثير القصة ويخلق تجربة سينمائية مؤثرة بقوة.</Text>
                    )}</p>


                </div>

              </div>
              <div className="exp-info-img ">
                <img
                  src={
                    require("../../assets/bg-dolbyatmos.jpg")
                      .default
                  }
                />
              </div>
            </div>
          </div>



          <div className="experience-head p-t-40 m-b-0">
            <h3>   {localStorage.getItem("language") !== "ar" ? (
              "AWE-INSPIRING IMAGES"
            ) : (
              <Text>صور مذهلة</Text>
            )}</h3>
          </div>
          <div className="experience-white-bg">
            <div className="experience-info-box">
              <div className="exp-info-img">
                <img src={require("../../assets/bg-barco.jpg").default} />
              </div>
              <div className="exp-info-box">
                <h4>   {localStorage.getItem("language") !== "ar" ? (
                  "Barco Laser"
                ) : (
                  <Text>باركو ليزر</Text>
                )}</h4>
                <div className="exp-info-box-detail">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Barco's state of the art laser projector brings brilliant brightness levels and cutting edge image quality to your large screen. A stunning experience that's only available in theaters. "
                    ) : (
                      <Text>يوفر جهاز العرض بالليزر المتطور من Barco مستويات سطوع رائعة وجودة صورة متطورة لشاشتك الكبيرة. تجربة مذهلة لا تتوفر إلا في المسارح.</Text>
                    )}</p>

                  <h5>   {localStorage.getItem("language") !== "ar" ? (
                    "Unprecedented image quality"
                  ) : (
                    <Text>جودة صورة غير مسبوقة</Text>
                  )}</h5>
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Barco offers superior image quality with a remarkable brightness level, increased contrast ratio and vivid colors for a radically improved 3D movie experience."
                    ) : (
                      <Text>تقدم Barco جودة صورة فائقة مع مستوى سطوع ملحوظ ، وزيادة نسبة التباين والألوان الزاهية لتجربة أفلام ثلاثية الأبعاد محسّنة بشكل جذري.</Text>
                    )}

                  </p>

                </div>
                <div className="exp-info-box-img fourdx-environmental">
                  <img
                    src={
                      require("../../assets/bg-imaxpicture-content.png")
                        .default
                    }
                  />
                </div>
              </div>
            </div>
          </div>






        </div>
      </>
    );
  }
}
export default eleven;
