import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import * as Constants from "../Constants/index";
import axios from "axios";
import Text from "react-text";

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cinemas: [],
    };
  }
  componentDidMount() {
    const token = localStorage.getItem("token");

    axios
      .post(Constants.api + "content/cinemas", "", {
        headers: {
          "Accept-Language": localStorage.getItem("language"),
          Authorization: `Bearer ${token}`,
          appversion: Constants.av,
          platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
        },
      })
      .then((res) => {
        console.log(res);

        console.log("cinemas");
        this.setState({
          cinemas: res.data.output.cinemas,
        });
      });
  }
  render() {
    return (
      <>
        <div className="location-banner">
          <div className="location-banner-img">
            <img src={require("../assets/location360.png").default} alt="" />
            <h3>Cinescape 360</h3>
          </div>
          <div className="location-info mobile-hidden">
            <div className="location-info-upper">
              <p>
                {localStorage.getItem("language") !== "ar" ? (
                  "EXPERIENCES"
                ) : (
                  <Text>خبرة</Text>
                )}
              </p>
              <div className="location-info-img">
                <img src={require("../assets/standard.png").default} alt="" />
                <img src={require("../assets/vip.png").default} alt="" />
                <img src={require("../assets/premium2.png").default} alt="" />
                <img src={require("../assets/dolby.png").default} alt="" />
                <img src={require("../assets/imax.png").default} alt="" />
                <img src={require("../assets/4dx.png").default} alt="" />
              </div>
            </div>
            <div className="location-info-lower">
              <p>
                {" "}
                {localStorage.getItem("language") !== "ar" ? (
                  "LOCATION"
                ) : (
                  <Text>موقع</Text>
                )}
              </p>
              <div className="location-info-loc">
                <p>360 Mall, South Surra</p>
                <img
                  src={require("../assets/location-marker.png").default}
                />{" "}
                {localStorage.getItem("language") !== "ar" ? (
                  "MAPS"
                ) : (
                  <Text>خرائط</Text>
                )}
              </div>
            </div>
          </div>
          <div className="house_text desktop-hidden">
            <div className="location_info">
              <h4>
                {localStorage.getItem("language") !== "ar" ? (
                  "LOCATION"
                ) : (
                  <Text>موقع</Text>
                )}
              </h4>
              <p>360 Mall, South Surra </p>
              <p className="map">
                <img src={require("../assets/location.png").default} />{" "}
                {localStorage.getItem("language") !== "ar" ? (
                  "Maps"
                ) : (
                  <Text>خرائط</Text>
                )}
              </p>
            </div>
            <div className="cinescap_imgs">
              <div className="cinescap_img-logo">
                <img
                  src={require("../assets/standard.png").default}
                  alt="img"
                />
              </div>
              <div className="cinescap_img-logo">
                <img src={require("../assets/vip.png").default} alt="img" />
              </div>
              <div className="cinescap_img-logo">
                <img
                  src={require("../assets/premium2.png").default}
                  alt="img"
                />
              </div>
              <div className="cinescap_img-logo">
                <img src={require("../assets/dolby.png").default} alt="img" />
              </div>
              <div className="cinescap_img-logo">
                <img src={require("../assets/imax.png").default} alt="img" />
              </div>
              <div className="cinescap_img-logo">
                <img src={require("../assets/4dx.png").default} alt="img" />
              </div>
            </div>
          </div>
        </div>
        <div className="home-content-container">
          <section className="now-showing padbothvertical-40 ">
            <div className="now-showing-container">
              <div className="now-showing-preview">
                <div className="now-showing-preview-img">
                  <img src={require("../assets/SSposter.png").default} alt="" />
                  <div className="age-highlight">13+</div>
                </div>
              </div>
              <div className="now-showing-preview">
                <div className="now-showing-preview-img">
                  <img src={require("../assets/SSposter.png").default} alt="" />
                  <div className="age-highlight">13+</div>
                </div>
              </div>
              <div className="now-showing-preview">
                <div className="now-showing-preview-img">
                  <img src={require("../assets/SSposter.png").default} alt="" />
                  <div className="age-highlight">13+</div>
                </div>
              </div>
              <div className="now-showing-preview">
                <div className="now-showing-preview-img">
                  <img src={require("../assets/SSposter.png").default} alt="" />
                  <div className="age-highlight">13+</div>
                </div>
              </div>
              <div className="now-showing-preview">
                <div className="now-showing-preview-img">
                  <img src={require("../assets/SSposter.png").default} alt="" />
                  <div className="age-highlight">13+</div>
                </div>
              </div>
              <div className="now-showing-preview">
                <div className="now-showing-preview-img">
                  <img src={require("../assets/SSposter.png").default} alt="" />
                  <div className="age-highlight">13+</div>
                </div>
              </div>
              <div className="now-showing-preview">
                <div className="now-showing-preview-img">
                  <img src={require("../assets/SSposter.png").default} alt="" />
                  <div className="age-highlight">13+</div>
                </div>
              </div>
              <div className="now-showing-preview">
                <div className="now-showing-preview-img">
                  <img src={require("../assets/SSposter.png").default} alt="" />
                  <div className="age-highlight">13+</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
export default Location;
