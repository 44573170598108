import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import banner2 from "../assets/banner-2.png";
import banner3 from "../assets/banner-3.png";
import banner4 from "../assets/banner-4.png";
import Text from "react-text";
import {
   Accordion,
   AccordionItem,
   AccordionItemHeading,
   AccordionItemButton,
   AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
// import "react-tabs/style/react-tabs.css";
import * as Constants from "../Constants/index";
import axios from "axios";
import Modal from "react-modal";
import Moment from "react-moment";
import TrailerModal from "../components/trailer/trailer.component";
import { now } from "moment";
import { Loading } from "../components/header/loader.component";

class Maintenance extends Component {
   constructor(props) {
      super(props);
      this.state = {
         advance: [],
         advanceTab: [],
         nowShowing: [],
         sliders: [],
         comingSoon: [],
         showHoverButtons: '',
         showMovies: "nowshowing",
         topPick: [],
         thisWeek: [],
         lastChance: [],
         showTrailer: false,
         trailerUrl: "",
         cinemas: [],
         filterModal: false,
         offers: [],
         genres: [],
         language: [],
         rating: [],
         time: [],
         experiences: [],
         moviecinema: [],
         filtercinema: [],
         filtergenre: [],
         filtertime: [],
         filterlanguage: [],
         filterrating: [],
         filterexperience: [],
         filter_cinema: [],
         filter_experience: [],
         filter_genre: [],
         filter_language: [],
         filter_rating: [],
         filter_time: [],
         autoplay: true,

      };
   }

   handleModal = () => {
      this.setState({ filterModal: true })
      document.body.style.overflow = 'hidden'; // Disable scrolling

   }
   handleCloseModal = () => {
      this.setState({ filterModal: false })
      document.body.style.overflow = 'visible'; // Disable scrolling

   }
   componentDidMount() {
      if (localStorage.getItem('guest')) {
         localStorage.removeItem('token')
         localStorage.removeItem('guest')

      }
      const token = localStorage.getItem("token");
      localStorage.removeItem('seatLayoutJson');

      axios
         .get(Constants.api + "content/cinemas", {
            headers: {
               "Accept-Language": localStorage.getItem("language"),
               Authorization: `Bearer ${token}`,
               appversion: Constants.av,
               platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            },
         })
         .then((res) => {
            console.log(res);
            console.log("cinemas");
            this.setState({
               cinemas: res.data.output.cinemas,
            });
         })
         .catch((error) => {
            console.error(error);
         });


      axios
         .post(Constants.api + "more/offer", "", {
            headers: {
               "Accept-Language": localStorage.getItem("language"),
               Authorization: `Bearer ${token}`,
               appversion: Constants.av,
               platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            },
         })
         .then((res) => {
            console.log(res);
            console.log("offers");
            this.setState({
               offers: res.data.output,
            });
         });
      this.getMovies();

   }

   getMovies = () => {
      const token = localStorage.getItem("token");

      this.setState({ loading: true })
      axios
         .post(Constants.api + "content/movies", {
            "cinema": this.state.filtercinema.length > 0 ? this.state.filtercinema.join(',') : "all",
            "experience": this.state.filterexperience.length > 0 ? this.state.filterexperience.join(',') : "all",
            "genre": this.state.filtergenre.length > 0 ? this.state.filtergenre.join(',') : "all",
            "language": this.state.filterlanguage.length > 0 ? this.state.filterlanguage.join(',') : "all",
            "rating": this.state.filterrating.length > 0 ? this.state.filterrating.join(',') : "all",
            "time": this.state.filtertime.length > 0 ? this.state.filtertime.join(',') : "all"
         }, {
            headers: {
               "Accept-Language": localStorage.getItem("language"),
               Authorization: `Bearer ${token}`,
               appversion: Constants.av,
               platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            },
         })
         .then((res) => {
            //console.log(res);
            console.log(res.data.output);


            this.setState({ loading: false })
            let cs = [];
            let coming = [];
            if (res.data.output) {
               Object.entries(res.data.output.comingsoon).map(([key, value1]) => (
                  Object.entries(res.data.output.comingsoon[key].comingSoon).map(([key2, value2]) => (
                     coming.push(res.data.output.comingsoon[key].comingSoon[key2])
                  ))
               ));
            }



            console.log(coming);
            this.setState({
               comingSoon: coming,
               topPick: res.data.output ? res.data.output.topPick : [],
               thisWeek: res.data.output ? res.data.output.thisWeek : [],
               lastChance: res.data.output ? res.data.output.lastChance : [],
               advance: res.data.output ? res.data.output.advanceBookingHome : [],
               advanceTab: res.data.output ? res.data.output.advanceBooking : [],

               rating: res.data.output ? res.data.output.ratings : [],
               language: res.data.output ? res.data.output.nslanguages : [],
               nowShowing: res.data.output ? res.data.output.nowshowing : [],
               sliders: res.data.output ? res.data.output.slider : [],
               time: res.data.output ? res.data.output.movieTimings : [],
               genres: res.data.output ? res.data.output.genreList : [],
               moviecinema: res.data.output ? res.data.output.cinemas : [],
               experiences: res.data.output ? res.data.output.experiences : [],
            });
         });
   }
   resetFilter = () => {
      var clist = document.getElementsByTagName("input");
      for (var i = 0; i < clist.length; ++i) { clist[i].checked = false; }
      this.setState({
         filtercinema: [], filterexperience: [], filtergenre: [], filterrating: [], filterlanguage: [], filtertime: [],
         filter_cinema: [], filter_experience: [], filter_genre: [], filter_rating: [], filter_language: [], filter_time: []
      }, this.getMovies)
   }
   applyFilter = () => {
      this.setState({ filterWeb: false });
      document.body.style.overflow = 'visible'; // Enable scrolling


      var clist = document.getElementsByTagName("input");
      for (var i = 0; i < clist.length; ++i) { clist[i].checked = false; }
      this.setState({
         filtercinema: this.state.filter_cinema,
         filterexperience: this.state.filter_experience, filtergenre:
            this.state.filter_genre, filterrating: this.state.filter_rating,
         filterlanguage: this.state.filter_language, filtertime: this.state.filter_time,
         filterModal: false
      },
         this.getMovies)


   }
   render() {
      let playTrailer = (trailerUrl, name) => {
         this.setState({
            showTrailer: true,
            trailerUrl: trailerUrl,
            moviename: name
         });
      }
      let filter_cinema = this.state.filter_cinema
      let filter_genre = this.state.filter_genre
      let filter_time = this.state.filter_time
      let filter_experience = this.state.filter_experience
      let filter_language = this.state.filter_language
      let filter_rating = this.state.filter_rating

      let setFilter = (type, name) => {
         console.log(type);
         let array = [];
         if (type == 'cinema') array = this.state.filter_cinema;
         if (type == 'experience') array = this.state.filter_experience;
         if (type == 'time') array = this.state.filter_time;
         if (type == 'rating') array = this.state.filter_rating;
         if (type == 'language') array = this.state.filter_language;
         if (type == 'genre') array = this.state.filter_genre;

         if (!array.includes(name)) {
            //checking weather array contain the id
            array.push(name); //adding to array because value doesnt exists
         } else {
            array.splice(array.indexOf(name), 1); //deleting
         }
         if (type == 'cinema')
            this.setState({
               filter_cinema: array
            });

         if (type == 'experience')
            this.setState({
               filter_experience: array
            });

         if (type == 'genre')
            this.setState({
               filter_genre: array
            });

         if (type == 'rating')
            this.setState({
               filter_rating: array
            });

         if (type == 'language')
            this.setState({
               filter_language: array
            });

         if (type == 'time')
            this.setState({
               filter_time: array
            });


      };
      const responsive = {
         superLargeDesktop: {
            // the naming can be any, depends on you.

            breakpoint: { max: 6000, min: 3000 },
            items: 1,
         },
         desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
         },
         tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
         },
         mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
         },
      };
      const responsive3 = {
         superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
         },
         desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
         },
         tablet: {
            breakpoint: { max: 1024, min: 480 },
            items: 2,
         },
         mobile: {
            breakpoint: { max: 480, min: 0 },
            items: 1,
         },
      };
      const responsive2 = {
         superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
         },
         desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
         },
         tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
         },
         mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
         },
      };

      // let sliderarr = [];
      // for (let i = 0; i < this.state.sliders.length; i++) {
      //   sliderarr.push(this.state.sliders[i].webimgbig);
      // }
      let banner = this.state.sliders.map((item, j) => {

         let nameForUrl = item.title.toString().toLowerCase().replaceAll(" ", "-");
         let linkUrl =
            "/moviesessions/" +
            nameForUrl +
            "/" +
            item.id +
            "?language=" +
            item.language;
         if (j < 5 && item.webimgbig != '') {
            return (
               <>
                  <div className="banner-item">
                     <div className="banner-item-img">
                        <img src={item.webimgbig} alt="" />
                     </div>
                     <div className="banner-info">
                        <div className="banner-info-upper">
                           <h3 className="title-info">{item.title}</h3>
                           <div className="age-highlight title-info" style={{ backgroundColor: item.ratingColor }}>{item.rating}</div>

                           <span className="movie-title d-none-desktop">{item.title}</span>
                           <span className="age-highlight d-none-desktop" style={{ backgroundColor: item.ratingColor }}>{item.rating}</span >

                        </div>
                        <div className="banner-info-lower">
                           <div className="banner-info-lower-part">
                              <p>
                                 <span className="lang">{item.language}</span>
                                 <span className="movie-about">{item.genre}</span>
                                 <span className="length">
                                    {item.runTime} MIN&nbsp;

                                 </span>
                              </p>
                           </div>
                           <div className="banner-info-lower-part banner-trailer">
                              {item.trailerUrl != "" ? (
                                 <a
                                    className="pointer-cursor"
                                    onClick={() => {
                                       this.setState({
                                          showTrailer: true,
                                          trailerUrl: item.trailerUrl,
                                          moviename: item.title,
                                          autoplay: true
                                       }
                                       );
                                    }}
                                 >
                                    <img
                                       src={require("../assets/Trailer icon.png").default}
                                       className="play-icon"
                                    />
                                    <p >
                                       {localStorage.getItem("language") !== "ar" ? (
                                          "Watch Trailer"
                                       ) : (
                                          <Text>مشاهدة الإعلان
                                          </Text>
                                       )}
                                    </p>
                                 </a>
                              ) : (
                                 ""
                              )}
                              {/* 
            <a href={item.trailerUrl} target="_blank">
               <img
                  src={require("../assets/play.png").default}
                  className="play-icon"
                  />
               <p>Watch Trailer</p>
            </a>
            */}
                           </div>
                           <div className="banner-info-lower-part">
                              <a href={linkUrl}>
                                 <button className="btnc btnc-primary">
                                    {localStorage.getItem("language") !== "ar" ? (
                                       "BOOK TICKETS"
                                    ) : (
                                       <Text>حجز التذاكر</Text>
                                    )}
                                 </button>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </>
            );
         }
      });
      return (
         <div class="banner-item movie-banner movie-banner-img">
            <div class="banner-item-img">
               <img src={require("../assets/maintenance.jpeg").default} alt="" />
            </div>
         </div>
      );
   }
}
export default Maintenance;