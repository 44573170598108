import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import Text from "react-text";
import Modal from "react-modal";

class experiencePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="experience-banner seat-banner">
          <img src={require("../../assets/dolby_bg.png").default} />
          <h3>   {localStorage.getItem("language") !== "ar" ? (
            "DOLBY CINEMA"
          ) : (
            <Text>سينما دولبي</Text>
          )}</h3>

        </div>
        <div className="experience-page home-content-container offer-food-body">
          <div className="experience-title padbothvertical-40">
            <div className="experience-head">
              <h3>   {localStorage.getItem("language") !== "ar" ? (
                "the total cinema experience"
              ) : (
                <Text>تجربة السينما الشاملة</Text>
              )}</h3>
            </div>
            <div className="experience-info">
              <p>

                {localStorage.getItem("language") !== "ar" ? (
                  "Dolby Cinema, the ultimate movie experience in cities around the world,is now in Kuwait. Discover the next level of premium entertainment with a theatre designed and built to provide the best sound, picture, and environment for any movie — letting you step into another reality and surrender to the story."
                ) : (
                  <Text>سينما دولبي ، أفضل تجربة سينمائية في المدن حول العالم ، موجودة الآن في الكويت. اكتشف المستوى التالي من الترفيه المتميز مع مسرح مصمم ومصمم لتوفير أفضل صوت وصورة وبيئة لأي فيلم - مما يتيح لك الدخول إلى واقع آخر والاستسلام للقصة.</Text>
                )}</p>
            </div>
          </div>
          <div className="experience-white-bg padbothvertical-40">
            <div className="experience-info-box">
              <div className="exp-info-img">
                <img src={require("../../assets/ultravid.gif").default} />
              </div>
              <div className="exp-info-box">
                <h4>   {localStorage.getItem("language") !== "ar" ? (
                  "ULTRAVIVID PICTURE"
                ) : (
                  <Text>صورة فائقة الدقة</Text>
                )}</h4>
                <div className="exp-info-box-detail">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "An amazingly lifelike image brings each movie moment to life with spectacular impact."
                    ) : (
                      <Text>تضفي صورة نابضة بالحياة بشكل مذهل على كل لحظة فيلم تنبض بالحياة
                        بتأثير مذهل.</Text>
                    )}

                  </p>
                  <p>


                    {localStorage.getItem("language") !== "ar" ? (
                      "Powered by dual-laser projection technology and engineered  for a consistently great experience, Dolby Vision™ <br /> HDR lets you see and feel more of the movies you love."
                    ) : (
                      <Text>مدعوم بتقنية الإسقاط بالليزر المزدوج ومصمم هندسيًا
                        للحصول على تجربة رائعة باستمرار ، Dolby Vision ™ <br />
                        تتيح لك تقنية HDR مشاهدة المزيد من الأفلام التي تحبها والشعور بها.</Text>
                    )}
                  </p>


                  <ul>
                    <li>   {localStorage.getItem("language") !== "ar" ? (
                      "500 times the contrast ratio of standard screens"
                    ) : (
                      <Text>500 ضعف نسبة التباين في الشاشات القياسية</Text>
                    )}</li>
                    <li>  {localStorage.getItem("language") !== "ar" ? (
                      "Twice (2x) the brightness of standard screens"
                    ) : (
                      <Text>ضعف سطوع الشاشات القياسية</Text>
                    )}</li>
                    <li>   {localStorage.getItem("language") !== "ar" ? (
                      "Unsurpassed color palette"
                    ) : (
                      <Text>لوحة ألوان غير مسبوقة</Text>
                    )}</li>
                  </ul>
                </div>
                <div className="exp-info-box-img exp-img-one">
                  <img src={require("../../assets/Image 5.png").default} />
                </div>
              </div>
            </div>
          </div>
          <div className="experience-grey-bg">
            <div className="experience-info-box">
              <div className="exp-info-box">
                <h4>   {localStorage.getItem("language") !== "ar" ? (
                  "IMMERSIVE SOUND"
                ) : (
                  <Text>صوت غامر</Text>
                )}</h4>
                <div className="exp-info-box-detail">
                  <p>

                    {localStorage.getItem("language") !== "ar" ? (
                      " A complete atmosphere of lifelike sound is created by Dolby Atmos® to put you in the experience."
                    ) : (
                      <Text>يتم إنشاء جو كامل من الصوت النابض بالحياة بواسطة Dolby
                        أتموس® ليضعك في التجربة.</Text>
                    )}
                  </p>
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Sounds are precisely placed and moved throughout the cinema to play the story out all around you with lifelike depth,detail, and clarity, putting you at the center of the story. An arrow shoots overhead, a river tumbles down into the An arrow shoots overhead, a river tumbles down into the valley below, and a race car screeches around the corner, all with incredible realism."
                    ) : (
                      <Text>يتم وضع الأصوات بدقة ونقلها في جميع أنحاء السينما
                        لعرض القصة من حولك بعمق نابض بالحياة ،
                        التفاصيل والوضوح ، مما يضعك في قلب القصة.
                        ينطلق سهم فوق الرأس ، ينهار نهر لأسفل في
                        وادي في الأسفل ، وعربة سباق صرخت قاب قوسين أو أدنى ،
                        كل ذلك بواقعية لا تصدق.</Text>
                    )}

                  </p>

                  <ul>
                    <li>  {localStorage.getItem("language") !== "ar" ? (
                      "Atmosphere of sound "
                    ) : (
                      <Text>جو الصوت</Text>
                    )}</li>
                    <li>   {localStorage.getItem("language") !== "ar" ? (
                      "128 individual sounds around you"
                    ) : (
                      <Text>128 صوتًا فرديًا من حولك</Text>
                    )}</li>
                  </ul>

                </div>
                <div className="exp-info-box-img">
                  <img src={require("../../assets/crop.png").default} />
                </div>
              </div>
              <div className="exp-info-img">
                <img
                  src={
                    require("../../assets/immersive-sound.gif")
                      .default
                  }
                />
              </div>
            </div>
          </div>
          <div className="experience-white-bg">
            <div className="experience-info-box">
              <div className="exp-info-img">
                <img src={require("../../assets/ultimate-movie-destination.gif").default} />
              </div>
              <div className="exp-info-box">
                <h4>  {localStorage.getItem("language") !== "ar" ? (
                  "THE ULTIMATE MOVIE DESTINATION"
                ) : (
                  <Text>الوجهة النهائية للأفلام</Text>
                )}</h4>
                <div className="exp-info-box-detail">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Every aspect of the design, from entrance to interior to seating, comes together to make the experience uniquely immersive and impactful."
                    ) : (
                      <Text>كل جانب من جوانب التصميم ، من المدخل إلى الداخل إلى
                        الجلوس ، معًا لجعل التجربة فريدة من نوعها
                        غامرة ومؤثرة.</Text>
                    )}
                  </p>
                  <p>

                    {localStorage.getItem("language") !== "ar" ? (
                      "Premium seats are designed for maximum comfort and clear sight lines, giving you a great perspective on 2D and 3D  action. And because you can reserve your seats ahead of time, you can get your favorite spot in the house, whether it’s just for you, your family, or group of friends."
                    ) : (
                      <Text>تم تصميم المقاعد المتميزة لتوفير أقصى درجات الراحة والوضوح
                        خطوط الرؤية ، مما يمنحك منظورًا رائعًا على ثنائية وثلاثية الأبعاد
                        فعل. ولأنك تستطيع حجز مقاعدك قبل ذلك
                        الوقت ، يمكنك الحصول على مكانك المفضل في المنزل ، سواء
                        إنه مخصص لك أو لعائلتك أو لمجموعة من الأصدقاء فقط.</Text>
                    )}
                  </p>


                  <ul>
                    <li>Reserved seating   {localStorage.getItem("language") !== "ar" ? (
                      "Reserved seating "
                    ) : (
                      <Text>جلوس محجوز</Text>
                    )}</li>
                    <li>   {localStorage.getItem("language") !== "ar" ? (
                      "Clear line of sight"
                    ) : (
                      <Text>خط رؤية واضح</Text>
                    )}</li>
                  </ul>



                </div>
                <div className="exp-info-box-img exp-img-three">
                  <img
                    src={
                      require("../../assets/4312eb13d79c9bfee17a8b4eae344194.png")
                        .default
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default experiencePage;
