import axios from "axios";
import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import * as Constants from "../../Constants/index";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { act } from "react-dom/cjs/react-dom-test-utils.production.min";
import ShowDetails from "../showdetails/ShowDetails.component";
import Modal from "react-modal";
import TrailerModal from "../trailer/trailer.component";
import { Link, Redirect } from "react-router-dom";
import Text from "react-text";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Loading } from "../header/loader.component";
import swal from "sweetalert";
import Login from "../../pages/login.component";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
let arabicmodal =
  localStorage.getItem("language") === "ar" ? "arabicmodal" : "";
let crossarabic =
  localStorage.getItem("language") === "ar" ? "crossarabic" : "";

class CinemaSession extends Component {
  // constructor(props) {
  //   super(props);
  // this.
  state = {
    amcode: this.props.match.params.amcode,

    lat: 0.0,
    lng: 0.0,
    date: "",
    lang: "ALL",
    format: "ALL",
    price: "ALL",
    time: "ALL",
    cinetype: "ALL",
    special: "ALL",
    hc: "ALL",
    output: {},
    lngs: [],
    daySessions: [],
    cinema: {},
    shows: [],
    days: [],
    seats: [],
    selectedDate: "",
    selectedDay: "",
    todaydate: "",
    showtime: "",
    showTrailer: false,
    category: "",
    seatType: "",
    ttypeCode: "",
    // category: "Bachelor",
    // seatType: "Premium",
    quantity: 1,
    selectedShowTime: "",
    movies: {},
    showTrailer: false,
    trailerUrl: "",
    isHovered: false,
    isHovered2: false,
    isHovered3: false,
    isHovered4: false,
    showProceed: false,
    areacode: "",
    seatCategory: [],
    seatTypes: [],
    seatType: "",
    experienceSessions: [],
    selectMovie: "",
    seatCount: 0,
    loading: true,
    login: false,
    cinemas: [],
    experiences: [],
  };
  // }

  componentDidMount() {
    window.scrollTo(0, 0);

    let testIfJson = JSON.parse(localStorage.getItem("seatLayoutJson"));

    if (
      localStorage.getItem("seatLayoutJson") != null &&
      typeof testIfJson == "object"
    ) {
      this.setState(
        {
          quantity: testIfJson.quantity,
          areacode: testIfJson.areacode,
          seats: testIfJson.seats,
          cinemaId: testIfJson.cinemaId,
          sessionId: testIfJson.sessionId,
          mid: testIfJson.amcode,
          ttypeCode: testIfJson.ttypeCode,
        },
        this.clickProceed
      );
    }
    axios
      .post(
        Constants.api + "content/csessions",
        {
          dated: this.state.todaydate,
          mid: this.state.amcode,
        },

        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
          },
        }
      )
      .then((res) => {
        console.log(res);
        let days = [];
        let movies = {};
        if (res.data.output != null) {
          Object.keys(res.data.output.days).forEach(function (key2) {
            console.log("showing dates...");
            days.push(res.data.output.days[key2]);
          });
          this.setState({
            selectedDay: days[0]["wdf"],
            selectedDate: days[0]["dt"],
          });

          this.setState({
            days: days,
            movies: movies,
            cinema: res.data.output.cinema,
            daySessions: res.data.output.daySessions,
            selectMovie: res.data.output.daySessions[0].movie.id,
            loading: false,
            cinemas: res.data.output.cinemas,
            experiences:res.data.output.exps
          });
     

        
         // this.setState({ experiences })


        }
        else swal(res.data.msg);
        // console.log(this.state.shows[0]["showTime"]);
      });
  }
  scrolltoDiv = (id) => {
    alert(id)
    var element = document.getElementById(id);
    element.scrollIntoView();
  };
  clickProceed = () => {
    const moviesessionPath = window.location.pathname;

    // Store the URL path in local storage
    localStorage.setItem("moviesessionPath", moviesessionPath);
    if (localStorage.getItem("token") && localStorage.getItem("token") != "") {
      this.setState({
        seatLayout: true,
      });
    } else {
      console.log("seatLayoutJson");

      let seatLayoutJson = {
        quantity: this.state.quantity,
        areacode: this.state.areacode,
        seats: this.state.seats,
        cinemaId: this.state.cinemaId,
        sessionId: this.state.sessionId,
        mid: this.state.amcode,
        ttypeCode: this.state.ttypeCode,
        url: window.location.href,
      };
      console.log(seatLayoutJson);
      // Put the object into storage
      localStorage.setItem("seatLayoutJson", JSON.stringify(seatLayoutJson));
      this.setState({ login: true });
    }
  };
  increaseQuantity = () => {
    if (this.state.quantity < this.state.seatCount)
      this.setState({ quantity: this.state.quantity + 1 });

  };
  decreaseQuantity = () => {
    if (this.state.quantity > 1)
      this.setState({ quantity: this.state.quantity - 1 });
  };

  handledropdownchange = (event) => {
    // Here, selectedIndex will give you the index of the selected option

    // You can perform any additional actions you need based on the selected option and index
    // For example, you can call a function with the selected index:
    this.clearSelection(event.target.value);
  };
  handleClickById = (
    type,
    elementId,
    cinema,
    time,
    cinemaId,
    sessionId,
    dated,
    amcode,
    showTime
  ) => {
    if (type != "time") {
      // alert(time)
      let elementToClick = document.getElementById(elementId);

      if (elementToClick) {
        elementToClick.click();
      } else {
        console.error(`Element with id "${elementId}" not found.`);
      }
      if (type == "date")
        this.handleClickById(
          "cinema",
          cinema,
          cinema,
          time,
          cinemaId,
          sessionId,
          dated,
          amcode,
          showTime
        );
      if (type == "cinema")
        this.handleClickById(
          "time",
          time,
          cinema,
          time,
          cinemaId,
          sessionId,
          dated,
          amcode,
          showTime
        );
    } else {
      this.getSeatLayoutAfterLogin(
        cinemaId,
        sessionId,
        showTime,
        amcode,
        dated
      );
    }
  };
  getSeatLayoutAfterLogin = (cinemaId, sessionId, showtime, mid, dated) => {
    if (!localStorage.getItem("token") || localStorage.getItem("token") == "") {
      let seatLayoutJson = {
        url: window.location.href,
        cinemaId,
        sessionId,
        showtime,
        mid,
        dated,
      };
      console.log(seatLayoutJson);
      // Put the object into storage
      localStorage.setItem("seatLayoutJson", JSON.stringify(seatLayoutJson));
      this.setState({ login: true });
      return;
    }
    this.setState({
      seatCategory: [],
      showtime: "",
      seats: [],
      loading: false,
      seatCount: "",
      category: "",
      seatTypes: "",
      showProceed: false,
      seatType: "",
      amcode: mid
    });

    let token = localStorage.getItem("token");
    localStorage.setItem("sessionID", sessionId);
    this.setState({
      showtime: showtime,
      cinemaId: cinemaId,
      sessionId: sessionId,
      loading: true,
    });

    axios
      .post(
        Constants.api + "content/trans/seatlayout",
        {
          cinemaId: cinemaId,
          sessionId: sessionId,
          mid: mid,
          dated: dated,
        },

        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            Authorization: `Bearer ${token}`,
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.result && res.data.result == "success") {
          this.setState({
            seatCategory: res.data.output.seatTypes,
            showtime: showtime,
            seats: res.data.output.rows,
            loading: false,
            seatCount: res.data.output.seatCount,
          });
        } else {
          swal(res.data.msg);
          this.setState({
            showtime: "",
            loading: false,
          });
        }
        // document.getElementById("timing-slot").scrollIntoView()
      })
      .catch((error) => {
        localStorage.clear();
        this.setState({ login: true });
      });
  };
  clearSelection = (cinema) => {
    this.setState({
      seatCategory: [],
      showtime: "",
      seats: [],
      loading: false,
      seatCount: "",
      category: "",
      seatTypes: "",
      showProceed: false,
      selectedCinema: cinema,
    });
  };
  render() {
    window.myComponentInstance = this;

    const clearSelection = (cinema) => {
      this.setState({
        seatCategory: [],
        showtime: "",
        seats: [],
        loading: false,
        seatCount: "",
        category: "",
        seatTypes: "",
        showProceed: false,
        selectedCinema: cinema,
      });
    };
    const changecategory = (
      category,
      seatType,
      areacode = false,
      ttypeCode,
      selectedPrice
    ) => {
      document.getElementById("seat-category-section").scrollIntoView()
      console.log(seatType);
      this.setState({
        selectedPrice: selectedPrice,
        quantity: 1,
      });
      localStorage.setItem("seatCategory", category);
      if (seatType.length != 0) {
        this.setState({
          category: category,
          seatTypes: seatType,
          showProceed: false,
        });
        if (seatType.length == 1)
          this.setState({
            seatType: seatType[0].seatType,
            showProceed: true,
            areacode: seatType[0].areacode,
            ttypeCode: seatType[0].ttypeCode,
            selectedPrice: seatType[0].priceInt,
          });
        else {
          this.setState({
            seatType: "",
            showProceed: false,
            areacode: "",
            ttypeCode: "",
            selectedPrice: 0,
          });
        }
      } else
        this.setState({
          category: category,
          seatType: seatType,
          showProceed: true,
          areacode: areacode,
          ttypeCode: ttypeCode,
        });
      // scrolltoDiv("seat-type-before");

      // scrollagain();
    };
    const scrollagain = () => {
      // scrolltoDiv("seat-type-after");
    };
    const setSeatType = (seatType, areacode, ttypeCode, selectedPrice) => {
      this.setState({
        seatType: seatType,
        showProceed: true,
        areacode: areacode,
        ttypeCode: ttypeCode,
        selectedPrice,
      });

      // scrolltoDiv("quantitydiv");
    };
    let scrolltoDiv = (id) => {
      var element = document.getElementById(id);
      element.scrollIntoView();
    };
    const handleHover = () => {
      this.setState((prevState) => ({
        isHovered: !prevState.isHovered,
      }));
    };
    const handleHover2 = () => {
      this.setState((prevState) => ({
        isHovered2: !prevState.isHovered2,
      }));
    };
    const handleHover3 = () => {
      this.setState((prevState) => ({
        isHovered3: !prevState.isHovered3,
      }));
    };

    const getSeatLayout = (cinemaId, sessionId, showtime, mid, dated) => {
      scrolltoDiv("scrool");

      if (
        !localStorage.getItem("token") ||
        localStorage.getItem("token") == ""
      ) {
        let seatLayoutJson = {
          url: window.location.href,
          cinemaId,
          sessionId,
          showtime,
          mid,
          dated,
        };
        console.log(seatLayoutJson);
        // Put the object into storage
        localStorage.setItem("seatLayoutJson", JSON.stringify(seatLayoutJson));
        this.setState({ login: true });
        return;
      }
      this.setState({
        seatCategory: [],
        showtime: "",
        seats: [],
        loading: false,
        seatCount: "",
        category: "",
        seatTypes: "",
        showProceed: false,
        seatType: "",
        amcode: mid
      });

      let token = localStorage.getItem("token");
      localStorage.setItem("sessionID", sessionId);
      this.setState({
        showtime: showtime,
        cinemaId: cinemaId,
        sessionId: sessionId,
        loading: true,
      });
      axios
        .post(
          Constants.api + "content/trans/seatlayout",
          {
            cinemaId: cinemaId,
            sessionId: sessionId,
            mid: mid,
            dated: dated,
          },

          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              Authorization: `Bearer ${token}`,
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            },
          }
        )
        .then((res) => {

          console.log(res);
          if (res.data.result && res.data.result == "success") {
            this.setState({
              seatCategory: res.data.output.seatTypes,
              showtime: showtime,
              seats: res.data.output.rows,
              loading: false,
              seatCount: res.data.output.seatCount,
            });

            if (res.data.output.seatTypes.length == 1) {
              changecategory(
                this.state.seatCategory[0].seatType,
                this.state.seatCategory[0].seatTypes,
                this.state.seatCategory[0].areacode,
                this.state.seatCategory[0].ttypeCode,
                this.state.seatCategory[0].priceInt
              );
            }
          } else {
            swal(res.data.msg);
            this.setState({
              showtime: "",
              loading: false,
            });
          }
          // document.getElementById("timing-slot").scrollIntoView()
        })
        .catch((error) => {
          localStorage.clear();
          this.setState({ login: true });
        });
      //  scrolltoDiv("seat-category-section")

    };
    const selectDate = (date, day) => {
      this.setState({
        seatCategory: [],
        showtime: "",
        seats: [],
        loading: false,
        seatCount: "",
        category: "",
        seatTypes: "",
        showProceed: false,
      });
      document.getElementById("movie-date-box").scrollIntoView();
      this.setState({ selectedDate: date, selectedDay: day, loading: true });

      try {
        axios
          .post(
            Constants.api + "content/csessions",
            {
              dated: date,
              mid: this.state.amcode,
            },
            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              },
            }
          )
          .then((resp) => {
            console.log(resp);
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: false,
              });
              console.log(resp.data.output.daySessions);
              // let cinemas = [];

              //  cinemas.push(resp.data.output.daySessions);

              console.log(this.state.cinemas);

              // var element = document.getElementById("otherDateFilter");
              // element.classList.remove("active");

              this.setState({
                daySessions: resp.data.output.daySessions,

                loading: false,
              });
              console.log(this.state.shows);
            } else {
              console.log(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    let movieinfo = { ...this.state.movies };
    let director = { ...movieinfo.director };
    let cast = { ...movieinfo.cast };
    let mh = Math.floor(movieinfo.runTime / 60);
    let mm = movieinfo.runTime % 60;
    let daySessions = this.state.daySessions;
    const entries = Object.entries(cast);
    const renderCast = [];
    for (const [key, values] of entries) {
      let fullName = `${values.firstName} ${values.lastName}`;
      renderCast.push(fullName);
    }
    console.log(movieinfo);
    console.log(this.state.selectedDay);
    console.log(this.state.selectedDate);

    // dropdown menu
    const options = ["Cinescape 360", "two", "three"];
    const defaultOption = options[0];

    const keys = Object.keys(this.state.cinema);

    let selectedShowTime = this.state.showtime;
    let selectedDate = this.state.selectedDate;
    let experiences = this.state.experiences;

    // this.setState({
    //   selectedDay: this.state.days[0]["wdf"],
    //   selectedDate: this.state.days[0]["dt"],
    // });

    const responsive2 = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
      },
    };

    const days = this.state.days.map(function (item, i) {
      let active = selectedDate == item.dt ? "active" : "";

      return (
        <Tab>
          <div className="movie-date-box" id="movie-date-box">
            <div
              className={`movie-date ${active}`}
              onClick={() => selectDate(item.dt, item.wdf)}
            >
              <p>{item.wd}</p>
              <p>{item.d}</p>
            </div>
            <div className="border-right"></div>
          </div>
        </Tab>
      );
    });
    let category = this.state.category;
    let seatType = this.state.seatType;
    let amcode = this.state.amcode;
    let cinema = this.state.cinema;
    const responsive5 = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
      },
    };
    let map = cinema.lat && cinema.lng ? cinema.lat + "," + cinema.lng : ''
    return (
      <>
        <div className="banner-item movie-banner movie-banner-img">
          <div className="banner-item-img">
            {cinema.webImageUrl && cinema.webImageUrl.length > 0 ? (
              <img src={cinema.webImageUrl} alt="" />
            ) : (
              <div className="dummy-imgs">
                <img
                  src={require("../../assets/bean.jpg").default}
                  alt={cinema.name}
                />
              </div>
            )}
          </div>
          
          <div className="movie-banner-bottom">
            <div className="banner-info">
              <div className="banner-info-upper">
                <h3>{cinema.name}</h3>
              </div>
            </div>
            <div className="banner-info-lower justify-flex pad-60 cinemasession-movie-banners">
              <div className="banner-info-lower-part movie-info cinemasession-movie">
                <div className="movie-info-box movie-box-cinemasession">
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Experiences"
                    ) : (
                      <Text>خبرة</Text>
                    )}
                  </p>
                  <p>
                    {this.state.experiences.map(function (item, i) {
                      return (<img src={item} />)
                    })}
                  </p>
                </div>
                <div className="movie-info-box movie-box-one">
                  <p>
                    
                    {" "}
                    {localStorage.getItem("language") !== "ar" ? (
                      "Location"
                    ) : (
                      <Text>موقعك</Text>
                    )}
                  </p>
                  <p>
                    {cinema.address1} {cinema.city}
                  </p>
                  <a href={`https://www.google.com/maps?q=${cinema.latitude},${cinema.longitude}`} target="_blank">
                     <p style={{ textTransform:"capitalize"}}>  <img src={require("../../assets/location-marker.png").default} /> Maps</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-content-container cinemasession-section">
          <div className="date-time-section padbothvertical-40">
            <div className="main-head">
              <h3>
                {localStorage.getItem("language") !== "ar" ? (
                  "Select Movie"
                ) : (
                  <Text>حدد الفيلم</Text>
                )}
              </h3>
            </div>
            <div className="movie-dates">
              <Tabs>
                <TabList>
                  <div className="movie-date-tabs">
                    <div className="movie-week">{days}</div>
                    <div className="date-filter hidden">
                      <img src={require("../../assets/filter2.png").default} />
                      <div className="filter-buttons">
                        <button className="btnc btnc-ghost">
                          {" "}
                          {localStorage.getItem("language") !== "ar" ? (
                            "EXPERIENCE"
                          ) : (
                            <Text>خبرة</Text>
                          )}
                        </button>
                        <button className="btnc btnc-ghost">
                          {localStorage.getItem("language") !== "ar" ? (
                            "TIME"
                          ) : (
                            <Text>زمن</Text>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </TabList>

                {/* <TabPanel> */}
                <Tabs>
                  {/* <TabList>
                    <div className="cinema-hall-tabs">
                      {this.state.daySessions.map(function (item, i) {
                        return <Tab>{item.movie.title}</Tab>

                      })}
                    </div>

                   
                    <div className="cinema-hall-mobile">
                      <p className="loc-tag">Location</p>
                      <Dropdown
                        options={options}
                       
                        value={defaultOption}
                        placeholder="Select an option"
                      />
                    </div>
                  </TabList> */}

                  <TabPanel>
                    <div className="slider-carousel">
                      <Carousel
                        className="banner-slider mobile-hidden"
                        swipeable={false}
                        draggable={false}
                        arrows={true}
                        showDots={false}
                        responsive={responsive5}
                        ssr={true} // means to render carousel on server-side.
                        infinite={false}
                        autoPlay={false}
                        keyBoardControl={true}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={[]}
                        deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                      >
                        {this.state.cinemas.map(function (item, i) {
                          if (item.id !== amcode)
                            return (
                              <a href={`/cinemasessions/${item.id}`}>
                                <div


                                >
                                  {item.name}
                                </div>
                              </a>
                            );

                        })}
                      </Carousel>
                    </div>

                    {this.state.daySessions.map(function (items, i) {


                      return (<><div className="timing-slot" id="timing-slot">


                        <div className="timing-slot-times time-slot-cinemasessions ">
                          <div className="img-cinemasession">
                            {items.movie.mobimgsmall ? (

                              <img src={items.movie.mobimgsmall} alt={items.movie.title} />

                            ) : (
                              <div className="dummy-img">
                                <img
                                  src={
                                    require("../../assets/logo-mobile.png").default
                                  }
                                  alt={items.movie.title}
                                />
                                <p>{items.movie.title}</p>
                              </div>


                            )}


                            <div class="age-highlight-cinemasession" style={{ backgroundColor: items.ratingColor }}>{items.movie.rating}</div>
                          </div>



                          <div className="movie-cinemasession">
                            <div class="boocking-section">
                              <div class="booking_text mobile-hidden">
                                <h2>
                                  {items.movie.title}{" "}
                                </h2>
                                <button
                                  type="button"
                                  class="age_btn new-payment-rating"
                                  style={{ backgroundColor: items.movie.ratingColor }}>{items.movie.rating}
                                </button>

                              </div>

                              <div className="mobile-view-cinemasession">

                                <span className="movie-title d-none-desktop">{items.movie.title}</span>
                                <span className="age-highlight d-none-desktop" style={{ backgroundColor: items.movie.ratingColor }}>{items.movie.rating}</span >
                              </div>
                            </div>
                            {items.experienceSessions.map(function (item, i) {



                              return (<div className="cinemasession-time-slot">
                                {
                                  <div className="time-left">
                                    <img src={item.experienceIcon} />
                                  </div>
                                }
                                <div className="time-right">
                                  {item.shows.map((sws, k) => {
                                    let active =
                                      selectedShowTime == sws.showTime
                                        ? "active"
                                        : "";
                                    return (
                                      <>
                                        <div
                                          id={`time${sws.sessionId}`}

                                          className={`time-box ${active}`}
                                          onClick={() =>
                                            getSeatLayout(
                                              sws.cinemaId,
                                              sws.sessionId,
                                              sws.showTime,
                                              sws.scheduledFilmId,
                                              selectedDate
                                            )
                                          }
                                        >
                                          {sws.showTime}
                                          <span>{sws.format}</span>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>)
                            })}
                          </div>
                        </div>
                      </div>
                      </>
                      )
                    })}







                  </TabPanel>
                </Tabs>

                {/* <TabPanel> */}
                <Tabs></Tabs>
                {/* </TabPanel> */}
              </Tabs>
            </div>
          </div>
          <div id="scrool">
            {this.state.loading ? <Loading /> : ""}
            {this.state.seatCategory.length > 0 ? (
              <div className="seat-category-section" id="seat-category-section">
                <div className="main-head">
                  <h3>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Select Seat Category"
                    ) : (
                      <Text>حدد فئة المقعد </Text>
                    )}
                  </h3>
                </div>

                <div className="seat-category-contain">
                  {this.state.seatCategory.map(function (item, i) {
                    let catClass = "";
                    if (category == item.seatType) catClass = "active";
                    return (
                      <>
                        <div
                          className={`seat-category-box ${catClass}`}
                          onClick={() =>
                            changecategory(
                              item.seatType,
                              item.seatTypes,
                              item.areacode,
                              item.ttypeCode
                            )
                          }
                          onMouseEnter={handleHover}
                          onMouseLeave={handleHover}
                        >
                          <img src={item.icon} />
                          <img src={item.iconActive} />
                          <p>{item.seatType}</p>
                          {item.seatTypes.length == 0 && item.count > 0 ? (
                            <>
                              {" "}
                              <p>{item.count} available</p> <p>{item.price}</p>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.seatTypes.length > 0 ? (
              <div
                className="seat-type-section seat-category-section"
                id="seat-type"
              >
                <div className="main-head">
                  <h3>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Select Seat Type"
                    ) : (
                      <Text>حدد نوع المقعد</Text>
                    )}
                  </h3>
                </div>
                <div className="seat-category-contain">
                  {this.state.seatTypes.map(function (item, i) {
                    let catClass = "";
                    if (seatType == item.seatType) catClass = "active";

                    return (
                      <div
                        className={`seat-category-box ${catClass}`}
                        onClick={() =>
                          setSeatType(
                            item.seatType,
                            item.areacode,
                            item.ttypeCode
                          )
                        }
                        onMouseEnter={() => handleHover3}
                        onMouseLeave={() => handleHover3}
                      >
                        <img src={item.icon} />
                        <img src={item.iconActive} />
                        <p>{item.seatType}</p>
                        <p className="other-info">{item.count}</p>
                        <p className="other-info">{item.price}</p>
                      </div>
                    );
                  })}
                  {/* <div className="seat-terms">
                  <h4>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Seat selection terms"
                    ) : (
                      <Text>شروط اختيار المقعد</Text>
                    )}
                  </h4>
                  <p>
                    {localStorage.getItem("language") !== "ar" ? (
                      "By selecting these seats, I hereby confirm that the seats selected in each grouping are for members of the same household only, seats cannot be changed once seated, and must maintain a minimum two meters distancing from all other groups. I also confirm that I am vaccinated/recovered and have presented the IMMUNE APP or the KUWAIT MOBILE ID APP where my status is confirmed in green/yellow/orange (Age categories exempted from MOH vaccination plan -below the age of 12- are excluded). I accept that violating these rules will result in losing these tickets without a refund. I also confirm that I will abide by all Cinescape’s COVID – 19 rules and regulations set forth in this booking process and in cinemas, and that violating them will result in losing these tickets."
                    ) : (
                      <Text>
                        باختيار هذه المقاعد ، أؤكد بموجب هذا أن المقاعد المختارة
                        في كل مجموعة مخصصة لأفراد من نفس الأسرة فقط ، ولا يمكن
                        تغيير المقاعد بمجرد الجلوس ، ويجب الحفاظ على مسافة مترين
                        على الأقل من جميع المجموعات الأخرى. أؤكد أيضًا أنني
                        تلقيت التطعيم / تعافيت وقدمت تطبيق منيع أو تطبيق رقم
                        الهاتف المحمول في الكويت حيث تم تأكيد حالتي باللون
                        الأخضر / الأصفر / البرتقالي (الفئات العمرية المستثناة من
                        خطة التطعيم بوزارة الصحة - أقل من 12 عامًا - مستبعدة) .
                        أوافق على أن انتهاك هذه القواعد سيؤدي إلى فقدان هذه
                        التذاكر دون استرداد. أؤكد أيضًا أنني سألتزم بجميع قواعد
                        ولوائح كوفيد سينسكيب - 19 المنصوص عليها في عملية الحجز
                        هذه وفي دور السينما ، وأن انتهاكها سيؤدي إلى فقدان هذه
                        التذاكر.
                      </Text>
                    )}
                  </p>
                </div> */}
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.showProceed ? (
              <div className="seat-quantity-section seat-category-section">
                <div className="main-head">
                  <h3>
                    {localStorage.getItem("language") !== "ar" ? (
                      "Select Ticket Quantity"
                    ) : (
                      <Text>حدد كمية التذكرة</Text>
                    )}
                  </h3>
                </div>
                <div className="ticket-counter">
                  <div className="ticket-button">
                    <p>
                      {this.state.seatType}
                      &nbsp;
                      {localStorage.getItem("language") !== "ar" ? (
                        "Ticket"
                      ) : (
                        <Text>تذكرة</Text>
                      )}
                    </p>
                    <div className="counter-button">
                      <button onClick={() => this.decreaseQuantity()}>
                        <i class="fa fa-minus" aria-hidden="true"></i>
                      </button>
                      <input
                        type="text"
                        name="ticket-count"
                        id="ticket-count"
                        placeholder={this.state.quantity}
                      />
                      <button onClick={() => this.increaseQuantity()}>
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  {/* <div className="gift-card">
                    <p>
                      {" "}
                      {localStorage.getItem("language") !== "ar" ? (
                        "Have a"
                      ) : (
                        <Text>عند</Text>
                      )}
                      &nbsp;
                      <b>
                        <u>
                          {localStorage.getItem("language") !== "ar" ? (
                            "Bank Offers"
                          ) : (
                            <Text>عروض البنك</Text>
                          )}
                        </u>
                      </b>{" "}
                      <b>
                        <u>
                          {localStorage.getItem("language") !== "ar" ? (
                            "Gift Vouchers"
                          ) : (
                            <Text>قسائم الهدايا</Text>
                          )}
                        </u>
                      </b>
                      &nbsp;&nbsp;
                      {localStorage.getItem("language") !== "ar" ? (
                        "or"
                      ) : (
                        <Text>أو</Text>
                      )}
                      &nbsp;&nbsp;
                      <b>
                        <u>
                          {localStorage.getItem("language") !== "ar" ? (
                            "Gift Card?"
                          ) : (
                            <Text>كرت هدية?</Text>
                          )}
                        </u>
                      </b>
                    </p>
                    <div className="input-code-container">
                      <div className="input-code-box">
                        <input
                          type="text"
                          name="code"
                          id="code"
                          placeholder={
                            localStorage.getItem("language") !== "ar"
                              ? " Enter Gift Voucher Code"
                              : "أدخل رمز قسيمة الهدية"
                          }
                        />
                        <img
                          src={require("../../assets/barcode.png").default}
                          alt=""
                        />
                      </div>
                      <button className="btnc btnc-primary">
                        {localStorage.getItem("language") !== "ar" ? (
                          "Apply"
                        ) : (
                          <Text>تطبيق</Text>
                        )}
                      </button>
                    </div>
                  </div> */}
                </div>
                <div className="age-restriction age-cinemasession">
                  <div className="age-allowed">
                    <img
                      src={require("../../assets/18plus.png").default}
                      alt=""
                    />
                  </div>
                  <div className="age-info">
                    <p>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Under 18 needs to be accompanied by parents or any adult age 18 and over."
                      ) : (
                        <Text>
                          يجب أن يكون أقل من 18 عامًا برفقة الوالدين أو أي شخص
                          بالغ يبلغ من العمر 18 عامًا أو أكثر.
                        </Text>
                      )}
                    </p>
                    <p className="tc-web">
                      {localStorage.getItem("language") !== "ar" ? (
                        "By clicking Proceed, I agree to the"
                      ) : (
                        <Text>بالنقر فوق متابعة ، أوافق على</Text>
                      )}
                      &nbsp;
                      <a href="#">
                        {localStorage.getItem("language") !== "ar" ? (
                          "terms and conditions"
                        ) : (
                          <Text>الأحكام والشروط</Text>
                        )}
                      </a>
                    </p>
                  </div>
                  <p className="tc-mob">
                    {localStorage.getItem("language") !== "ar" ? (
                      "By clicking Proceed, I agree to the"
                    ) : (
                      <Text>بالنقر فوق متابعة ، أوافق على</Text>
                    )}
                    &nbsp;
                    <a href="#">
                      {localStorage.getItem("language") !== "ar" ? (
                        "terms and conditions"
                      ) : (
                        <Text>الأحكام والشروط</Text>
                      )}
                    </a>
                  </p>
                  { localStorage.getItem("experience")=='4DX'?
           localStorage.getItem("language") !== "ar" ? (   <center> <img
                class="seat-quantity-section "
                style={{"width":"60%"}}
                        src={require("../../assets/4DXwarning.png").default}
                        alt=""
                      /></center>)
                      : <center> <img
                      class="seat-quantity-section "
                      style={{"width":"60%"}}
                              src={require("../../assets/4DXwarning_ar.png").default}
                              alt=""
                            /></center>:''}
                  <div className="proceed-container proceed-moviesession">
                    {this.state.showProceed ? (
                      <a onClick={this.clickProceed}>
                        <button className="btnc btnc-primary">
                          {localStorage.getItem("language") !== "ar" ? (
                            "PROCEED"
                          ) : (
                            <Text>تقدم</Text>
                          )}
                        </button>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mobile-border-bot"></div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {
          this.state.seatLayout ? (
            <Redirect
              to={{
                pathname: "/seatlayout",

                state: {
                  quantity: this.state.quantity,
                  areacode: this.state.areacode,
                  seats: this.state.seats,
                  cinemaId: this.state.cinemaId,
                  sessionId: this.state.sessionId,
                  mid: this.state.amcode,
                  ttypeCode: this.state.ttypeCode,
                },
              }}
            />
          ) : (
            ""
          )
        }
        {
          this.state.showTrailer ? (
            <>
              <Modal
                isOpen={this.state.showTrailer}
                contentLabel="Example Modal"
                portalClassName="trai-modal"
              >
                {" "}
                <button
                  className="trailer-cross"
                  onClick={() => this.setState({ showTrailer: false })}
                >
                  <i class="fas fa-times"></i>
                </button>
                <TrailerModal
                  trailerUrl={this.state.trailerUrl}
                  name={this.state.moviename}
                />
              </Modal>
            </>
          ) : (
            ""
          )
        }
        <>
          <Modal
            isOpen={this.state.login}
            contentLabel="Example Modal"
            portalClassName={`trai-modal modal-name modal-login ${arabicmodal}`}
          >
            {" "}
            <button
              className={`trailer-cross modal-cross ${crossarabic}`}
              onClick={() => this.setState({ login: false })}
            >
              <i class="fas fa-times"></i>
            </button>
            <Login />
          </Modal>
        </>
      </>

    );
  }
}
export default CinemaSession;
