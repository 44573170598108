import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import banner2 from "../assets/banner-2.png";
import banner3 from "../assets/banner-3.png";
import banner4 from "../assets/banner-4.png";
import Text from "react-text";
import {
   Accordion,
   AccordionItem,
   AccordionItemHeading,
   AccordionItemButton,
   AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
// import "react-tabs/style/react-tabs.css";
import * as Constants from "../Constants/index";
import axios from "axios";
import Modal from "react-modal";
import Moment from "react-moment";
import TrailerModal from "../components/trailer/trailer.component";
import { now } from "moment";
import { Loading } from "../components/header/loader.component";

class Movies extends Component {
   constructor(props) {
      super(props);
      this.state = {
         advance: [],
         nowShowing: [],
         sliders: [],
         comingSoon: [],
         showHoverButtons: '',
         showMovies: "nowshowing",
         topPick: [],
         thisWeek: [],
         lastChance: [],
         showTrailer: false,
         trailerUrl: "",
         cinemas: [],
         filterModal: false,
         offers: [],
         genres: [],
         language: [],
         rating: [],
         time: [],
         experiences: [],
         moviecinema: [],
         filtercinema: [],
         filtergenre: [],
         filtertime: [],
         filterlanguage: [],
         filterrating: [],
         filterexperience: [],
         filter_cinema: [],
         filter_experience: [],
         filter_genre: [],
         filter_language: [],
         filter_rating: [],
         filter_time: [],

      };
   }
   componentDidMount() {
      const query = new URLSearchParams(this.props.location.search);
      const type = query.get('type');
      const token = localStorage.getItem("token");

      //console.log(type);
      if (type == 'comingsoon')
         this.setState({ showMovies: "comingsoon" })
      this.getMovies();
      axios
         .get(Constants.api + "content/cinemas", "", {
            headers: {
               "Accept-Language": localStorage.getItem("language"),
               Authorization: `Bearer ${token}`,
               appversion: Constants.av,
               platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            },
         })
         .then((res) => {
            console.log(res);
            console.log("cinemas");
            this.setState({
               cinemas: res.data.output.cinemas,
            });
         });
      axios
         .post(Constants.api + "more/offer", "", {
            headers: {
               "Accept-Language": localStorage.getItem("language"),
               Authorization: `Bearer ${token}`,
               appversion: Constants.av,
               platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            },
         })
         .then((res) => {
            console.log(res);
            console.log("offers");
            this.setState({
               offers: res.data.output,
            });
         });


   }

   getMovies = () => {
      const token = localStorage.getItem("token");

      this.setState({ loading: true })
      axios
         .post(Constants.api + "content/movies", {
            "cinema": this.state.filtercinema.length > 0 ? this.state.filtercinema.join(',') : "all",
            "experience": this.state.filterexperience.length > 0 ? this.state.filterexperience.join(',') : "all",
            "genre": this.state.filtergenre.length > 0 ? this.state.filtergenre.join(',') : "all",
            "language": this.state.filterlanguage.length > 0 ? this.state.filterlanguage.join(',') : "all",
            "rating": this.state.filterrating.length > 0 ? this.state.filterrating.join(',') : "all",
            "time": this.state.filtertime.length > 0 ? this.state.filtertime.join(',') : "all"
         }, {
            headers: {
               "Accept-Language": localStorage.getItem("language"),
               Authorization: `Bearer ${token}`,
               appversion: Constants.av,
               platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            },
         })
         .then((res) => {
            //console.log(res);
            console.log(res.data.output);


            this.setState({ loading: false })
            let cs = [];
            let coming = [];
            Object.entries(res.data.output.comingsoon).map(([key, value1]) => (
               Object.entries(res.data.output.comingsoon[key].comingSoon).map(([key2, value2]) => (
                  coming.push(res.data.output.comingsoon[key].comingSoon[key2])
               ))

            ));

            console.log(coming);
            this.setState({
               comingSoon: coming,
               topPick: res.data.output.topPick,
               thisWeek: res.data.output.thisWeek,
               lastChance: res.data.output.lastChance,
               advance: res.data.output.advanceBooking,
               rating: res.data.output.ratings,
               language: res.data.output.nslanguages,
               nowShowing: res.data.output.nowshowing,
               sliders: res.data.output.slider,
               time: res.data.output.movieTimings,
               genres: res.data.output.genreList,
               moviecinema: res.data.output.cinemas,
               experiences: res.data.output.experiences,
            });
         });
   }
   resetFilter = () => {
      var clist = document.getElementsByTagName("input");
      for (var i = 0; i < clist.length; ++i) { clist[i].checked = false; }
      this.setState({
         filtercinema: [], filterexperience: [], filtergenre: [], filterrating: [], filterlanguage: [], filtertime: [],
         filter_cinema: [], filter_experience: [], filter_genre: [], filter_rating: [], filter_language: [], filter_time: []
      }, this.getMovies)
   }
   applyFilter = () => {
      var clist = document.getElementsByTagName("input");
      for (var i = 0; i < clist.length; ++i) { clist[i].checked = false; }
      this.setState({
         filtercinema: this.state.filter_cinema,
         filterexperience: this.state.filter_experience, filtergenre:
            this.state.filter_genre, filterrating: this.state.filter_rating,
         filterlanguage: this.state.filter_language, filtertime: this.state.filter_time,
         filterModal: false
      },
         this.getMovies)

   }
   render() {
      let playTrailer = (trailerUrl, name) => {
         this.setState({
            showTrailer: true,
            trailerUrl: trailerUrl,
            moviename: name
         });
      }
      let filter_cinema = this.state.filter_cinema
      let filter_genre = this.state.filter_genre
      let filter_time = this.state.filter_time
      let filter_experience = this.state.filter_experience
      let filter_language = this.state.filter_language
      let filter_rating = this.state.filter_rating

      let setFilter = (type, name) => {
         console.log(type);
         let array = [];
         if (type == 'cinema') array = this.state.filter_cinema;
         if (type == 'experience') array = this.state.filter_experience;
         if (type == 'time') array = this.state.filter_time;
         if (type == 'rating') array = this.state.filter_rating;
         if (type == 'language') array = this.state.filter_language;
         if (type == 'genre') array = this.state.filter_genre;

         if (!array.includes(name)) {
            //checking weather array contain the id
            array.push(name); //adding to array because value doesnt exists
         } else {
            array.splice(array.indexOf(name), 1); //deleting
         }
         if (type == 'cinema')
            this.setState({
               filter_cinema: array
            });

         if (type == 'experience')
            this.setState({
               filter_experience: array
            });

         if (type == 'genre')
            this.setState({
               filter_genre: array
            });

         if (type == 'rating')
            this.setState({
               filter_rating: array
            });

         if (type == 'language')
            this.setState({
               filter_language: array
            });

         if (type == 'time')
            this.setState({
               filter_time: array
            });


      };
      const responsive = {
         superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
         },
         desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
         },
         tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
         },
         mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
         },
      };
      const responsive3 = {
         superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
         },
         desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
         },
         tablet: {
            breakpoint: { max: 1024, min: 480 },
            items: 2,
         },
         mobile: {
            breakpoint: { max: 480, min: 0 },
            items: 1,
         },
      };
      const responsive2 = {
         superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
         },
         desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
         },
         tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
         },
         mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
         },
      };

      // let sliderarr = [];
      // for (let i = 0; i < this.state.sliders.length; i++) {
      //   sliderarr.push(this.state.sliders[i].webimgbig);
      // }
      const banner = this.state.sliders.map((item, j) => {

         let nameForUrl = item.title.toString().toLowerCase().replaceAll(" ", "-");
         let linkUrl =
            "/moviesessions/" +
            nameForUrl +
            "/" +
            item.id +
            "?language=" +
            item.language;
         if (j < 5 && item.webimgbig != '') {
            return (
               <>
                  <div className="banner-item">
                     <div className="banner-item-img">
                        <img src={item.webimgbig} alt="" />
                     </div>
                     <div className="banner-info">
                        <div className="banner-info-upper">
                           <h3>{item.title}</h3>
                           <div className="age-highlight" style={{ backgroundColor: item.ratingColor }}>{item.rating}</div>
                        </div>
                        <div className="banner-info-lower">
                           <div className="banner-info-lower-part">
                              <p>
                                 <span className="lang">{item.language}</span>
                                 <span className="movie-about">{item.genre}</span>
                                 <span className="length">
                                    {item.runTime} MIN&nbsp;

                                 </span>
                              </p>
                           </div>
                           <div className="banner-info-lower-part banner-trailer">
                              {item.trailerUrl != "" ? (
                                 <a
                                    className="pointer-cursor"
                                    onClick={() => {
                                       this.setState({
                                          showTrailer: true,
                                          trailerUrl: item.trailerUrl,
                                       }
                                       );
                                    }}
                                 >
                                    <img
                                       src={require("../assets/play.png").default}
                                       className="play-icon"
                                    />
                                    <p>
                                       {localStorage.getItem("language") !== "ar" ? (
                                          "Watch Trailer"
                                       ) : (
                                          <Text>مشاهدة اعلان</Text>
                                       )}
                                    </p>
                                 </a>
                              ) : (
                                 ""
                              )}
                              {/* 
            <a href={item.trailerUrl} target="_blank">
               <img
                  src={require("../assets/play.png").default}
                  className="play-icon"
                  />
               <p>Watch Trailer</p>
            </a>
            */}
                           </div>
                           <div className="banner-info-lower-part">
                              <a href={linkUrl}>
                                 <button className="btnc btnc-primary">
                                    {localStorage.getItem("language") !== "ar" ? (
                                       "BOOK TICKETS"
                                    ) : (
                                       <Text>حجز التذاكر</Text>
                                    )}
                                 </button>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </>
            );
         }
      });
      return (
         <>

            {/* <section className="banner">
               <Carousel
                  className="banner-slider"
                  swipeable={false}
                  draggable={false}
                  autoPlay={true}
                  arrows={false}
                  showDots={true}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  // autoPlay={this.props.deviceType !== "mobile" ? true : false}

                  autoPlaySpeed={3000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={400}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  deviceType={this.props.deviceType}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
               >
                  {banner}
               </Carousel>
            </section> */}
            <div className="faq-banner seat-banner">
               <img src={require("../assets/my-account.png").default} alt="" />
               {/* <h3>Movies</h3> */}

               <h3>
           
            {localStorage.getItem("language") !== "ar" ? (
              "Movies"
            ) : (
              <Text>الأفلام
              </Text>
            )}
          </h3>
            </div>
            <div class="black-width"></div>
            <div className="home-content-container advanceds">

               {/* <section className="advance-booking-section padbothvertical-40">
                  {this.state.advance.length > 0 ? <div className="main-head">
                     <h3>
                        {localStorage.getItem("language") !== "ar" ? (
                           "ADVANCE BOOKING"
                        ) : (
                           <Text>حجز مسبق</Text>
                        )}
                     </h3>
                  </div> : ''}
                  {/* for mobile */}
               {/* <div className="mobile-display-advance">
                     {this.state.loading ? <Loading /> : ""}

                     <div className="advance-booking">
                        <div className="booking-box booking-box-info">
                           {this.state.advance.map((item, i) => {
                              if (i < 3) {
                                 let aImage = '';
                                 if (this.state.advance.length == 1)
                                    aImage = item.webadvance;
                                 else if (this.state.advance.length == 2)
                                    aImage = item.webadvance2;
                                 else if (this.state.advance.length >= 3)
                                    aImage = item.webadvance3;
                                 else
                                    aImage = item.webadvance;
                                 let nameForUrl = item.title
                                    .toString()
                                    .toLowerCase()
                                    .replaceAll(" ", "-");
                                 let linkUrl =
                                    "/moviesessions" +
                                    "/" +
                                    nameForUrl +
                                    "/" +
                                    item.id +
                                    "?language=" +
                                    item.language;
                                 return (
                                    <div
                                       className="advance-booking-preview"
                                       style={{
                                          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 150%), url(${aImage})`,
                                       }}
                                    >
                                       <div className="advance-booking-content">
                                          <h4>{item.title}</h4>
                                          <p>
                                             {
                                                <Moment format="DD/MM/YYYY">
                                                   {item.openingDate}
                                                </Moment>
                                             }
                                          </p>
                                          <a href={linkUrl}>
                                             <button className="btnc btnc-primary">
                                                {localStorage.getItem("language") !== "ar" ? (
                                                   "BOOK TICKETS"
                                                ) : (
                                                   <Text>حجز التذاكر</Text>
                                                )}
                                             </button>
                                          </a>
                                       </div>
                                    </div>
                                 );
                              }
                           })}
                        </div>
                     </div>
                  </div>


               </section> */}
               <section className="filter-section filter-desktop-section pad-60">
                  <Accordion allowZeroExpanded={true}>
                     <AccordionItem>
                        {/* 
         <AccordionItemHeading>
            */}
                        <div className="filter-div justify-flex">
                           <div className="filter-categories">
                              {/* for desktop */}
                              <a
                                 onClick={() =>
                                    this.setState({ showMovies: "nowshowing" })
                                 }
                                 class={
                                    this.state.showMovies == "nowshowing" ? "active" : ""
                                 }
                              >
                                 {localStorage.getItem("language") !== "ar" ? (
                                    "Now Showing"
                                 ) : (
                                    <Text>يعرض الآن</Text>
                                 )}
                              </a>
                              <a
                                 onClick={() =>
                                    this.setState({ showMovies: "advance" })
                                 }
                                 class={
                                    this.state.showMovies == "advance" ? "active" : ""
                                 }
                              >
                                 {localStorage.getItem("language") !== "ar" ? (
                                    "Advance Booking"
                                 ) : (
                                    <Text>حجز مسبق</Text>
                                 )}
                              </a>
                              <a
                                 onClick={() =>
                                    this.setState({ showMovies: "newmovies" })}
                                 class={
                                    this.state.showMovies == "newmovies" ? "active" : ""
                                 }
                              >
                                 {localStorage.getItem("language") !== "ar" ? (
                                    "New This Week"
                                 ) : (
                                    <Text>جديد هذا الأسبوع</Text>
                                 )}
                              </a>
                              <a
                                 onClick={() =>
                                    this.setState({ showMovies: "lastchance" })
                                 }
                                 class={
                                    this.state.showMovies == "lastchance" ? "active" : ""
                                 }
                              >
                                 {localStorage.getItem("language") !== "ar" ? (
                                    "Last Chance to Watch"
                                 ) : (
                                    <Text>آخر فرصة للمشاهدة</Text>
                                 )}
                              </a>
                              <a
                                 onClick={() =>
                                    this.setState({ showMovies: "comingsoon" })
                                 }
                                 class={
                                    this.state.showMovies == "comingsoon" ? "active" : ""
                                 }
                              >
                                 {localStorage.getItem("language") !== "ar" ? (
                                    "Coming Soon"
                                 ) : (
                                    <Text>يعرض قريباً</Text>
                                 )}
                              </a>
                           </div>
                           {/* end desktop */}
                           <div className="filter-button">
                              <AccordionItemButton>
                                 {localStorage.getItem("language") !== "ar" ? (
                                    "Filters"
                                 ) : (
                                    <Text>المرشحات</Text>
                                 )}{" "}
                                 <img src={require("../assets/filter.png").default} />
                              </AccordionItemButton>
                           </div>
                        </div>
                        {/* 
         </AccordionItemHeading>
         */}
                        <AccordionItemPanel>
                           <Tabs>
                              <div className="filters-tab">
                                 <TabList className="filter-tablist">
                                    <Tab>
                                       {/* <div className="circle-tab"></div> */}
                                       {filter_cinema.length > 0 ? <div className="circle-tab"></div> : ''}
                                       {localStorage.getItem("language") !== "ar" ? (
                                          "Location"
                                       ) : (
                                          <Text>موقع</Text>
                                       )}

                                    </Tab>
                                    <Tab>
                                       {filter_experience.length > 0 ? <div className="circle-tab"></div> : ''}

                                       {localStorage.getItem("language") !== "ar" ? (
                                          "Experience"
                                       ) : (
                                          <Text>خبرة</Text>
                                       )}
                                    </Tab>
                                    <Tab>
                                       {filter_genre.length > 0 ? <div className="circle-tab"></div> : ''}
                                       {localStorage.getItem("language") !== "ar" ? (
                                          "Genre"
                                       ) : (
                                          <Text>النوع</Text>
                                       )}
                                    </Tab>
                                    <Tab>
                                       {filter_time.length > 0 ? <div className="circle-tab"></div> : ''}
                                       {localStorage.getItem("language") !== "ar" ? (
                                          "Time"
                                       ) : (
                                          <Text>زمن</Text>
                                       )}
                                    </Tab>
                                    <Tab>
                                       {filter_language.length > 0 ? <div className="circle-tab"></div> : ''}
                                       {localStorage.getItem("language") !== "ar" ? (
                                          "Language"
                                       ) : (
                                          <Text>لغة</Text>
                                       )}
                                    </Tab>
                                    <Tab>
                                       {filter_rating.length > 0 ? <div className="circle-tab"></div> : ''}
                                       {localStorage.getItem("language") !== "ar" ? (
                                          "Rating"
                                       ) : (
                                          <Text>تقييم</Text>
                                       )}
                                    </Tab>
                                 </TabList>
                                 <div className="reset-all" >
                                    <a onClick={() => this.resetFilter()} href="javascript:void(0)">
                                       <b>
                                          {localStorage.getItem("language") !== "ar" ? (
                                             "Reset"
                                          ) : (
                                             <Text>إعادة ضبط</Text>
                                          )}
                                       </b>
                                       &nbsp;
                                       {localStorage.getItem("language") !== "ar" ? (
                                          "All Filters"
                                       ) : (
                                          <Text>كل المرشحات</Text>
                                       )}
                                    </a>
                                    <a onClick={() => this.applyFilter()}>
                                       <button className="btnc btnc-primary">
                                          {localStorage.getItem("language") !== "ar" ? (
                                             "Apply Filters"
                                          ) : (
                                             <Text>تطبيق المرشحات</Text>
                                          )}
                                       </button>
                                    </a>
                                 </div>
                              </div>
                              <div className="filters-panel">
                                 <TabPanel>
                                    <div className="select-location">
                                       {this.state.moviecinema.length > 0 ? this.state.moviecinema.map(function (item, i) {
                                          let checked = '';
                                          if (filter_cinema.includes(item.id))
                                             checked = "checked";
                                          else
                                             checked = ""
                                          return (
                                             <>

                                                <div >
                                                   <input
                                                      id={`radio-${i}`}
                                                      class="radio-custom"
                                                      name="radio-group"
                                                      type="checkbox"
                                                      onClick={() => setFilter('cinema', item.id)}
                                                      checked={checked}
                                                   />
                                                   <label for={`radio-${i}`} class="radio-custom-label"  >
                                                      {item.name}
                                                   </label>
                                                </div>

                                             </>
                                          )
                                       }) : ''}

                                    </div>
                                 </TabPanel>
                                 <TabPanel>
                                    <div className="select-location">
                                       {this.state.experiences.length > 0 ? this.state.experiences.map(function (item, i) {
                                          let checked = '';
                                          if (filter_experience.includes(item))
                                             checked = "checked";
                                          else
                                             checked = ""
                                          return (
                                             <>

                                                <div >
                                                   <input
                                                      id={`radio-${i + 1}`}
                                                      class="radio-custom"
                                                      name="radio-group"
                                                      type="checkbox"
                                                      onClick={() => setFilter('experience', item)}
                                                      checked={checked}
                                                   />
                                                   <label for={`radio-${i + 1}`} class="radio-custom-label"  >
                                                      {item}
                                                   </label>
                                                </div>

                                             </>
                                          )
                                       }) : ''}
                                    </div>
                                 </TabPanel>
                                 <TabPanel>
                                    <div className="select-location">
                                       {this.state.genres.length > 0 ? this.state.genres.map(function (item, i) {
                                          let checked = ''
                                          if (filter_genre.includes(item))
                                             checked = "checked";
                                          else
                                             checked = ""
                                          return (
                                             <>

                                                <div>
                                                   <input
                                                      id={`gradio-${item}`}
                                                      class="radio-custom"
                                                      name="radio-group"
                                                      type="checkbox"
                                                      onClick={() => setFilter('genre', item)}
                                                      checked={checked}
                                                   />
                                                   <label for={`gradio-${item}`} class="radio-custom-label">
                                                      {item}
                                                   </label>
                                                </div>

                                             </>
                                          )
                                       }) : ''}
                                    </div>
                                 </TabPanel>
                                 <TabPanel>
                                    <div className="select-location">
                                       {this.state.time.length > 0 ? this.state.time.map(function (item, i) {
                                          let checked = ''
                                          if (filter_time.includes(item.id))
                                             checked = "checked";
                                          else
                                             checked = ""
                                          return (
                                             <>

                                                <div>
                                                   <input
                                                      id={`tradio-${i}`}
                                                      class="radio-custom"
                                                      name="radio-group"
                                                      type="checkbox"
                                                      onClick={() => setFilter('time', item.id)}
                                                      checked={checked}
                                                   />
                                                   <label for={`tradio-${i}`} class="radio-custom-label">
                                                      {item.name}
                                                   </label>
                                                </div>

                                             </>
                                          )
                                       }) : ''}
                                    </div>
                                 </TabPanel>
                                 <TabPanel>
                                    <div className="select-location">

                                       {this.state.language.length > 0 ? this.state.language.map(function (item, i) {
                                          let checked = ''
                                          if (filter_language.includes(item))
                                             checked = "checked";
                                          else
                                             checked = ""
                                          return (
                                             <>

                                                <div>
                                                   <input
                                                      id={`lradio-${item}`}
                                                      class="radio-custom"
                                                      name="radio-group"
                                                      type="checkbox"
                                                      onClick={() => setFilter('language', item)}
                                                      checked={checked}
                                                   />
                                                   <label for={`lradio-${item}`} class="radio-custom-label">
                                                      {item}
                                                   </label>
                                                </div>

                                             </>
                                          )
                                       }) : ''}
                                    </div> </TabPanel>
                                 <TabPanel>
                                    <div className="select-location">

                                       {this.state.rating.length > 0 ? this.state.rating.map(function (item, i) {
                                          let checked = ''
                                          if (filter_rating.includes(item))
                                             checked = "checked";
                                          else
                                             checked = ""
                                          return (
                                             <>

                                                <div>
                                                   <input
                                                      id={`rradio-${item}`}
                                                      class="radio-custom"
                                                      name="radio-group"
                                                      type="checkbox"
                                                      onClick={() => setFilter('rating', item)}
                                                      checked={checked}
                                                   />
                                                   <label for={`rradio-${item}`} class="radio-custom-label">
                                                      {item}
                                                   </label>
                                                </div>

                                             </>
                                          )
                                       }) : ''}
                                    </div>                             </TabPanel>
                              </div>
                           </Tabs>
                        </AccordionItemPanel>
                     </AccordionItem>
                  </Accordion>
               </section>
               <section className="filter-section filter-mobile-section pad-60">
                  {/* 
   <AccordionItemHeading>
   */}
                  <div className="filter-div">
                     <div className="filter-categories">
                        {/* for mobile */}
                        <Carousel
                           className="banner-slider"
                           swipeable={false}
                           draggable={false}
                           arrows={true}
                           showDots={false}
                           responsive={responsive2}
                           ssr={true} // means to render carousel on server-side.
                           infinite={false}
                           autoPlay={false}
                           keyBoardControl={true}
                           containerClass="carousel-container"
                           removeArrowOnDeviceType={[]}
                           deviceType={this.props.deviceType}
                           dotListClass="custom-dot-list-style"
                           itemClass="carousel-item-padding-40-px"
                        >
                           <a
                              onClick={() =>
                                 this.setState({ showMovies: "nowshowing" })}
                              class={
                                 this.state.showMovies == "nowshowing" ? "active" : ""
                              }
                           >
                              {localStorage.getItem("language") !== "ar" ? (
                                 "Now Showing"
                              ) : (
                                 <Text>يعرض الآن</Text>
                              )}
                              <div className="border-rights"></div>
                           </a>
                           <a
                              onClick={() =>
                                 this.setState({ showMovies: "advance" })
                              }
                              class={
                                 this.state.showMovies == "advance" ? "active" : ""
                              }
                           >
                              {localStorage.getItem("language") !== "ar" ? (
                                 "Advance Booking"
                              ) : (
                                 <Text>حجز مسبق</Text>
                              )}
                           </a>
                           <a
                              onClick={() =>
                                 this.setState({ showMovies: "newmovies" })}
                              class={this.state.showMovies == "newmovies" ? "active" : ""}
                           >
                              {localStorage.getItem("language") !== "ar" ? (
                                 "New This Week"
                              ) : (
                                 <Text>جديد هذا الأسبوع</Text>
                              )}
                              <div className="border-rights"></div>
                           </a>
                           <a
                              onClick={() =>
                                 this.setState({ showMovies: "lastchance" })}
                              class={
                                 this.state.showMovies == "lastchance" ? "active" : ""
                              }
                           >
                              {localStorage.getItem("language") !== "ar" ? (
                                 "Last Chance"
                              ) : (
                                 <Text>آخر فرصة</Text>
                              )}
                              <div className="border-rights"></div>
                           </a>
                           <a
                              onClick={() =>
                                 this.setState({ showMovies: "comingsoon" })}
                              class={
                                 this.state.showMovies == "comingsoon" ? "active" : ""
                              }
                           >
                              {localStorage.getItem("language") !== "ar" ? (
                                 "Coming Soon"
                              ) : (
                                 <Text>يعرض قريباً</Text>
                              )}
                              <div className="border-rights"></div>
                           </a>
                        </Carousel>
                     </div>
                     {/* end mobile */}
                     <div className="filter-button">
                        <img
                           src={require("../assets/filter.png").default}
                           onClick={() => {
                              this.setState({ filterModal: true });
                           }}
                        />
                     </div>
                  </div>
               </section>
               <section className="now-showing padbothvertical-40">
                  <div className="now-showing-container">
                     {this.state.showMovies == "nowshowing" && this.state.nowShowing.length > 0
                        ?
                        this.state.nowShowing.map((item, i) => {
                           let nameForUrl = item.title ? item.title
                              .toString()
                              .toLowerCase()
                              .replaceAll(" ", "-") : '';
                           let linkUrl =
                              "/moviesessions" +
                              "/" +
                              nameForUrl +
                              "/" +
                              item.id;
                           return (
                              <div className="now-showing-preview">

                                 <div className="now-showing-preview-img">
                                    {item.tag != '' ? <div class="new_release" style={{ backgroundColor: item.tagColor }}>{item.tag}</div> : ''}

                                    <a
                                       onClick={() => {
                                          this.setState({
                                             showHoverButtons: item.id
                                          });
                                       }}
                                       onMouseEnter={() => {
                                          this.setState({
                                             showHoverButtons: item.id
                                          });
                                       }}
                                       className="block-anchor"
                                    >

                                       {item.mobimgsmall ? (
                                          <a href={linkUrl}>
                                             <img src={item.mobimgsmall} alt={item.title} />
                                          </a>
                                       ) : (
                                          <div className="dummy-img">
                                             <img
                                                src={
                                                   require("../assets/logo-mobile.png").default
                                                }
                                                alt={item.title}
                                             />
                                             <p>{item.title}</p>
                                          </div>


                                       )}
                                    </a>
                                    {/* <img src={item.mobimgsmall} alt={item.title} /> */}
                                    <div className="age-highlight" style={{ backgroundColor: item.ratingColor }}>{item.rating}</div>
                                    {this.state.showHoverButtons == item.id ?
                                       <div className="now-showing-hover">
                                          {item.trailerUrl != "undefined" ?
                                             <a
                                                onClick={() => {
                                                   this.setState({
                                                      showTrailer: true,
                                                      trailerUrl: item.trailerUrl, moviename: item.title
                                                   });
                                                }}
                                                className="block-anchor"
                                             >
                                                <img
                                                   src={require("../assets/play.png").default}
                                                   className="play-icon"
                                                />
                                             </a> : ''}
                                          <a href={linkUrl}>
                                             <button className="btnc btnc-primary">
                                                {localStorage.getItem("language") !== "ar" ? (
                                                   "BOOK NOW"
                                                ) : (
                                                   <Text>احجز الآن</Text>
                                                )}
                                             </button>
                                          </a>
                                       </div> : ''}

                                 </div>

                              </div>
                           );
                        })
                        : ""}
                     {this.state.showMovies == "advance" && this.state.advance.length > 0
                        ?
                        this.state.advance.map((item, i) => {
                           let nameForUrl = item.title ? item.title
                              .toString()
                              .toLowerCase()
                              .replaceAll(" ", "-") : '';
                           let linkUrl =
                              "/moviesessions" +
                              "/" +
                              nameForUrl +
                              "/" +
                              item.id;
                           return (
                              <div className="now-showing-preview">

                                 <div className="now-showing-preview-img">

                                    {item.tag != '' ? <div class="new_release" style={{ backgroundColor: item.tagColor }}>{item.tag}</div> : ''}

                                    <a
                                       onClick={() => {
                                          this.setState({
                                             showHoverButtons: item.id
                                          });
                                       }}
                                       onMouseEnter={() => {
                                          this.setState({
                                             showHoverButtons: item.id
                                          });
                                       }}
                                       className="block-anchor"
                                    >

                                       {item.mobimgsmall ? (
                                          <a href={linkUrl}>
                                             <img src={item.mobimgsmall} alt={item.title} />
                                          </a>
                                       ) : (
                                          <div className="dummy-img">
                                             <img
                                                src={
                                                   require("../assets/logo-mobile.png").default
                                                }
                                                alt={item.title}
                                             />
                                             <p>{item.title}</p>
                                          </div>


                                       )}
                                    </a>
                                    {/* <img src={item.mobimgsmall} alt={item.title} /> */}
                                    <div className="age-highlight" style={{ backgroundColor: item.ratingColor }}>{item.rating}</div>
                                    {this.state.showHoverButtons == item.id ?
                                       <div className="now-showing-hover">
                                          {item.trailerUrl != "undefined" ?
                                             <a
                                                onClick={() => {
                                                   this.setState({
                                                      showTrailer: true,
                                                      trailerUrl: item.trailerUrl, moviename: item.title
                                                   });
                                                }}
                                                className="block-anchor"
                                             >
                                                <img
                                                   src={require("../assets/Trailer icon.png").default}
                                                   className="play-icon"
                                                />
                                             </a> : ''}
                                          <a href={linkUrl}>
                                             <button className="btnc btnc-primary">
                                                {localStorage.getItem("language") !== "ar" ? (
                                                   "BOOK NOW"
                                                ) : (
                                                   <Text>احجز الآن</Text>
                                                )}
                                             </button>
                                          </a>
                                       </div> : ''}

                                 </div>

                              </div>
                           );
                        })
                        : ""}
                     {this.state.showMovies == "comingsoon" && this.state.comingSoon.length > 0
                        ? this.state.comingSoon.map(function (item, i) {
                           let nameForUrl = item.title ? item.title
                              .toString()
                              .toLowerCase()
                              .replaceAll(" ", "-") : '';
                           let linkUrl =
                              "/movie-details" +
                              "/" +
                              nameForUrl +
                              "/" +
                              item.id;
                           return (
                              <div className="now-showing-preview">
                                 <div className="now-showing-preview-img">
                                    {item.tag != '' ? <div class="new_release" style={{ backgroundColor: item.tagColor }}>{item.tag}</div> : ''}

                                    {item.mobimgsmall ? (
                                       <a href={linkUrl}>
                                          <img src={item.mobimgsmall} alt={item.title} />
                                       </a>
                                    ) : (
                                       <div className="dummy-img">
                                          <img
                                             src={
                                                require("../assets/logo-mobile.png").default
                                             }
                                             alt={item.title}
                                          />
                                          <p>{item.title}</p>
                                       </div>
                                    )}
                                    {/* <img src={item.mobimgsmall} alt={item.title} /> */}
                                    <div className="age-highlight" style={{ backgroundColor: item.ratingColor }}>{item.rating}</div>
                                    <div className="now-showing-hover">
                                       {item.trailerUrl != "undefined" ?
                                          <a
                                             onClick={() => playTrailer(item.trailerUrl, item.title)}
                                             className="block-anchor"
                                          >
                                             <img
                                                src={require("../assets/play.png").default}
                                                className="play-icon"
                                             />
                                          </a> : ''}
                                       <a href={linkUrl}>
                                          <button className="btnc btnc-primary">
                                             {localStorage.getItem("language") !== "ar" ? (
                                                "MOVIE INFO"
                                             ) : (
                                                <Text>معلومات الفيلم</Text>)}
                                          </button>
                                       </a>
                                    </div>
                                 </div>
                              </div>
                           );
                        })
                        : ""}
                     {this.state.showMovies == "newmovies" && this.state.thisWeek.length > 0
                        ? this.state.thisWeek.map(function (item, i) {
                           let nameForUrl = item.title ? item.title
                              .toString()
                              .toLowerCase()
                              .replaceAll(" ", "-") : '';
                           let linkUrl =
                              "/moviesessions" +
                              "/" +
                              nameForUrl +
                              "/" +
                              item.id;
                           return (
                              <div className="now-showing-preview">
                                 <div className="now-showing-preview-img">
                                    {item.tag != '' ? <div class="new_release" style={{ backgroundColor: item.tagColor }}>{item.tag}</div> : ''}

                                    {item.mobimgsmall ? (
                                       <a href={linkUrl}>
                                          <img src={item.mobimgsmall} alt={item.title} />
                                       </a>
                                    ) : (
                                       <div className="dummy-img">
                                          <img
                                             src={
                                                require("../assets/logo-mobile.png").default
                                             }
                                             alt={item.title}
                                          />
                                          <p>{item.title}</p>
                                       </div>
                                    )}
                                    {/* <img src={item.mobimgsmall} alt={item.title} /> */}
                                    <div className="age-highlight" style={{ backgroundColor: item.ratingColor }}>{item.rating}</div>
                                    <div className="now-showing-hover">
                                       {item.trailerUrl != "undefined" ?
                                          <a
                                             onClick={() => playTrailer(item.trailerUrl, item.title)}
                                             className="block-anchor"
                                          >
                                             <img
                                                src={require("../assets/play.png").default}
                                                className="play-icon"
                                             />
                                          </a> : ''}
                                       <a href={linkUrl}>
                                          <button className="btnc btnc-primary">
                                             {localStorage.getItem("language") !== "ar" ? (
                                                "BOOK NOW"
                                             ) : (
                                                <Text>احجز الآن</Text>
                                             )}
                                          </button>
                                       </a>
                                    </div>
                                 </div>
                              </div>
                           );
                        })
                        : ""}
                     {this.state.showMovies == "lastchance" && this.state.lastChance.length > 0
                        ? this.state.lastChance.map(function (item, i) {
                           let nameForUrl = item.title ? item.title
                              .toString()
                              .toLowerCase()
                              .replaceAll(" ", "-") : '';
                           let linkUrl =
                              "/moviesessions" +
                              "/" +
                              nameForUrl +
                              "/" +
                              item.id;
                           return (
                              <div className="now-showing-preview">
                                 <div className="now-showing-preview-img">
                                    {item.tag != '' ? <div class="new_release" style={{ backgroundColor: item.tagColor }}>{item.tag}</div> : ''}

                                    {item.mobimgsmall ? (
                                       <a href={linkUrl}>
                                          <img src={item.mobimgsmall} alt={item.title} />
                                       </a>
                                    ) : (
                                       <div className="dummy-img">
                                          <img
                                             src={
                                                require("../assets/logo-mobile.png").default
                                             }
                                             alt={item.title}
                                          />
                                          <p>{item.title}</p>
                                       </div>
                                    )}
                                    {/* <img src={item.mobimgsmall} alt={item.title} /> */}
                                    <div className="age-highlight" style={{ backgroundColor: item.ratingColor }}>{item.rating}</div>
                                    <div className="now-showing-hover">
                                       {item.trailerUrl != "undefined" ?
                                          <a
                                             onClick={() => playTrailer(item.trailerUrl, item.title)}
                                             className="block-anchor"
                                          >
                                             <img
                                                src={require("../assets/play.png").default}
                                                className="play-icon"
                                             />
                                          </a> : ''}
                                       <a href={linkUrl}>
                                          <button className="btnc btnc-primary">
                                             {localStorage.getItem("language") !== "ar" ? (
                                                "BOOK NOW"
                                             ) : (
                                                <Text>احجز الآن</Text>
                                             )}
                                          </button>
                                       </a>
                                    </div>
                                 </div>
                              </div>
                           );
                        })
                        : ""}
                  </div>
               </section>


            </div >

            {
               this.state.showTrailer ? (
                  <>
                     <Modal
                        isOpen={this.state.showTrailer}
                        contentLabel="Example Modal"
                        portalClassName="trai-modal modal-name"
                     >
                        {" "}
                        <button
                           className="trailer-cross"
                           onClick={() => this.setState({ showTrailer: false })}
                        >
                           <i class="fas fa-times"></i>
                        </button>
                        <TrailerModal trailerUrl={this.state.trailerUrl} name={this.state.moviename} />
                     </Modal>
                  </>
               ) : (
                  ""
               )
            }
            {
               this.state.filterModal ? (
                  <Modal
                     isOpen={this.state.filterModal}
                     contentLabel="Example Modal"
                     portalClassName="filt-modal"
                  >
                     {" "}
                     <button
                        className="trailer-cross"
                        onClick={() => this.setState({ filterModal: false })}
                     >
                        <i class="fas fa-times trailer-filter"></i>
                     </button>
                     <div className="filter-head">
                        <h3>FILTERS</h3>
                     </div>
                     <div className="mobile-acc">
                        <Tabs>
                           <div className="filters-tab">
                              <TabList className="filter-tablist">
                                 <Tab>Location {filter_cinema.length > 0 ? <div class="circle-tab circle-mobile"></div> : ''}</Tab>
                                 <Tab>Experience {filter_experience.length > 0 ? <div class="circle-tab circle-mobile"></div> : ''}</Tab>
                                 <Tab>Genre {filter_genre.length > 0 ? <div class="circle-tab circle-mobile"></div> : ''}</Tab>
                                 <Tab>Time {filter_time.length > 0 ? <div class="circle-tab circle-mobile"></div> : ''}</Tab>
                                 <Tab>Language {filter_language.length > 0 ? <div class="circle-tab circle-mobile"></div> : ''}</Tab>
                                 <Tab>Rating {filter_rating.length > 0 ? <div class="circle-tab circle-mobile"></div> : ''}</Tab>
                              </TabList>
                           </div>
                           <div className="filters-panel">
                              <TabPanel>
                                 <div className="select-location">
                                    {this.state.moviecinema.length > 0 ? this.state.moviecinema.map(function (item, i) {
                                       let checked = '';
                                       if (filter_cinema.includes(item.id))
                                          checked = "checked";
                                       else
                                          checked = ""
                                       return (
                                          <>

                                             <div >
                                                <input
                                                   id={`radio-${i}`}
                                                   class="radio-custom"
                                                   name="radio-group"
                                                   type="checkbox"
                                                   onClick={() => setFilter('cinema', item.id)}
                                                   checked={checked}
                                                />
                                                <label for={`radio-${i}`} class="radio-custom-label"  >
                                                   {item.name}
                                                </label>
                                             </div>

                                          </>
                                       )
                                    }) : ''}

                                 </div>
                              </TabPanel>
                              <TabPanel>
                                 <div className="select-location">
                                    {this.state.experiences.length > 0 ? this.state.experiences.map(function (item, i) {
                                       let checked = '';
                                       if (filter_experience.includes(item))
                                          checked = "checked";
                                       else
                                          checked = ""
                                       return (
                                          <>

                                             <div >
                                                <input
                                                   id={`radio-${i + 1}`}
                                                   class="radio-custom"
                                                   name="radio-group"
                                                   type="checkbox"
                                                   onClick={() => setFilter('experience', item)}
                                                   checked={checked}
                                                />
                                                <label for={`radio-${i + 1}`} class="radio-custom-label"  >
                                                   {item}
                                                </label>
                                             </div>

                                          </>
                                       )
                                    }) : ''}
                                 </div>
                              </TabPanel>
                              <TabPanel>
                                 <div className="select-location">
                                    {this.state.genres.length > 0 ? this.state.genres.map(function (item, i) {
                                       let checked = ''
                                       if (filter_genre.includes(item))
                                          checked = "checked";
                                       else
                                          checked = ""
                                       return (
                                          <>

                                             <div>
                                                <input
                                                   id={`gradio-${item}`}
                                                   class="radio-custom"
                                                   name="radio-group"
                                                   type="checkbox"
                                                   onClick={() => setFilter('genre', item)}
                                                   checked={checked}
                                                />
                                                <label for={`gradio-${item}`} class="radio-custom-label">
                                                   {item}
                                                </label>
                                             </div>

                                          </>
                                       )
                                    }) : ''}
                                 </div>
                              </TabPanel>
                              <TabPanel>
                                 <div className="select-location">
                                    {this.state.time.length > 0 ? this.state.time.map(function (item, i) {
                                       let checked = ''
                                       if (filter_time.includes(item.id))
                                          checked = "checked";
                                       else
                                          checked = ""
                                       return (
                                          <>

                                             <div>
                                                <input
                                                   id={`tradio-${i}`}
                                                   class="radio-custom"
                                                   name="radio-group"
                                                   type="checkbox"
                                                   onClick={() => setFilter('time', item.id)}
                                                   checked={checked}
                                                />
                                                <label for={`tradio-${i}`} class="radio-custom-label">
                                                   {item.name}
                                                </label>
                                             </div>

                                          </>
                                       )
                                    }) : ''}
                                 </div>
                              </TabPanel>
                              <TabPanel>
                                 <div className="select-location">

                                    {this.state.language.length > 0 ? this.state.language.map(function (item, i) {
                                       let checked = ''
                                       if (filter_language.includes(item))
                                          checked = "checked";
                                       else
                                          checked = ""
                                       return (
                                          <>

                                             <div>
                                                <input
                                                   id={`lradio-${i}`}
                                                   class="radio-custom"
                                                   name="radio-group"
                                                   type="checkbox"
                                                   onClick={() => setFilter('language', item)}
                                                   checked={checked}
                                                />
                                                <label for={`lradio-${i}`} class="radio-custom-label">
                                                   {item}
                                                </label>
                                             </div>

                                          </>
                                       )
                                    }) : ''}
                                 </div>                             </TabPanel>
                              <TabPanel>
                                 <div className="select-location">

                                    {this.state.rating.length > 0 ? this.state.rating.map(function (item, i) {
                                       let checked = ''
                                       if (filter_rating.includes(item))
                                          checked = "checked";
                                       else
                                          checked = ""
                                       return (
                                          <>

                                             <div>
                                                <input
                                                   id={`rradio-${item}`}
                                                   class="radio-custom"
                                                   name="radio-group"
                                                   type="checkbox"
                                                   onClick={() => setFilter('rating', item)}
                                                   checked={checked}
                                                />
                                                <label for={`rradio-${item}`} class="radio-custom-label">
                                                   {item}
                                                </label>
                                             </div>

                                          </>
                                       )
                                    }) : ''}
                                 </div>                             </TabPanel>
                           </div>
                        </Tabs>
                        <div className="reset-all" >
                           <a onClick={() => this.resetFilter()} href="javascript:void(0)">
                              <b>Reset</b> All Filters
                           </a>
                           <a >
                              <button className="btnc btnc-primary" onClick={() => this.applyFilter()}>Apply Filters</button>
                           </a>
                        </div>
                     </div>
                  </Modal>
               ) : (
                  ""
               )
            }

         </>
      );
   }
}
export default Movies;