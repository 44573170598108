import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://c2195cc4a145700d66d6447f8891e061@o4505550110654464.ingest.sentry.io/4505634369961984%22', // Replace with your actual Sentry DSN
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0, // Set the sample rate for tracing (optional, 1.0 means 100%)
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")


);




