import { reveal as Menu } from "react-burger-menu";
import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import Text from "react-text";

class MenuSlider extends Component {
  handleLogout = () => {
    localStorage.clear();
    window.location = "/";
  };

  render() {
    return (
      <div className="slider-menu">


        <div className="nav-menu-open">
          <p>
            <a href="/">
              {localStorage.getItem("language") !== "ar" ? (
                "HOME"
              ) : (
                <Text>صفحة الرئيسية</Text>
              )}
            </a>
          </p>
          <p>
            <a href="/">
              {localStorage.getItem("language") !== "ar" ? (
                "MOVIES"
              ) : (
                <Text>أفلام</Text>
              )}
            </a>
          </p>
          <p>
            <a href="./experiences">
              {localStorage.getItem("language") !== "ar" ? (
                "EXPERIENCE"
              ) : (
                <Text>خبرة </Text>
              )}
            </a>
          </p>
          <p>
            <a href="/promotion">
              {localStorage.getItem("language") !== "ar" ? (
                "PROMOTIONS"
              ) : (
                <Text>الترقيات</Text>
              )}
            </a>
          </p>
          <p>
            <a href="#">
              {localStorage.getItem("language") !== "ar" ? (
                "NEWS"
              ) : (
                <Text>الإخبارية</Text>
              )}
            </a>
          </p>
          <p>
            <a href="#">
              {localStorage.getItem("language") !== "ar" ? (
                "CORPORATE"
              ) : (
                <Text>شركة كبرى</Text>
              )}
            </a>
          </p>
          <p>
            <a href="#">
              {localStorage.getItem("language") !== "ar" ? (
                "CONTACT US"
              ) : (
                <Text>شركة كبرى</Text>
              )}
            </a>
          </p>
          {localStorage.getItem("userid") ? (
            <p>
              <a href="#" onClick={() => this.handleLogout()}>
                {localStorage.getItem("language") !== "ar" ? (
                  "LOGOUT"
                ) : (
                  <Text>تسجيل خروج </Text>
                )}
              </a>

              {/* <a href="#" onClick={() => this.handleLogout()}>
                        LOGOUT
                      </a> */}
            </p>
          ) : (
            ""
          )}
        </div>

      </div>
    );
  }
}

export default MenuSlider;
