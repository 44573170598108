import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Modal from "react-modal";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import moment from "moment";
import Text from "react-text";
import { Loading } from "../header/loader.component";
import { PaymentInputsContainer } from 'react-payment-inputs';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,

  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class Payment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tckDetailsOutput: {},
      selectSeat: [],
      tab: [],
      cinemaId: this.props.match.params.ccode,
      showcart: true,
      modalType: "",
      modalItem: {},
      selectedFlavor: "",
      selectedCombo: [],
      quantity: 1,
      calculatedTotal: 0,
      concessionFoods: [],
      time: {},
      //seconds: 300,
      showcreditcard: false,
      seconds: parseInt(localStorage.getItem('timeout')) * 60,
      expirationMonth: "",
      expirationYear: "",
      expiryDate: "",
      cardNumber: "",
      cvNumber: "",
      orgId: "",
      jwtToken: "",
      deviceSessionId: "",
      merchantId: "",
      showProceed: true
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }
  clubPayment = () => {
    let token = localStorage.getItem("token")
    this.setState({ loading: true });
    axios
      .post(Constants.api + "clubcard/clubPayment", {
        bookingid: localStorage.getItem("bookingid"),
        booktype: "BOOKING",
        transid: localStorage.getItem("transid"),
        upcoming: true,
        userid: localStorage.getItem("userid"),
      },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            Authorization: `Bearer ${token}`,

          },
        })
      .then((res) => {
        console.log(res);
        if (res.data.result == "success") {

          if (res.data.output.PAID == "YES") {
            this.setState({ loading: false });

            window.location = "/bookingconfirm";
          }
          this.setState({ loading: false });

        } else {
          this.setState({ loading: false,showProceed:false });

          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });
  };
  knetPayment = () => {
    let token = localStorage.getItem("token");
    this.setState({ loading: true });
    axios
      .post(Constants.api + "payment/knet/hmac", {
        bookingid: localStorage.getItem("bookingid"),
        booktype: "BOOKING",
        transid: localStorage.getItem("transid"),
        userid: localStorage.getItem("userid"),
      },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            Authorization: `Bearer ${token}`,

          },
        })
      .then((res) => {
        this.setState({ loading: true });
        console.log(res);
        if (res.data.result == "success") {

          window.location = res.data.output.callingUrl;

        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });
  };
  cybersourcePayment = () => {
    let token = localStorage.getItem("token")
    this.setState({ loading: true });
    axios
      .post(Constants.api + "payment/cybersource/initiate", {
        bookingid: localStorage.getItem("bookingid"),
        userid: localStorage.getItem("userid"),
        transid: localStorage.getItem("transid"),
        booktype: "BOOKING"
      },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            Authorization: `Bearer ${token}`,

          },
        })
      .then((res) => {
        this.setState({ loading: true });
        console.log(res);
        if (res.data.result == "success") {

          this.setState({
            jwtToken: res.data.output.jwtToken,
            deviceSessionId: res.data.output.deviceSessionId,
            merchantId: res.data.output.merchantId,
            orgId: res.data.output.orgId,
          });
          const script = document.createElement("script");
          script.src = "https://h.online-metrix.net/fp/tags.js?org_id=" + this.state.orgId + "&session_id=" + this.state.merchantId + this.state.deviceSessionId;
          script.async = false;
          document.body.appendChild(script);

          window.Cardinal.configure({
            logging: {
              debug: "verbose"
            }
          });

          window.Cardinal.setup("init", {
            jwt: res.data.output.jwtToken,
          });

          let session_Id = "";
          window.Cardinal.on('payments.setupComplete', function (setupCompleteData) {
            console.log('setup complete');
            console.log(setupCompleteData.sessionId);

            session_Id = setupCompleteData.sessionId;

          });
          //  await sleep(10000); //wait 5 seconds




          setTimeout(function () {
            this.payerauth(session_Id);
          }.bind(this), 10000)

          // this.payerauth(session_Id);
        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });

  };
  payerauth = (session_Id, jwt, cardNumber, cvNumber, expirationMonth, expirationYear) => {
    this.setState({ loading: true });
    let token = localStorage.getItem("token")
    // alert(session_Id);
    axios
      .post(Constants.api + "payment/cybersource/payerauth", {
        bookingid: localStorage.getItem("bookingid"),
        userid: localStorage.getItem("userid"),
        transid: localStorage.getItem("transid"),

        cardNumber: this.state.cardNumber.replaceAll(' ', ''),
        cvNumber: this.state.cvNumber,
        expirationMonth: this.state.expiryDate.split('/')[0].replaceAll(' ', ''),
        expirationYear: this.state.expiryDate.split('/')[1].replaceAll(' ', ''),

        referenceId: session_Id,

      },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            Authorization: `Bearer ${token}`,

          },
        })
      .then((res) => {
        this.setState({ loading: true });
        console.log(res);
        if (res.data.result == "success") {

          if (res.data.output.redirect == "1") {
            //show_alert(data.errorDescription);
            window.location = res.data.output.responseURL;
          } else {
            //alert(data);
            let jwt = this.state.jwtToken;
            let cardNumber = this.state.cardNumber.replaceAll(' ', '');
            let cvNumber = this.state.cvNumber;
            let expirationMonth = this.state.expiryDate.split('/')[0].replaceAll(' ', '');
            let expirationYear = this.state.expiryDate.split('/')[1].replaceAll(' ', '');
            let sessionid = this.state.deviceSessionId;
            this.cardinalCall(res.data.output, sessionid, jwt, cardNumber, cvNumber, expirationMonth, expirationYear);
          }

        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });
  }

  cardinalCall = (data, sessionid, jwtToken, cn, cv, em, ey) => {
    this.setState({ loading: true });
    let xid = data.xid;
    console.log(xid);
    let jwt = jwtToken;
    const session_Id = sessionid;

    const cardNumber = cn;
    const cvNumber = cv;
    const expirationMonth = em;
    const expirationYear = ey;

    console.log('cardinal call');
    window.Cardinal.continue('cca',
      {
        "AcsUrl": data.acsURL,
        "Payload": data.pares
      },
      {
        "OrderDetails": {
          "TransactionId": data.authTransId
          // Add the rest of the Order object
        }
      });

    window.Cardinal.on("payments.validated", function (data, jwt) {
      console.log(data);
      console.log(jwt);
      // alert("cardNumber:" + cardNumber);
      // alert("cvNumber:" + cvNumber);
      // alert("session_Id:" + session_Id);

      axios
        .post(Constants.api + "payment/cybersource/payerresponse", {
          bookingid: localStorage.getItem("bookingid"),
          cardNumber: cardNumber,
          cvNumber: cvNumber,
          expirationMonth: expirationMonth,
          expirationYear: expirationYear,
          payerAuth: jwt,
          sessionId: session_Id,
          xid: xid == null ? '' : xid
        },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            },
          })
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            window.location = "/bookingconfirm?result=success&bookingid=" + localStorage.getItem("bookingid") + "&type=BOOKING";


          } else {
            // this.setState({alert:res.data.msg});

            swal(res.data.msg);
            window.location = "/bookingconfirm?result=fail&bookingid=" + localStorage.getItem("bookingid") + "&type=BOOKING";


          }
        });
      // var exp = document.getElementById('expmonth').value;
      // exp = exp.split('/');

      // $.post("/Payment/CyberSourceJWTResponse",
      //   {

      //     cardNumber: this.state.cardNumber,
      //     cvNumber: this.state.cvNumber,
      //     expirationMonth: this.state.expiryDate.split('/')[0],
      //     expirationYear: this.state.expiryDate.split('/')[1],

      //     payerauth: this.state.jwt,
      //     xid: xid,
      //     session_Id: document.getElementById("devicesession").value

      //   },
      //   function (data) {
      //     if (data.reasonCode == "100" && data.responseURL != 'Failure') {
      //       location.href = data.responseURL;
      //     } else {
      //       show_alert(data.errorDescription);
      //       location.href = data.responseURL;
      //     }
      //   }, 'JSON');
      //function (data) {
      //    //var obj = jQuery.parseJSON(data);
      //    console.log(data);
      //    window.location.href = "http://localhost:60886/HOME";
      //});

    });
  }

  componentDidMount() {
    localStorage.removeItem('seatLayoutJson');

    const tckDetailsRequest = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        // const resp =
        await axios
          .post(
            Constants.api + "content/trans/tcksummary/",
            {
              bookingId: localStorage.getItem("bookingid"),
              transid: localStorage.getItem("transid"),
              userId: localStorage.getItem("userid"),
            },
            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              },
            }
          )
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });
              let tckDetailsArray = [];
              localStorage.setItem("bookingid", resp.data.output.bookingId);
              this.setState({
                tckDetailsOutput: resp.data.output,

                concessionFoods: resp.data.output.concessionFoods,
                selectSeat: resp.data.output.seatsArr,
              });

              this.setState({
                totalPrice: resp.data.output.totalTicketPrice,
              });
            } else {
              swal(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
          });
      } catch (err) {
        console.error(err);
      }
    };

    tckDetailsRequest();


    this.startTimer();
  }
  proceed = () => {
    this.setState({ loading: true })
    if (this.state.selectedpayment != '') {
      if (this.state.selectedpayment == "Wallet") this.clubPayment();
      if (this.state.selectedpayment == "KNET") this.knetPayment();
      if (this.state.selectedpayment == "Card") this.cybersourcePayment();

    }
    else swal("", "Please select a Payment Mode first", "info");
  };
  startTimer = () => {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };
  countDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      window.location = "/";
      //clearInterval(this.timer);
    }
  };
  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };
  canceltrans = () => {
    swal({
      title: "",
      text: "Are you sure you want to cancel transaction?",
      type: ""
    }).then(function () {
      window.location = "/";
    });

  }

  cardNumberChange = (event) => {
    this.setState({
      cardNumber: event.target.value
    })
  }
  cvNumberChange = (event) => {
    this.setState({ cvNumber: event.target.value })
  }
  expirationMonthChange = (event) => {
    this.setState({ expirationMonth: event.target.value })
  }
  expirationDateChange = (event) => {
    this.setState({ expiryDate: event.target.value })
  }
  expirationYearChange = (event) => {
    this.setState({ expirationYear: event.target.value })
  }
  render() {

    let tckDetailsOutput = this.state.tckDetailsOutput;
    let openModal = (item, foodType) => {
      if (foodType == "Flavour" || foodType == "combo")
        this.setState({
          showModalFood: true,
          modalItem: item,
        });

      if (foodType == "Individual")
        this.addConcessionFoods(
          "Individual",
          item.id,
          item.description,
          item.descriptionAlt,
          item.headOfficeItemCode,
          item.itemPrice,
          item.itemType,
          item.parentId,
          item.priceInCents,
          1
        );
    };
    let selectflavor = (item, price) => {
      this.setState({
        selectedFlavor: item,
        priceInCents: price,
      });
    };
    let selectCombo = (item, index) => {
      let selectedCombo = this.state.selectedCombo;
      selectedCombo[index] = item;

      this.setState({
        selectedCombo,
      });
      console.log("selectedCombo");
      console.log(selectedCombo);
    };
    let increasequantity = () => {
      let q = this.state.quantity + 1;
      this.setState({ quantity: q });
    };
    let decreasequantity = () => {
      if (this.state.quantity > 1) {
        let q = this.state.quantity - 1;
        this.setState({ quantity: q });
      }
    };

    let increaseFoodQuantity = (i) => {
      let concessionFoods = this.state.concessionFoods;
      concessionFoods[i].quantity = parseInt(concessionFoods[i].quantity + 1);
      this.setState({ concessionFoods });
    };
    let decreaseFoodQuantity = (i) => {
      let concessionFoods = this.state.concessionFoods;
      if (concessionFoods[i].quantity > 1)
        concessionFoods[i].quantity = parseInt(concessionFoods[i].quantity - 1);
      else concessionFoods.splice(i, 1);
      this.setState({ concessionFoods });
    };
    let removeFood = (i) => {
      let concessionFoods = this.state.concessionFoods;
      concessionFoods.splice(i, 1);
      this.setState({ concessionFoods });
    };
    let selectedFlavor = this.state.selectedFlavor;
    let selectedCombo = this.state.selectedCombo;

    let fprice = 0;
    let url = "https://h.online-metrix.net/fp/tags?org_id=" + this.state.orgId + "&session_id=" + this.state.merchantId + this.state.deviceSessionId;
    return (
      <>

        {this.state.loading ? <Loading /> : ""}

        <div className="seat-banner">
          <img src={require("../../assets/food-banner.png").default} alt="" />
        </div>
        <div className="home-content-container food-content-containerpage">

          <div className="food-container">
            <div className="proceed-skip-food"></div>
          </div>
        </div>
        <div className="booked-info-fixed payment-section">
          {!this.state.showcart ? (
            <div
              className="scroll-up"
              onClick={() => this.setState({ showcart: true })}
            >
              <img src={require("../../assets/arrow-up.png").default} alt="" />
            </div>
          ) : (
            <div className="scroll-down">
              <img src={require("../../assets/arrow-up.png").default} alt="" />
            </div>
          )}

          {this.state.showcart ? (
            <div className="booked-active ">
              {this.state.orgId != '' ?
                <iframe style={{ width: "100px", height: "100px", border: 0, position: "absolute" }} src={url}></iframe>
                : ''}
              <div className="booked-info-full">

                <div className="booked-movie-info">
                  <h4 className="booking-info">BOOKING INFORMATION</h4>
                  <div className="booked-movie-full-img">
                    <img
                      src={tckDetailsOutput.poster ? tckDetailsOutput.poster : ""}
                    />
                  </div>
                  <div className="booked-movie-name movie-info-title">
                    <span className="title-movie-info-namegit">
                      {" "}
                      {tckDetailsOutput.moviename
                        ? tckDetailsOutput.moviename
                        : ""}
                    </span>
                    <span className="title-star" style={{ backgroundColor: tckDetailsOutput.ratingColor }}>
                      {tckDetailsOutput.mcensor ? tckDetailsOutput.mcensor : ""}
                    </span>

                  </div>
                  <div className="booked-cinema">
                    {" "}
                    {tckDetailsOutput.cinemaname
                      ? tckDetailsOutput.cinemaname
                      : ""}
                  </div>
                  <div className="booked-cinema-time">
                    <p>
                      <span>
                        {" "}
                        {tckDetailsOutput.showTime
                          ? tckDetailsOutput.showTime
                          : ""}
                      </span>
                      <span>
                        {" "}
                        {tckDetailsOutput.showDate
                          ? moment(tckDetailsOutput.showDate).format("dddd")
                          : ""}
                      </span>
                      <span>
                        {" "}
                        {tckDetailsOutput.showDate
                          ? tckDetailsOutput.showDate
                          : ""}
                      </span>
                    </p>
                  </div>
                  <div className="booked-cinema-ticket">
                    <p>
                      <span>
                        {" "}
                        {tckDetailsOutput.format ? tckDetailsOutput.format : ""}
                      </span>
                      <span>
                        {tckDetailsOutput.experience
                          ? tckDetailsOutput.experience
                          : ""}
                      </span>
                      <span>
                        {" "}
                        {tckDetailsOutput.numofseats
                          ? tckDetailsOutput.numofseats
                          : ""}{" "}
                        Tickets
                      </span>
                    </p>
                  </div>
                  {tckDetailsOutput.seatsArr ? (
                    <div className="booked-cinema-ticket">
                      <p>
                        Seat Number &nbsp;&nbsp;
                        {tckDetailsOutput.seatsArr.map(function (item, i) {
                          return <span>{item}</span>;
                        })}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                </div>
                <div className="total-tickets-price">
                  <p>
                    Ticket Price <span>{tckDetailsOutput.ticketPrice} x {tckDetailsOutput.numofseats}</span>
                  </p>
                  <p>
                    <b>{tckDetailsOutput.totalTicketPrice}</b>
                  </p>
                </div>

              </div>
              <div className="border-right-food"></div>
              {this.state.concessionFoods.length > 0 ?
                <div className="booked-food-full">
                  <h4>FOOD</h4>
                  <div className="food-container-list">
                    {this.state.concessionFoods.map(function (item, i) {
                      fprice += item.priceInCents * item.quantity;
                      return (
                        <div className="food-quantity-container">
                          <div className="food-quantity-img">
                            <img
                              src={
                                require("../../assets/Large Popcorn Soda Combo.png")
                                  .default
                              }
                              alt=""
                            />
                          </div>
                          <div className="food-quantity-name">
                            <p>
                              <b>{item.name}</b>
                            </p>
                            <p>{item.description}</p>
                          </div>
                          {/* <div className="food-inc-btn">
                              <p>{item.price} x {item.quantity}</p>


                            </div> */}
                          <div className="food-inc-btn">
                            {/* 
                                    <p>{item.quantity}</p>
                                            */                    }
                            <p>
                              KD{" "}
                              {(
                                item.itemPrice.split(" ")[1] * item.quantity
                              ).toFixed(3)}
                            </p>
                            <div class="counter-button">
                              <button onClick={() => decreaseFoodQuantity(i)}>
                                -
                              </button>
                              <input
                                type="text"
                                name="ticket-count"
                                id="ticket-count"
                                placeholder={item.quantity}
                              />
                              <button onClick={() => increaseFoodQuantity(i)}>
                                +
                              </button>
                            </div>
                          </div>
                          <div className="food-remove-btn">
                            <a
                              className="pointer-cursor"
                              onClick={() => removeFood(i)}
                            >
                              <i class="fa fa-trash" aria-hidden="true"></i>
                              Remove
                            </a>

                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="food-price-total">
                    <p>
                      Food Price{" "}
                      <span>
                        <b>KWD {parseFloat(fprice / 100).toFixed(3)}</b>
                      </span>
                    </p>
                    <p className="grand-total">
                      <p>
                        GRAND TOTAL{" "}
                        <span>
                          {" "}
                          {tckDetailsOutput.totalTicketPrice
                            ? "KWD " + (parseFloat(tckDetailsOutput.totalTicketPriceInt / 100) + parseFloat(fprice / 100)).toFixed(3)
                            : ""}{" "}
                        </span>
                      </p>
                    </p>

                  </div>
                </div>
                : ''}
              <div className="border-right-food"></div>
              <div className="booked-time-full payment-food">
                <div className="fixed-payment-contain " >
                  <h4>PAYMENT METHOD</h4>
                  <div className="payment-options">
                    <div
                      className={`payment-option pointer-cursor ${this.state.selectedpayment == "Wallet"
                        ? "payment-border"
                        : ""
                        }`}
                      onClick={() =>
                        this.setState({
                          showcreditcard: false,
                          selectedpayment: "Wallet",
                        })
                      }
                    >
                      <img
                        src={
                          require(`../../assets/${this.state.selectedpayment == "Wallet"
                            ? "Group 3090(1).png"
                            : "Group 3090.png"
                            }`).default
                        }
                      />
                      <p>Wallet</p>
                      {/* 
               <p className="wallet-bal">KD 65 Balance</p>
               */}
                    </div>
                    <div
                      className={`payment-option pointer-cursor ${this.state.selectedpayment == "Card"
                        ? "payment-border"
                        : ""
                        }`}
                      onClick={() =>
                        this.setState({
                          showcreditcard: true,
                          selectedpayment: "Card",
                        })
                      }
                    >
                      <img
                        src={
                          require(`../../assets/${this.state.selectedpayment == "Card"
                            ? "Group 3092(1).png"
                            : "Group 3092.png"
                            }`).default
                        }
                      />
                      <p>Credit Card</p>
                    </div>
                    <div
                      className={`payment-option pointer-cursor ${this.state.selectedpayment == "KNET"
                        ? "payment-border"
                        : ""
                        }`}
                      onClick={() =>
                        this.setState({
                          showcreditcard: false,
                          selectedpayment: "KNET",
                        })
                      }
                    >
                      <img
                        src={
                          require(`../../assets/${this.state.selectedpayment == "KNET"
                            ? "Group 3094(1).png"
                            : "Group 3094.png"
                            }`).default
                        }
                      />
                      <p>KNET</p>
                    </div>
                  </div>
                  {this.state.showcreditcard ? (

                    <div className="card-details-container">

                      <div className="input-box-card input-ccv">
                        <img
                          src={
                            require("../../assets/Credit Card Name Normal@2x.png")
                              .default
                          }
                        />

                        <input
                          type="text"
                          name=""
                          placeholder="Cardholder Name"
                        />
                      </div>
                      <div className="input-box-card input-ccv">
                        <img
                          src={
                            require("../../assets/Credit Card Number Normal@2x.png")
                              .default
                          }
                        />

                        <PaymentInputsContainer>
                          {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps }) => (
                            <>
                              <input {...getCardNumberProps({ onChange: this.cardNumberChange })} value={this.state.cardNumber} />
                              {/* <input {...getExpiryDateProps({ onChange: handleChangeExpiryDate })} value={expiryDate} />
                              <input {...getCVCProps({ onChange: handleChangeCVC })} value={cvc} />
                              {meta.isTouched && meta.error && <span>Error: {meta.error}</span>} */}
                            </>
                          )}
                        </PaymentInputsContainer>
                        {/* <input onChange={this.cardNumberChange} maxLength="16" value={this.state.cardNumber} /> */}

                      </div>
                      <div className="card-types">
                        <img
                          src={
                            require("../../assets/express-icon (1).png").default
                          }
                        />
                        &nbsp;
                        <img
                          src={
                            require("../../assets/express-icon (2).png").default
                          }
                        />
                        &nbsp;
                        <img
                          src={
                            require("../../assets/express-icon (4).png").default
                          }
                        />
                      </div>
                      <div className="input-box-card">
                        <div className="input-box-part input-ccv input-date">
                          <img
                            src={
                              require("../../assets/Credit Card Expiry Date  Normal – 1@2x.png")
                                .default
                            }
                          />
                          <PaymentInputsContainer>
                            {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps }) => (
                              <>
                                <input {...getExpiryDateProps({ onChange: this.expirationDateChange })} value={this.state.expiryDate} />
                                {/*  <input {...getCVCProps({ onChange: handleChangeCVC })} value={cvc} />
                              {meta.isTouched && meta.error && <span>Error: {meta.error}</span>} */}
                              </>
                            )}
                          </PaymentInputsContainer>
                          {/* <CreditCardInput
                            cardExpiryInputProps={{ value: this.state.expirationMonth, onChange: this.expirationMonthChange }}
                            fieldClassName="input"
                          /> */}
                        </div>
                        <div className="input-box-part input-ccv">
                          <img
                            src={
                              require("../../assets/express-icon (3).png")
                                .default
                            }
                          />
                          <PaymentInputsContainer>
                            {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps }) => (
                              <>
                                <input {...getCVCProps({ onChange: this.cvNumberChange })} value={this.state.cvNumber} />
                                {/* {meta.isTouched && meta.error && <span>Error: {meta.error}</span>} */}
                              </>
                            )}
                          </PaymentInputsContainer>
                          {/* <CreditCardInput
                            cardCVCInputProps={{ value: this.state.cvNumber, onChange: this.cvNumberChange }}
                            fieldClassName="input"

                          /> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="fixed-proceed-btn-contain">
                  <p>
                    TIME REMAINING {this.state.time.m}:{this.state.time.s}
                  </p>
                  <div className="proceed-food-container d-flex">
                    <button
                      className="btnc btnc-ghost"
                      onClick={this.canceltrans}
                    >
                      Cancel
                    </button>
                    {this.state.showProceed?
                    <a onClick={this.proceed}>
                      <button className="btnc btnc-ghost proceed-btn">Proceed</button>
                    </a>:''}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="booked-info-fixed-mobile-container">
          <div className="booked-info-mobile-tab">
            <div className="mobile-tab-head">
              <div className="cross-mobile-tab">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
              <h4 className="cart-text">
                CART{" "}
                <img
                  src={
                    require("../../assets/Icon feather-shopping-cart.png")
                      .default
                  }
                />
              </h4>
            </div>

            <div className="booked-info-mobile-tab-movie-info">
              {/* <div className="booked-movie-info">
                <div className="booked-movie-name">
                  <h4>ASDAS</h4>
                  <div className="age-highlight">13+</div>
                </div>
                <div className="booked-cinema">asd</div>
                <div className="booked-cinema-time">
                  <p>
                    <span>asd</span>
                    <span>asd </span>
                    <span>asdas</span>
                  </p>
                </div>
                <div className="booked-cinema-ticket">
                  <p>
                    <span>asd</span>
                    <span>Bachelor</span>
                    <span>Premium</span>
                    <span>4 Tickets</span>
                  </p>
                </div>
              </div> */}

              <div className="booked-info-full">
                <div className="booked-movie-full-img">
                </div>
                <div className="booked-movie-info">
                  <div className="booked-movie-name">
                    <h4>
                      {" "}
                      {tckDetailsOutput.moviename
                        ? tckDetailsOutput.moviename
                        : ""}
                    </h4>
                    <div className="age-highlight" style={{ backgroundColor: tckDetailsOutput.ratingColor }}>
                      {tckDetailsOutput.mcensor ? tckDetailsOutput.mcensor : ""}
                    </div>
                  </div>
                  <div className="booked-cinema">
                    {" "}
                    {tckDetailsOutput.cinemaname
                      ? tckDetailsOutput.cinemaname
                      : ""}
                  </div>
                  <div className="booked-cinema-time">
                    <p>
                      <span>
                        {" "}
                        {tckDetailsOutput.showTime
                          ? tckDetailsOutput.showTime
                          : ""}
                      </span>
                      <span>
                        {" "}
                        {tckDetailsOutput.showDate
                          ? moment(tckDetailsOutput.showDate).format("dddd")
                          : ""}
                      </span>
                      <span>
                        {" "}
                        {tckDetailsOutput.showDate
                          ? tckDetailsOutput.showDate
                          : ""}
                      </span>
                    </p>
                  </div>
                  <div className="booked-cinema-ticket">
                    <p>
                      <span>
                        {" "}
                        {tckDetailsOutput.format ? tckDetailsOutput.format : ""}
                      </span>
                      <span>
                        {tckDetailsOutput.experience
                          ? tckDetailsOutput.experience
                          : ""}
                      </span>
                      <span>
                        {" "}
                        {tckDetailsOutput.numofseats
                          ? tckDetailsOutput.numofseats
                          : ""}{" "}
                        Tickets
                      </span>
                    </p>
                  </div>
                  {tckDetailsOutput.seatsArr ? (
                    <div className="booked-cinema-ticket">
                      <p>
                        Seat Number &nbsp;&nbsp;
                        {tckDetailsOutput.seatsArr.map(function (item, i) {
                          return <span>{item}</span>;
                        })}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                </div>
              </div>
              <Accordion allowZeroExpanded={true}>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <p className="food-kd-price"><b>KD {fprice / 100}</b></p>
                      <p><b>FOOD</b>  </p>

                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      {/* food section  mobile*/}
                      <div className="food-container-list">


                        {this.state.concessionFoods.map(function (item, i) {
                          fprice += item.priceInCents * item.quantity;
                          return (

                            <div className="food-quantity-container">
                              <div className="food-quantity-img">
                                <img
                                  src={
                                    require("../../assets/Large Popcorn Soda Combo.png")
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="food-quantity-name">
                                <p>
                                  <b>{item.name}</b>
                                </p>
                                <p>{item.description}</p>
                              </div>
                              <div className="food-inc-btn">
                                <p>{item.price} x {item.quantity}</p>


                              </div>
                              <div className="food-remove-btn">

                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {/* food section mobile*/}
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

              </Accordion>
              <div className="food-container-list">
                <div className="total-amount">
                  <p>Total amount to be paid</p>
                  <p>
                    KD{" "}
                    <span>
                      {" "}
                      {tckDetailsOutput.totalTicketPrice
                        ? tckDetailsOutput.totalTicketPrice
                        : ""}{" "}
                    </span>
                  </p>
                </div>
              </div>


              {/*......................................... payment method mobile...................... */}

              <div className="booked-time-full payment-food">
                <div className="fixed-payment-contain" >
                  <h4>Select Payment Method</h4>
                  <div className="payment-options">
                    <div
                      className={`payment-option pointer-cursor ${this.state.selectedpayment == "Wallet"
                        ? "payment-border"
                        : ""
                        }`}
                      onClick={() =>
                        this.setState({
                          showcreditcard: false,
                          selectedpayment: "Wallet",
                        })
                      }
                    >
                      <img
                        src={
                          require(`../../assets/${this.state.selectedpayment == "Wallet"
                            ? "Group 3090(1).png"
                            : "Group 3090.png"
                            }`).default
                        }
                      />
                      <p>Wallet</p>

                    </div>
                    <div
                      className={`payment-option pointer-cursor ${this.state.selectedpayment == "Card"
                        ? "payment-border"
                        : ""
                        }`}
                      onClick={() =>
                        this.setState({
                          showcreditcard: true,
                          selectedpayment: "Card",
                        })
                      }
                    >
                      <img
                        src={
                          require(`../../assets/${this.state.selectedpayment == "Card"
                            ? "Group 3092(1).png"
                            : "Group 3092.png"
                            }`).default
                        }
                      />
                      <p>Credit Card</p>
                    </div>
                    <div
                      className={`payment-option pointer-cursor ${this.state.selectedpayment == "KNET"
                        ? "payment-border"
                        : ""
                        }`}
                      onClick={() =>
                        this.setState({
                          showcreditcard: false,
                          selectedpayment: "KNET",
                        })
                      }
                    >
                      <img
                        src={
                          require(`../../assets/${this.state.selectedpayment == "KNET"
                            ? "Group 3094(1).png"
                            : "Group 3094.png"
                            }`).default
                        }
                      />
                      <p>KNET</p>
                    </div>
                  </div>
                  {this.state.showcreditcard ? (

                    <div className="card-details-container">

                      <div className="input-box-card input-ccv">
                        <img
                          src={
                            require("../../assets/Credit Card Name Normal@2x.png")
                              .default
                          }
                        />


                        <input
                          type="text"
                          name=""
                          placeholder="Cardholder Name"
                        />
                      </div>
                      <div className="input-box-card input-ccv">
                        <img
                          src={
                            require("../../assets/Credit Card Number Normal@2x.png")
                              .default
                          }
                        />

                        <PaymentInputsContainer>
                          {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps }) => (
                            <>
                              <input {...getCardNumberProps({ onChange: this.cardNumberChange })} value={this.state.cardNumber} />

                            </>
                          )}
                        </PaymentInputsContainer>


                      </div>
                      <div className="card-types">
                        <img
                          src={
                            require("../../assets/express-icon (1).png").default
                          }
                        />
                        &nbsp;
                        <img
                          src={
                            require("../../assets/express-icon (2).png").default
                          }
                        />
                        &nbsp;
                        <img
                          src={
                            require("../../assets/express-icon (4).png").default
                          }
                        />
                      </div>
                      <div className="input-box-card input-ccv input-date">
                        <div className="input-box-part">
                          <img
                            src={
                              require("../../assets/Credit Card Expiry Date  Normal – 1@2x.png")
                                .default
                            }
                          />
                          <PaymentInputsContainer>
                            {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps }) => (
                              <>
                                <input {...getExpiryDateProps({ onChange: this.expirationDateChange })} value={this.state.expiryDate} />

                              </>
                            )}
                          </PaymentInputsContainer>

                        </div>
                        <div className="input-box-part input-ccv">
                          <img
                            src={
                              require("../../assets/express-icon (3).png")
                                .default
                            }
                          />
                          <PaymentInputsContainer>
                            {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps }) => (
                              <>
                                <input {...getCVCProps({ onChange: this.cvNumberChange })} value={this.state.cvNumber} />

                              </>
                            )}
                          </PaymentInputsContainer>

                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* .........................................payment method mobile....................... */}


            </div>
            {/* <div className="food-container-list">
              <div className="food-quantity-container">
                <div className="food-quantity-img">
                  <img
                    src={
                      require("../../assets/Large Popcorn Soda Combo.png")
                        .default
                    }
                  />
                </div>
                <div className="food-quantity-name">
                  <p>
                    <b>Combo 1</b>
                  </p>
                  <p>Small Salt Popcorn Small Pepsi</p>
                </div>
                <div className="food-inc-btn">
                  <p>KD 99.500</p>

                  <div className="food-remove-btn">
                    <button className="btnc btnc-primary">Add</button>
                  </div>
                </div>
              </div>
              <div className="food-quantity-container">
                <div className="food-quantity-img">
                  <img
                    src={
                      require("../../assets/Large Popcorn Soda Combo.png")
                        .default
                    }
                  />
                </div>
                <div className="food-quantity-name">
                  <p>
                    <b>Combo 1</b>
                  </p>
                  <p>Small Salt Popcorn Small Pepsi</p>
                </div>
                <div className="food-inc-btn">
                  <p>KD 99.500</p>
                  <div className="food-remove-btn">
                    <button className="btnc btnc-primary">Add</button>
                  </div>
                </div>
              </div>
            </div> */}

          </div>
          <div className="booked-info-fixed-mobile-total-contain">
            <div className="booked-info-fixed-mobile-total">
              <div className="mobile-fixed-item"><p>
                Ticket Price
              </p></div>
              <div className="mobile-fixed-item-price"> <p>
                <b>{tckDetailsOutput.totalTicketPrice}</b>
              </p></div>
              <div className="mobile-fixed-item-total">Total</div>
            </div>
            <div className="booked-info-fixed-mobile-total">
              <div className="mobile-fixed-item">Food price</div>
              <div className="mobile-fixed-item-price">KD {fprice / 100}</div>
              <div className="mobile-fixed-item-total">KD 42.000</div>
            </div>
          </div>
          <div className="booked-info-fixed-mobile">
            <div className="fixed-mob-1">
              <div className="cancel-img">
                <img src={require("../../assets/Group 1807.png").default} />
              </div>
              <p onClick={() => this.setState({ showcart: false })}>CANCEL</p>
            </div>
            <div className="fixed-mob-1">
              <p>TIME LEFT</p>
              <p>
                <b>{this.state.time.m}:{this.state.time.s}</b>
              </p>
            </div>
            {/* <div className="fixed-mob-1">
              <div className="cart-img">
                <img
                  src={
                    require("../../assets/Icon feather-shopping-cart.png")
                      .default
                  }
                />
                <div className="cart-item">0</div>
              </div>
            </div> */}
            <div className="fixed-mob-1">
              <a onClick={this.proceed}>
                <button className=" btnc btnc-primary btnc-proceed">
                  Proceed
                </button>
              </a>
            </div>
          </div>
        </div>
        {this.state.showModalFood ? (
          <>
            <Modal
              isOpen={this.state.showModalFood}
              contentLabel="Example Modal"
              portalClassName="food-modal"
            >
              {" "}
              <button
                className="trailer-cross"
                onClick={() => this.setState({ showModalFood: false })}
              >
                <i class="fas fa-times"></i>
              </button>
              {/* <TrailerModal trailerUrl={this.state.trailerUrl}  name={this.state.name} /> */}
              <div className="food-modal-content">
                <div className="food-modal-banner">
                  <img src={this.state.modalItem.itemPopupImageUrl} />
                </div>
                <div className="food-modal-content">
                  <div className="food-modal-heading">
                    <h3>{this.state.modalItem.description}</h3>
                    <div className="food-modal-price">
                      <h3>{this.state.modalItem.itemPrice}</h3>
                    </div>
                  </div>
                  <div className="food-modal-flavour">
                    {this.state.modalItem.foodtype == "Flavour" ? (
                      <div className="flavour-select-container">
                        {this.state.modalItem.alternateItems.map(function (
                          item,
                          i
                        ) {
                          let active = "";
                          if (item.description == selectedFlavor)
                            active = "active";
                          return (
                            <div
                              className={`flavour-select-item ${active}`}
                              onClick={() =>
                                selectflavor(
                                  item.description,
                                  item.priceInCents
                                )
                              }
                            >
                              {item.description}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        {this.state.modalItem.packageChildItems.map(function (
                          item,
                          i
                        ) {
                          return (
                            <>
                              <h4 className="combo-head">{item.description}</h4>
                              <div className="flavour-select-container">
                                <div className="food-item-container">
                                  {item.alternateItems.map(function (
                                    food,
                                    index
                                  ) {
                                    let active = "";
                                    if (selectedCombo.indexOf(food) > -1)
                                      active = "food_box_active";
                                    return (
                                      <>
                                        <div
                                          className={`food-combo-preview ${active}`}
                                          onClick={() => selectCombo(food, i)}
                                        >
                                          <div className="food-combo-content">
                                            <h4>{food.description}</h4>
                                            {/* <p>{food.description}</p> */}
                                            <div className="food-combo-contain">
                                              <div className="food-price">
                                                <p>{food.itemPrice}</p>
                                                {/* <button
                                                  className="btnc btnc-primary"
                                                  onClick={() => openModal(item, food.foodtype)}
                                                >
                                                  {localStorage.getItem("language") !==
                                                    "ar" ? (
                                                    "Add"
                                                  ) : (
                                                    <Text>يضيف</Text>
                                                  )}
                                                </button> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                  {this.state.selectedFlavor.length > 0 &&
                    this.state.modalItem.foodtype == "Flavour" ? (
                    <div className="food-modal-summary">
                      <p className="red-info">
                        {localStorage.getItem("language") !== "ar" ? (
                          "YOU SELECTED"
                        ) : (
                          <Text>أنت الذي اخترته</Text>
                        )}
                      </p>
                      <div className="food-after-select">
                        <div className="food-selected">
                          <div className="food-selected-left">
                            <p>{this.state.selectedFlavor}</p>
                          </div>
                          <div className="food-selected-right">
                            <p>
                              {this.state.quantity} x{" "}
                              {this.state.modalItem.itemPrice} = KD{" "}
                              {this.state.quantity > 0
                                ? (this.state.quantity *
                                  this.state.modalItem.priceInCents) /
                                1000
                                : this.state.modalItem.priceInCents / 1000}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="food-counter-container">
                        <div className="food-counter">
                          <i
                            class="fa fa-plus"
                            aria-hidden="true"
                            onClick={() => increasequantity()}
                          ></i>
                          <input type="text" value={this.state.quantity} />
                          <i
                            class="fa fa-minus"
                            aria-hidden="true"
                            onClick={() => decreasequantity()}
                          ></i>
                        </div>

                        <button
                          className="btnc btnc-primary btnc-fooditem"
                          onClick={() =>
                            this.addConcessionFoods(
                              "Flavour",
                              this.state.modalItem.id,
                              this.state.modalItem.description,
                              this.state.modalItem.descriptionAlt,
                              this.state.modalItem.headOfficeItemCode,
                              this.state.modalItem.itemPrice,
                              this.state.modalItem.itemType,
                              this.state.modalItem.parentId,
                              this.state.modalItem.priceInCents,
                              this.state.quantity
                            )
                          }
                        >
                          {localStorage.getItem("language") !== "ar" ? (
                            "ADD ITEM"
                          ) : (
                            <Text>اضافة عنصر</Text>
                          )}
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.selectedCombo.length > 0 &&
                    this.state.modalItem.foodtype == "combo" ? (
                    <div className="food-modal-summary">
                      <p className="red-info">
                        {localStorage.getItem("language") !== "ar" ? (
                          "YOU SELECTED"
                        ) : (
                          <Text>أنت الذي اخترته</Text>
                        )}
                      </p>
                      <div className="food-after-select">
                        <div className="food-selected">
                          <div className="food-selected-left">
                            <p>{this.state.selectedFlavor}</p>
                          </div>
                          <div className="food-selected-right">
                            <p>
                              {this.state.quantity} x{" "}
                              {this.state.modalItem.itemPrice} = KD{" "}
                              {this.state.quantity > 0
                                ? (this.state.quantity *
                                  this.state.modalItem.priceInCents) /
                                1000
                                : this.state.modalItem.priceInCents / 1000}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="food-counter-container">
                        <div className="food-counter">
                          <i
                            class="fa fa-plus"
                            aria-hidden="true"
                            onClick={() => increasequantity()}
                          ></i>
                          <input type="text" value={this.state.quantity} />
                          <i
                            class="fa fa-minus"
                            aria-hidden="true"
                            onClick={() => decreasequantity()}
                          ></i>
                        </div>

                        <button
                          className="btnc btnc-primary btnc-fooditem"
                          onClick={() =>
                            this.addConcessionFoods(
                              "Combo",
                              this.state.modalItem.id,
                              this.state.modalItem.description,
                              this.state.modalItem.descriptionAlt,
                              this.state.modalItem.headOfficeItemCode,
                              this.state.modalItem.itemPrice,
                              this.state.modalItem.itemType,
                              this.state.modalItem.parentId,
                              this.state.modalItem.priceInCents,
                              this.state.quantity
                            )
                          }
                        >
                          {localStorage.getItem("language") !== "ar" ? (
                            "ADD ITEM"
                          ) : (
                            <Text>اضافة عنصر</Text>
                          )}
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Modal>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}
export default Payment;
