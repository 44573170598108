import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Select from "react-select";
import "react-dropdown/style.css";
import OtpInput from "react-otp-input";
import uploadImg from '../assets/upload-app.png'
import call from '../assets/call-app.png'
import whatsapp from '../assets/whatsapp-app.png'
import facebook from '../assets/facebook-app.png'
import instagram from '../assets/instagram-app.png'
import twitter from '../assets/twitter-app.png'
import youtube from '../assets/youtube-app.png'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Text from "react-text";
import moment from "moment";
class AppContact extends Component {
   constructor(props) {
      super(props);
      this.state = {
         cinemas: [],
         countryCode: [],
         date: ''
      };
   }
   dateHandleChange = (date) => {
      // let fromnow = new Date();
      this.setState({
         date: moment(date).format("yyyy-MM-DD")
      });
      // } else swal("dob incorrect");
   };
   render() {
      const language = 'en';
      const style = {
         control: base => ({
            ...base,
            border: 0,
            boxShadow: "none"
         })
      };
      let options = [];
      this.state.countryCode.map(function (item, i) {
         options.push({
            value: item.isdCode,
            label: (
               <div>
                  <img src={item.flag} height="15px" width="15px" />
                  &nbsp;{item.isdCode}
               </div>
            ),
         });
      });
      return (
         <>
            {/* 
<div className="faq-banner seat-banner">
   <img src={require("../assets/my-account.png").default} alt="" />
   <h3>
      {language != "ar" ? (
      "Contact Us"
      ) : (
      <Text>اتصل بنا</Text>
      )}
   </h3>
</div>
<div class="black-width"></div>
*/}
            <div className="contactus-app-bg">
               <div className="app-container">
                  <div className="app-contact-details right-side">
                     <h1>اتصل بنا</h1>
                     <iframe title="Feedback Form" class="freshwidget-embedded-form" id="freshwidget-embedded-form" src="https://kncc.freshdesk.com/widgets/feedback_widget/new?&amp;widgetType=embedded" scrolling="no" height="500px" width="100%" frameborder="0">

                     </iframe>
                     <div className="footer-app-details">
                        <div className="call-app">
                           <h5>مركز الاتصال 1803456 </h5>
                           <p>ساعات العمل من 11:30 إلى 01:30</p>
                        </div>
                        <div className="call-icons-app">
                           <img src={call} alt="" />
                        </div>
                     </div>
                     <div className="footer-app-details">
                        <div className="call-app">
                           <h5>دردشة واتس اب</h5>
                           <p>ساعات العمل من 11:30 إلى 01:30</p>
                        </div>
                        <div className="call-icons-app">
                           <img src={whatsapp} alt="" />
                        </div>
                     </div>
                     <div className="social-link-app">
                        <h5>اتصل بنا</h5>
                        <div className="social-app-icon right">
                           <a href="#"> <img src={facebook} alt="" /></a>
                           <a href="#"> <img src={instagram} alt="" /></a>
                           <a href="#"> <img src={twitter} alt="" /></a>
                           <a href="#"> <img src={youtube} alt="" /></a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* 
<div className="contact-container padbothvertical-40">
   <div className="home-content-containers">
      <div className="opinion-matter">
         <h1>
            {language != "ar" ? (
            "YOUR OPINION MATTERS"
            ) : (
            <Text>رأيك يهم</Text>
            )}
         </h1>
         <p>
            {language != "ar" ? (
            "Have a question? Want an answer? Or some information?"
            ) : (
            <Text>لدي سؤال؟ تريد اجابة؟ أو بعض المعلومات؟</Text>
            )}
         </p>
         <p class="op">
            {language != "ar" ? (
            " What’s on your mind?Please write to us and we will get back to you as soon as we can."
            ) : (
            <Text>ما الذي يدور في ذهنك؟ يرجى مراسلتنا وسنعاود الاتصال بك في أقرب وقت ممكن</Text>
            )}
         </p>
         <div className="contact-us-form">
            <Tabs>
               <div className="filters-tab">
                  <TabList className="filter-tablist helpsupport">
                     <Tab>
                        {language != "ar" ? (
                        "Help & Support"
                        ) : (
                        <Text>ساعد لدعم</Text>
                        )}
                     </Tab>
                     <Tab>
                        {language != "ar" ? (
                        "Search Articles"
                        ) : (
                        <Text>مقالات البحث</Text>
                        )}
                     </Tab>
                  </TabList>
               </div>
               <div className="filters-panel">
                  <TabPanel>
                     <div className="fills-forms">
                        <form>
                           <div class="fill-contact">
                              <div className="placeholder-email">
                                 <img className="all-icon" src={require("../assets/email.png").default} alt="" />
                              </div>
                              <input type="email" placeholder="Email البريد الالكتروني" />
                           </div>
                           <div class="fill-contact">
                              <div className="placeholder-email">
                                 <img className="all-icon" src={require("../assets/doubts-button.png").default} alt="" />
                              </div>
                              <input type="text" placeholder="Subject الموضوع" />
                           </div>
                           <div class="fill-contact">
                              <textarea id="w3review" name="w3review" rows="4" cols="50" placeholder=""></textarea>
                           </div>
                           <div class="fill-contacts">
                              <label className="mobile">
                                 {language != "ar" ? (
                                 "Mobile Number"
                                 ) : (
                                 <Text>رقم الهاتف المحمول</Text>
                                 )}
                              </label>
                              <input type="number" />
                           </div>
                           <div class="fill-contacts">
                              <label className="mobile">
                                 {language != "ar" ? (
                                 "Booking/Order ID"
                                 ) : (
                                 <Text>معرف الحجز / الطلب</Text>
                                 )}
                              </label>
                              <input type="number" />
                           </div>
                           <div class="send-feedback">
                              <button className="sends">
                                 {language != "ar" ? (
                                 "Send Feedback "
                                 ) : (
                                 <Text>ارسل رأيك</Text>
                                 )}
                              </button>
                           </div>
                        </form>
                     </div>
                  </TabPanel>
                  <TabPanel>
                     <iframe title="Feedback Form" class="freshwidget-embedded-form" id="freshwidget-embedded-form" src='https://kncc.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&searchArea=no' scrolling="no" height="500px" width="100%" frameborder="0" >
                     </iframe>
                  </TabPanel>
               </div>
            </Tabs>
         </div>
      </div>
   </div>
</div>
*/}
            {/* 
<section className="location-section padbothvertical-40">
   <div className="home-content-containers">
      <div className="main-head justify-flex">
         <h3>
            {language != "ar" ? (
            "cinescape locations"
            ) : (
            <Text>مواقع سينسكيب</Text>
            )}
         </h3>
         <div className="view-all">
            <a href="/cinelocation">
               {language != "ar" ? (
               "view all locations"
               ) : (
               <Text>عرض كل المواقع</Text>
               )}
            </a>
         </div>
      </div>
      <div className="location-container justify-flex-2">
         {this.state.cinemas.map(function (item, i) {
         let url = "/cinemasessions/" + item.id
         return (
         <>
         <div className="location-preview">
            <div className="location-preview-img">
               <a href={url}>   <img src={item.webThumbImageUrl} /></a>
            </div>
            <div className="location-details">
               <p>{item.city}</p>
               <p>{item.name}</p>
            </div>
         </div>
         </>
         );
         })}
      </div>
      <div className="view-all view-all-mobile">
         <a href="/cinelocation">
            {language != "ar" ? (
            "VIEW ALL LOCATIONS"
            ) : (
            <Text>مشاهدة الكل</Text>
            )}
         </a>
      </div>
   </div>
</section>
*/}
         </>
      );
   }
}
export default AppContact;