import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Modal from "react-modal";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import moment from "moment";
import Text from "react-text";

class infoFixed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showcart: false,
      tckDetailsOutput: {},
      selectSeat: [],
    };
  }
  componentDidMount() {
    const tckDetailsRequest = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        // const resp =
        await axios
          .post(
            Constants.api + "content/trans/tcksummary/",
            {
              bookingId: localStorage.getItem("bookingid"),
              transid: localStorage.getItem("transid"),
            },

            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              },
            }
          )
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });
              let tckDetailsArray = [];

              this.setState({
                tckDetailsOutput: resp.data.output,
                selectSeat: resp.data.output.seatsArr,
              });

              this.setState({
                totalPrice: resp.data.output.totalTicketPrice,
              });
            } else {
              swal(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
          });
      } catch (err) {
        console.error(err);
      }
    };

    tckDetailsRequest();
    const getfood = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        await axios
          .post(
            Constants.api + "content/food/getfood/",
            {
              cinemaId: this.state.cinemaId,
            },

            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              },
            }
          )
          .then((resp) => {
            if (resp.data.result === "success") {
              console.log(resp.data);
              this.setState({ tab: resp.data.output.concessionTabs });
            } else {
              swal(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
          });
      } catch (err) {
        console.error(err);
      }
    };

    getfood();
    this.startTimer();
  }
  submitFood = () => {
    alert("Dd");
    axios
      .post(
        Constants.api + "content/food/addconcession/",
        {
          booktype: "BOOKING",
          cinemaId: this.state.cinemaId,
          concessionFoods: this.state.concessionFoods,
          sessionid: "string",
          transid: 0,
        },

        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
          },
        }
      )
      .then((resp) => {
        swal(resp.data.msg);

      });
  };
  startTimer = () => {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };
  countDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      window.location = "/";
      //clearInterval(this.timer);
    }
  };
  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };

  addConcessionFoods = (
    itemid,
    description,
    descriptionAlt,
    headOfficeItemCode,
    itemPrice,
    itemType,
    parentId,
    priceInCents,
    quantity
  ) => {
    let concessionFoods = this.state.concessionFoods;
    var item = {
      description: description,
      descriptionAlt: descriptionAlt,
      headOfficeItemCode: headOfficeItemCode,
      itemId: itemid,
      itemPrice: itemPrice,
      itemType: itemType,
      items: [{}],
      parentId: parentId,
      priceInCents: priceInCents,
      quantity: quantity,
    };
    concessionFoods.push(item);
    this.setState({ concessionFoods: concessionFoods });
    console.log(concessionFoods);
  };
  render() {
    return (
      <>
        <div className="booked-info-fixed">
          {!this.state.showcart ? (
            <div
              className="scroll-up"
              onClick={() => this.setState({ showcart: true })}
            >
              <img src={require("../../assets/arrow-up.png").default} alt="" />
            </div>
          ) : (
            <div
              className="scroll-down"
              onClick={() => this.setState({ showcart: false })}
            >
              <img src={require("../../assets/arrow-up.png").default} alt="" />
            </div>
          )}
          <div className="booked-collapsed">
            <div className="booked-movie-info">
              <div className="booked-movie-name">
                <h4>adskmflafm</h4>
                <div className="age-highlight" >13+</div>
              </div>
              <div className="booked-cinema"> alnsdasd</div>
              <div className="booked-cinema-time">
                <p>
                  <span>3:00</span>
                  <span>q,weqw</span>
                  <span>qwemqwe</span>
                </p>
              </div>
              <div className="booked-cinema-ticket">
                <p>
                  <span> qweqwe</span>
                  <span>Bachelor</span>
                  <span>Premium</span>
                  <span>5 Tickets</span>
                </p>
              </div>
            </div>
            <div className="booked-movie-total">
              <p>
                Total <span> 5</span>
              </p>
            </div>
            <div className="booked-movie-time-remaining">
              <p>
                Time Remaining <span> 4</span>
              </p>
            </div>
          </div>
          {this.state.showcart ? (
            <div className="booked-active ">
              <div className="booked-info-full">
                <h4>BOOKING INFORMATION</h4>
                <div className="booked-movie-full-img">
                  <img src="" />
                </div>
                <div className="booked-movie-info">
                  <div className="booked-movie-name">
                    <h4>asd</h4>
                    <div className="age-highlight">13+</div>
                  </div>
                  <div className="booked-cinema"> asa</div>
                  <div className="booked-cinema-time">
                    <p>
                      <span>asd</span>
                      <span> asd</span>
                      <span> asd</span>
                    </p>
                  </div>
                  <div className="booked-cinema-ticket">
                    <p>
                      <span> asd</span>
                      <span> asd</span>
                      <span>Premium</span>
                      <span> 5 Tickets</span>
                    </p>
                  </div>
                  <div className="booked-cinema-ticket">
                    <p>
                      Serial Number &nbsp;&nbsp;
                      <span>A1</span>
                      <span>A2</span>
                      <span>A3</span>
                      <span>A4</span>
                    </p>
                  </div>
                  <div className="total-tickets-price">
                    <p>
                      Ticket Price <span>KD 3.500 x 5</span>
                    </p>
                    <p>
                      <b>KD 17.500</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="booked-food-full">
                <h4>FOOD</h4>
                <div className="food-container-list">
                  <div className="food-quantity-container">
                    <div className="food-quantity-img">
                      <img
                        src={
                          require("../../assets/Large Popcorn Soda Combo.png")
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <div className="food-quantity-name">
                      <p>
                        <b>vcdf</b>
                      </p>
                      <p>sdgerw</p>
                    </div>
                    <div className="food-inc-btn">
                      <p>sdfsdf</p>

                      <div class="counter-button">
                        <button>-</button>
                        <input
                          type="text"
                          name="ticket-count"
                          id="ticket-count"
                          placeholder="0"
                        />
                        <button>+</button>
                      </div>
                    </div>
                    <div className="food-remove-btn">
                      <a className="pointer-cursor">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                        Remove
                      </a>
                    </div>
                  </div>
                </div>
                <div className="food-price-total">
                  <p>
                    Food Price{" "}
                    <span>
                      <b>KD 25.500</b>
                    </span>
                  </p>
                </div>
              </div>
              <div className="booked-time-full">
                <div className="fixed-payment-contain">
                  <h4>PAYMENT METHOD</h4>
                  <div className="payment-options">
                    <div className="payment-option pointer-cursor">
                      <img
                        src={
                          require("../../assets/express-icon (1).png").default
                        }
                      />
                      <p>Wallet</p>
                      <p className="wallet-bal">KD 65 Balance</p>
                    </div>
                    <div className="payment-option pointer-cursor">
                      <img
                        src={
                          require("../../assets/express-icon (1).png").default
                        }
                      />
                      <p>Credit Card</p>
                    </div>
                    <div className="payment-option pointer-cursor">
                      <img
                        src={
                          require("../../assets/express-icon (1).png").default
                        }
                      />
                      <p>KNET</p>
                    </div>
                  </div>
                  <div className="card-details-container">
                    <div className="input-box-card">
                      <input
                        type="text"
                        name=""
                        placeholder="Cardholder Name"
                      />
                    </div>
                    <div className="input-box-card">
                      <input type="text" name="" placeholder="Card Number" />
                    </div>
                    <div className="card-types">
                      <img
                        src={
                          require("../../assets/express-icon (1).png").default
                        }
                      />
                      <img
                        src={
                          require("../../assets/express-icon (2).png").default
                        }
                      />
                      <img
                        src={
                          require("../../assets/express-icon (4).png").default
                        }
                      />
                    </div>
                    <div className="input-box-card">
                      <div className="input-box-part">
                        <input type="text" name="" placeholder="Expiry Date" />
                      </div>
                      <div className="input-box-part input-ccv">
                        <img
                          src={
                            require("../../assets/express-icon (3).png").default
                          }
                        />
                        <input type="text" name="" placeholder="CCV" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fixed-proceed-btn-contain">
                  <p>
                    TIME REMAINING {this.state.time.m}:{this.state.time.s}
                  </p>
                  <div className="proceed-food-container">
                    <button className="btnc btnc-ghost">Cancel</button>
                    <button
                      className="btnc btnc-ghost"
                      onClick={this.submitFood}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="booked-info-fixed-mobile-container hidden">
          <div className="booked-info-mobile-tab">
            <div className="mobile-tab-head">
              <div className="cross-mobile-tab">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
              <h4>
                CART{" "}
                <img
                  src={
                    require("../../assets/Icon feather-shopping-cart.png")
                      .default
                  }
                />
              </h4>
            </div>

            <div className="booked-info-mobile-tab-movie-info">
              <div className="booked-movie-info">
                <div className="booked-movie-name">
                  <h4>ASDAS</h4>
                  <div className="age-highlight">13+</div>
                </div>
                <div className="booked-cinema">asd</div>
                <div className="booked-cinema-time">
                  <p>
                    <span>asd</span>
                    <span>asd </span>
                    <span>asdas</span>
                  </p>
                </div>
                <div className="booked-cinema-ticket">
                  <p>
                    <span>asd</span>
                    <span>Bachelor</span>
                    <span>Premium</span>
                    <span>4 Tickets</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="food-container-list">
              <div className="food-quantity-container">
                <div className="food-quantity-img">
                  <img
                    src={
                      require("../../assets/Large Popcorn Soda Combo.png")
                        .default
                    }
                  />
                </div>
                <div className="food-quantity-name">
                  <p>
                    <b>Combo 1</b>
                  </p>
                  <p>Small Salt Popcorn Small Pepsi</p>
                </div>
                <div className="food-inc-btn">
                  <p>KD 99.500</p>

                  <div className="food-remove-btn">
                    <button className="btnc btnc-primary">Add</button>
                  </div>
                </div>
              </div>
              <div className="food-quantity-container">
                <div className="food-quantity-img">
                  <img
                    src={
                      require("../../assets/Large Popcorn Soda Combo.png")
                        .default
                    }
                  />
                </div>
                <div className="food-quantity-name">
                  <p>
                    <b>Combo 1</b>
                  </p>
                  <p>Small Salt Popcorn Small Pepsi</p>
                </div>
                <div className="food-inc-btn">
                  <p>KD 99.500</p>
                  <div className="food-remove-btn">
                    <button className="btnc btnc-primary">Add</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="booked-info-fixed-mobile-total-contain">
            <div className="booked-info-fixed-mobile-total">
              <div className="mobile-fixed-item">Ticket price</div>
              <div className="mobile-fixed-item-price">KD 30.500</div>
              <div className="mobile-fixed-item-total">Total</div>
            </div>
            <div className="booked-info-fixed-mobile-total">
              <div className="mobile-fixed-item">Food price</div>
              <div className="mobile-fixed-item-price">KD 11.500</div>
              <div className="mobile-fixed-item-total">KD 42.000</div>
            </div>
          </div>
          <div className="booked-info-fixed-mobile">
            <div className="fixed-mob-1">
              <div className="cancel-img">
                <img src={require("../../assets/Group 1807.png").default} />
              </div>
              <p>CANCEL</p>
            </div>
            <div className="fixed-mob-1">
              <p>TIME LEFT</p>
              <p>
                <b>6:35</b>
              </p>
            </div>
            <div className="fixed-mob-1">
              <div className="cart-img">
                <img
                  src={
                    require("../../assets/Icon feather-shopping-cart.png")
                      .default
                  }
                />
                <div className="cart-item">0</div>
              </div>
            </div>
            <div className="fixed-mob-1">
              <button className=" btnc btnc-primary btnc-proceed">
                Proceed
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default infoFixed;
