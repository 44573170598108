import React, { Component } from "react";

class ShowDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movieInfo: this.props.movieInfo,
      cinemaName: this.props.cinemaName,
      selectedShowTime: this.props.selectedShowTime,
      selectedDate: this.props.selectedDate,
      selectedDay: this.props.selectedDay,
      category: this.props.category,
      seatType: this.props.seatType,
      quantity: this.props.quantity,
    };
  }
  render() {
    console.log(this.state.movieInfo.title);
    console.log(this.state.cinemaName);
    console.log(this.state.selectedShowTime);
    console.log(this.state.selectedDate);
    console.log(this.state.selectedDay);
    console.log(this.state.category);
    console.log(this.state.seatType);
    console.log(this.state.quantity);
    return (
      <>
        <div className="booked-info-fixed">
          <div className="scroll-up">
            <img src={require("../../assets/arrow-up.png").default} alt="" />
          </div>
          <div className="booked-collapsed">
            <div className="booked-movie-info">
              <div className="booked-movie-name">
                <h4>{this.state.movieInfo.title}</h4>
                <div className="age-highlight">
                  {this.state.movieInfo.rating}
                </div>
              </div>
              <div className="booked-cinema">{this.state.cinemaName}</div>
              <div className="booked-cinema-time">
                <p>
                  <span>{this.state.selectedShowTime}</span>
                  <span>{this.state.selectedDay}</span>
                  <span>{this.state.selectedDate}</span>
                </p>
              </div>
              <div className="booked-cinema-ticket">
                <p>
                  <span>2D</span>
                  <span>{this.state.category}</span>
                  <span>{this.state.seatType}</span>
                  <span>{this.state.quantity}</span>
                </p>
              </div>
            </div>
            <div className="booked-movie-total">
              <p>
                Total <span>KD 25.300</span>
              </p>
            </div>
            <div className="booked-movie-time-remaining">
              <p>
                Time Remaining <span>4:35</span>
              </p>
            </div>
          </div>
          <div className="booked-active hidden">
            <div className="booked-info-full">
              <h4>BOOKING INFORMATION</h4>
              <div className="booked-movie-full-img">
                <img src={require("../../assets/SSposter.png").default} />
              </div>
              <div className="booked-movie-info">
                <div className="booked-movie-name">
                  <h4>Bombshell</h4>
                  <div className="age-highlight">13+</div>
                </div>
                <div className="booked-cinema">Cinescape AlKout</div>
                <div className="booked-cinema-time">
                  <p>
                    <span>15:30</span>
                    <span>Wednesday</span>
                    <span>27 December 2020</span>
                  </p>
                </div>
                <div className="booked-cinema-ticket">
                  <p>
                    <span>2D</span>
                    <span>Bachelor</span>
                    <span>Premium</span>
                    <span>3 Tickets</span>
                  </p>
                </div>
                <div className="booked-cinema-ticket">
                  <p>
                    Serial Number &nbsp;&nbsp;
                    <span>A1</span>
                    <span>A2</span>
                    <span>A3</span>
                    <span>A4</span>
                  </p>
                </div>
                <div className="total-tickets-price">
                  <p>
                    Ticket Price <span>KD 3.500 x 5</span>
                  </p>
                  <p>
                    <b>KD 17.500</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="booked-food-full">
              <h4>FOOD</h4>
              <div className="food-container-list">
                <div className="food-quantity-container">
                  <div className="food-quantity-img">
                    <img
                      src={
                        require("../../assets/Large Popcorn Soda Combo.png")
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="food-quantity-name">
                    <p>
                      <b>Combo 1</b>
                    </p>
                    <p>Small Salt Popcorn Small Pepsi</p>
                  </div>
                  <div className="food-inc-btn">
                    <p>KD 99.500</p>

                    <div class="counter-button">
                      <button>-</button>
                      <input
                        type="text"
                        name="ticket-count"
                        id="ticket-count"
                        placeholder="0"
                      />
                      <button>+</button>
                    </div>
                  </div>
                  <div className="food-remove-btn">
                    <a className="pointer-cursor">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                      Remove
                    </a>
                  </div>
                </div>
                <div className="food-quantity-container">
                  <div className="food-quantity-img">
                    <img
                      src={
                        require("../../assets/Large Popcorn Soda Combo.png")
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="food-quantity-name">
                    <p>
                      <b>Combo 1</b>
                    </p>
                    <p>Small Salt Popcorn Small Pepsi</p>
                  </div>
                  <div className="food-inc-btn">
                    <p>KD 99.500</p>
                    <div class="counter-button">
                      <button>-</button>
                      <input
                        type="text"
                        name="ticket-count"
                        id="ticket-count"
                        placeholder="0"
                      />
                      <button>+</button>
                    </div>
                  </div>
                  <div className="food-remove-btn">
                    <a className="pointer-cursor">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                      Remove
                    </a>
                  </div>
                </div>
              </div>
              <div className="food-price-total">
                <p>
                  Food Price{" "}
                  <span>
                    <b>KD 25.500</b>
                  </span>
                </p>
              </div>
            </div>
            <div className="booked-time-full">
              <p>TIME REMAINING 4:35</p>
              <div className="proceed-food-container">
                <button className="btnc btnc-ghost">Cancel</button>
                <button className="btnc btnc-ghost">Proceed</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ShowDetails;
