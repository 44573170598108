import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import * as Constants from "../Constants/index";
import axios from "axios";
import Text from "react-text";
import Modal from "react-modal";

class CinescapeLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cinemas: [],
      showmap: false,
    };
  }
  componentDidMount() {
    const token = localStorage.getItem("token");

    axios
      .get(Constants.api + "content/cinemas", "", {
        headers: {
          "Accept-Language": localStorage.getItem("language"),
          Authorization: `Bearer ${token}`,
          appversion: Constants.av,
          platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
        },
      })
      .then((res) => {
        console.log(res);

        console.log("cinemas");
        this.setState({
          cinemas: res.data.output.cinemas,
        });
      });
  }
  render() {
    const openmap = (lat, long) => {
      let map =
        "https://www.google.com/maps/embed/v1/place?key=AIzaSyAgURva0yoMANv5oC9zAsn_bCMuZGLlSFc&q=" +
        lat +
        "," +
        long;
      this.setState({ mapurl: map, showmap: true });
    };
    return (
      <>

        <div className="cinescape_booking_section">

          <div className="cinescap_location_section">
            <div class='home-content-container home-location'>
              <h2>
                {localStorage.getItem("language") !== "ar" ? (
                  "cinescape locations"
                ) : (
                  <Text>مواقع سينسكيب</Text>
                )}
              </h2>
              <div className="cinescap_info">
                {this.state.cinemas.map(function (item, i) {
                  let url = "/cinemasessions/" + item.id
                  return (
                    <>
                      <div className="cinescap_location">
                        <div className="flim_info">
                          <a href={url} > <img
                            src={item.webThumbImageUrl}
                            alt="img"
                          /></a>
                          <div className="house_text">
                            <h3>{item.name}</h3>
                            <div className="location_info">
                              <h4>
                                {" "}
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Location"
                                ) : (
                                  <Text>موقع</Text>
                                )}
                              </h4>
                              <p>{item.city} </p>
                              <p
                                className="map"
                                onClick={() =>
                                  openmap(item.latitude, item.longitude)
                                }
                              >
                                <div className="img-map">
                                  <img
                                    src={require("../assets/location.png").default}
                                  />
                                </div>
                                <div className="text-map">
                                  {" "}

                                  {localStorage.getItem("language") !== "ar" ? (

                                    "Maps"
                                  ) :
                                    (
                                      <Text>خرائط</Text>
                                    )}
                                </div>
                              </p>
                            </div>

                            <div className="cinescap_imgs">
                              <div className="cinescap_img-logo">
                                <img
                                  src={require("../assets/Standard Normal@2x.png").default}
                                  alt="img"
                                />
                              </div>
                              <div className="cinescap_img-logo">
                                <img
                                  src={require("../assets/VIP Normal@2x.png").default}
                                  alt="img"
                                />
                              </div>
                              <div className="cinescap_img-logo">
                                <img
                                  src={require("../assets/Premium Normal@2x.png").default}
                                  alt="img"
                                />
                              </div>
                              <div className="cinescap_img-logo">
                                <img
                                  src={require("../assets/Dolby Cinema Normal@2x.png").default}
                                  alt="img"
                                />
                              </div>
                              <div className="cinescap_img-logo">
                                <img
                                  src={require("../assets/IMAX Normal@2x.png").default}
                                  alt="img"
                                />
                              </div>
                              <div className="cinescap_img-logo">
                                <img
                                  src={require("../assets/4DX Normal@2x.png").default}
                                  alt="img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {
          this.state.showmap ? (
            <>
              <Modal
                isOpen={this.state.showmap}
                contentLabel="Example Modal"
                portalClassName="trai-modal trai-modal-one"
              >
                {" "}
                <button
                  className="trailer-cross trailer-btn"
                  onClick={() => this.setState({ showmap: false })}
                >
                  <i class="fas fa-times"></i>
                </button>
                <iframe
                  src={this.state.mapurl}
                  width="100%"
                  height="500"
                ></iframe>
              </Modal>
            </>
          ) : (
            ""
          )
        }
      </>
    );
  }
}
export default CinescapeLocation;
