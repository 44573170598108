import React, { Component } from "react";
import * as Constants from "../Constants/index";
import axios from "axios";
import Text from "react-text";
import Modal from "react-modal";

class allExperiences extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="seat-banner seat-banner-movie">
          <img src={require("../assets/seat-banner-new.jpg").default} alt="" />
          <h4>
            {localStorage.getItem("language") !== "ar" ? (
              "WORLD'S MOST INNOVATIVE"
            ) : (
              <Text>اختر مقعدًا بجولة</Text>
            )}
          </h4>
          <h3>
            {" "}
            {localStorage.getItem("language") !== "ar" ? (
              "MOVIE GOING EXPERIENCE"
            ) : (
              <Text>اختر مقعدًا بجولة</Text>
            )}
            <div className="black-width black-width-info"></div>
          </h3>
        </div>
        <div className="home-content-container">
          <div className="experience-container">
            <img src={require("../assets/dobly_img-1.png").default} />
            <p>
              {localStorage.getItem("language") !== "ar" ? (
                "where movies"
              ) : (
                <Text>
                  هنا تنبع الأفلام بالحياة
                </Text>
              )}
            </p>
            <h3>
              {localStorage.getItem("language") !== "ar" ? (
                "come to life"
              ) : (
                ""
              )}
            </h3>
            <button className="btnc btnc-ghost">
              <a href="/dolby">
                {localStorage.getItem("language") !== "ar" ? (
                  "EXPLORE"
                ) : (
                  <Text>تصفح</Text>
                )}
              </a>
            </button>
          </div>
          <div className="experience-container">
            <img src={require("../assets/4dxbanner.jpg").default} />
            <p>
              {localStorage.getItem("language") !== "ar" ? (
                "the new 4dx"
              ) : (
                <Text>تجربة حركية غامرة</Text>
              )}
            </p>
            <h3>
              {localStorage.getItem("language") !== "ar" ? (
                "technology unfolds"
              ) : (
                "")}
            </h3>
            <button className="btnc btnc-ghost">
              <a href="/4dx">
                {localStorage.getItem("language") !== "ar" ? (
                  "EXPLORE"
                ) : (
                  <Text>تصفح</Text>
                )}
              </a>
            </button>
          </div>
          <div className="experience-container">
            <img src={require("../assets/bg-topbanner (1).jpg").default} />
            <p>
              {localStorage.getItem("language") !== "ar" ? (
                "beyond"
              ) : (
                <Text>اختر مقعدًا بجولة</Text>
              )}
            </p>
            <h3>
              {localStorage.getItem("language") !== "ar" ? (
                "the frame"
              ) : (
                <Text>اختر مقعدًا بجولة</Text>
              )}
            </h3>
            <a href="/screenX">
              <button className="btnc btnc-ghost">
                {localStorage.getItem("language") !== "ar" ? (
                  "EXPLORE"
                ) : (
                  <Text>تصفح</Text>
                )}

              </button>
            </a>
          </div>
          <div className="experience-container">
            <img src={require("../assets/exp4.png").default} />
            <p>
              {localStorage.getItem("language") !== "ar" ? (
                "bigger is just"
              ) : (
                <Text>اختر مقعدًا بجولة</Text>
              )}
            </p>
            <h3>
              {localStorage.getItem("language") !== "ar" ? (
                "the beginning"
              ) : (
                <Text>اختر مقعدًا بجولة</Text>
              )}
            </h3>
            <a href="/imax">
              <button className="btnc btnc-ghost">
                {localStorage.getItem("language") !== "ar" ? (
                  "EXPLORE"
                ) : (
                  <Text>تصفح</Text>
                )}
              </button>
            </a>
          </div>
          <div className="experience-container">
            <img src={require("../assets/eleven.jpg").default} />
            <p>
              {localStorage.getItem("language") !== "ar" ? (
                "immersive"
              ) : (
                <Text>الصوت الغامر وتكنلوجيا الليزر
                </Text>
              )}
            </p>
            <h3>
              {localStorage.getItem("language") !== "ar" ? (
                "sound & laser"
              ) : (
                ""
              )}
            </h3>
            <p>
              {localStorage.getItem("language") !== "ar" ? (
                "technology"
              ) : (

                ""
                // <Text>اختر مقعدًا بجولة</Text>
              )}
            </p>
            <a href="/eleven">
              <button className="btnc btnc-ghost">
                {localStorage.getItem("language") !== "ar" ? (
                  "EXPLORE"
                ) : (
                  <Text>تصفح</Text>
                )}
              </button>
            </a>
          </div>
          <div className="experience-container">
            <img src="https://s3-eu-west-1.amazonaws.com/content.cinescape/Web-Eng/Content/images/experiences/07.jpg" />
            <p>
              {localStorage.getItem("language") !== "ar" ? (
                "Luxury"
              ) : (
                <Text>عندما تلتقي</Text>
              )}
            </p>
            <h3>
              {localStorage.getItem("language") !== "ar" ? (
                "meets comfort"
              ) : (
                <Text>الفخامة بالراحة</Text>
              )}
            </h3>

            <a href="/vip">
              <button className="btnc btnc-ghost">
                {localStorage.getItem("language") !== "ar" ? (
                  "EXPLORE"
                ) : (
                  <Text>تصفح</Text>
                )}
              </button>
            </a>
          </div>
        </div>
      </>
    );
  }
}
export default allExperiences;
