import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import * as Constants from "../Constants/index";
import axios from "axios";
import Text from "react-text";
import DatePicker from "react-datepicker";
import Select from "react-select";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReCAPTCHA from "react-google-recaptcha";




const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};



class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cinemas: [],
      selectedDate: null,
      selectedOptionOne: '',
      selectedOptionTwo: '',
      selectedOptionThree: '',
      selectedOptionFour: '',
      text: '',
      isCaptchaVerified: false,

    };


    this.handleChange = this.handleChange.bind(this)
  }

  handleCaptchaChange = (value) => {
    // The callback when the user solves the CAPTCHA
    this.setState({ isCaptchaVerified: true });
  }


  handleChange(value) {
    this.setState({ text: value });
  }

  handleDateChange = (date) => {
    this.setState({
      selectedDate: date,
    });
  };






  componentDidMount() {
    const token = localStorage.getItem("token");

    axios
      .get(Constants.api + "content/cinemas", "", {
        headers: {
          "Accept-Language": localStorage.getItem("language"),
          Authorization: `Bearer ${token}`,
          appversion: Constants.av,
          platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
        },
      })
      .then((res) => {
        console.log("cinesdasdsadasdmas");
        console.log(res);
        
        this.setState({
          cinemas: res.data.output.cinemas,
        });
      });
  }
  handleSelectChangeOne = (selectedOption1) => {
    this.setState({ selectedOptionOne: selectedOption1 });
  }
  handleSelectChangeTwo = (selectedOption2) => {
    this.setState({ selectedOptionTwo: selectedOption2 });
  }
  handleSelectChangeThree = (selectedOption3) => {
    this.setState({ selectedOptionThree: selectedOption3 });
  }
  handleSelectChangeFour = (selectedOption4) => {
    this.setState({ selectedOptionFour: selectedOption4 });
  }
  render() {

    const { selectedOptionOne } = this.state;
    const { selectedOptionTwo } = this.state;
    const { selectedOptionThree } = this.state;
    const { selectedOptionFour } = this.state;


    const optionsOne = [
      { value: "option1", label: "Option 1" },

      { value: "option2", label: "Option 2" },

      { value: "option3", label: "Option 3" },

    ];




    const openmap = (lat, long) => {
      console.log(lat);
      console.log(long);
      console.log('map page');
      let map =
        "https://www.google.com/maps/embed/v1/place?key=AIzaSyAgURva0yoMANv5oC9zAsn_bCMuZGLlSFc&q=" +
        lat +
        "," +
        long;
      this.setState({ mapurl: map, showmap: true });
      window.open(map, "_blank");
    };
    return (
      <>
        <div className="faq-banner seat-banner">
          <img src={require("../assets/my-account.png").default} alt="" />
          <h3>
            {" "}
            {localStorage.getItem("language") !== "ar" ? (
              "Contact Us"
            ) : (
              <Text>تواصل معنا
              </Text>
            )}
          </h3>
        </div>
        <div class="black-width"></div>
        <div className="faqs-container padbothvertical-40">
          <div class="home-content-containers">
            <div className="get-in-touch">
              <h1>
                {" "}
                {localStorage.getItem("language") !== "ar" ? (
                  "GET IN TOUCH "
                ) : (
                  <Text>وسائل التواصل
                  </Text>
                )}
              </h1>
              <div class="get-head">
                <div className="address">
                  <div className="address-icon">
                    <img
                      src={require("../assets/Address.png").default}
                      className="all-icon"
                      alt="Address"
                    />
                  </div>
                  <div className="address-content">
                    <h2>
                      {" "}
                      {localStorage.getItem("language") !== "ar" ? (
                        "Address"
                      ) : (
                        <Text>عنوان المكتب الرئيسي
                        </Text>
                      )}
                    </h2>
                    <p>
                      {" "}
                      {localStorage.getItem("language") !== "ar" ? (
                        "Khaitan,  Airport Road,  Sama Khaitan Mall, 2nd floor, P.O Box 502 Safat 13006 Kuwait."
                      ) : (
                        <Text>خيطان – طريق المطار – مول سما خيطان – الدور الثاني – ص . ب 502 الصفاة – رمز بريدي 13006 الكويت
                        </Text>
                      )}
                    </p>
                  </div>
                </div>
                <div className="address">
                  <div className="address-icon">
                    <img
                      src={require("../assets/Chat.png").default}
                      className="all-icon"
                      alt="Chat"
                    />
                  </div>
                  <div className="address-content">
                    <h2>
                      {" "}
                      {localStorage.getItem("language") !== "ar" ? (
                        "Chat with us on"
                      ) : (
                        <Text>دردش معنا على</Text>
                      )}
                    </h2>
                    <a href="https://api.whatsapp.com/send?phone=9651803456" target="_blank">
                      <img
                        src={require("../assets/Whatsapp.png").default}
                        className="all-icon"
                        alt="Whatsapp"
                      />{" "}
                      <span className="whats">
                        {" "}
                        {localStorage.getItem("language") !== "ar" ? (
                          "Whatsapp"
                        ) : (
                          <Text>واتس اب</Text>
                        )}
                      </span>
                    </a>
                  </div>
                </div>
                <div className="address">
                  <div className="address-icon">
                    <img
                      src={require("../assets/Call_Center.png").default}
                      className="all-icon"
                      alt="Call Center"
                    />
                  </div>
                  <div className="address-content">
                    <h2>
                      {" "}
                      {localStorage.getItem("language") !== "ar" ? (
                        "Call Centere"
                      ) : (
                        <Text>مركز خدمة العملاء
                        </Text>
                      )}
                    </h2>
                    <p>
                      {" "}
                      {localStorage.getItem("language") !== "ar" ? (
                        "+965 1803456"
                      ) : (
                        <Text>+965 1803456</Text>
                      )}
                    </p>
                  </div>
                </div>
                <div className="address">
                  <div className="address-icon">
                    <img
                      src={require("../assets/Phone.png").default}
                      className="all-icon"
                      alt="Phone"
                    />
                  </div>
                  <div className="address-content address-info">
                    <h2>
                      {" "}
                      {localStorage.getItem("language") !== "ar" ? (
                        "Phone"
                      ) : (
                        <Text>الهاتف</Text>
                      )}
                    </h2>
                    <p>+965 22086990</p>
                  </div>
                </div>
                <div className="address">
                  <div className="address-icon">
                    <img
                      src={require("../assets/Social.png").default}
                      className="all-icon"
                      alt="Phone"
                    />
                  </div>
                  <div className="address-content">
                    <h2>
                      {" "}
                      {localStorage.getItem("language") !== "ar" ? (
                        "Social Media"
                      ) : (
                        <Text>وسائل التواصل الاجتماعي</Text>
                      )}
                    </h2>
                    <div class="social-icons">
                      <a href="https://www.facebook.com/CinescapeKuwait" target="_blank">
                        <img
                          src={require("../assets/Facebook.png").default}
                          className="all-icon"
                          alt="Phone"
                        />
                      </a>
                      <a href="https://twitter.com/Cinescapekuwait" target="_blank">
                        <img
                          src={require("../assets/twitterimg.png").default}
                          className="all-icon"
                          alt="Phone"
                        />
                      </a>
                      <a href="https://www.instagram.com/cinescapekuwait/" target="_blank">
                        <img
                          src={require("../assets/Instagram.png").default}
                          className="all-icon"
                          alt="Phone"
                        />
                      </a>
                      <a href="https://www.youtube.com/@CinescapeKuwait" target="_blank">
                        <img
                          src={require("../assets/Youtube.png").default}
                          className="all-icon"
                          alt="Phone"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-container padbothvertical-40">
          <div className="home-content-containers">
            <div className="opinion-matter">
              <h1>
                {" "}
                {localStorage.getItem("language") !== "ar" ? (
                  "YOUR OPINION MATTERS"
                ) : (
                  <Text>رأيك يهمنا
                  </Text>
                )}
              </h1>
              <p className="text-white">
                {" "}
                {localStorage.getItem("language") !== "ar" ? (
                  "Have a question? Want an answer? Or some information?"
                ) : (
                  <Text>تبحث عن إجابة أو بعض المعلومات ؟
                  </Text>
                )}
              </p>
              <p class="op text-white">
                {" "}
                {localStorage.getItem("language") !== "ar" ? (
                  " What’s on your mind?Please write to us and we will get back to you as soon as we can."
                ) : (
                  <Text>
                   ربما تريد تقديم شكوى أو اقتراح، يمكنك مراسلتنا وسنعاود التواصل معك في أقرب وقت ممكن

                  </Text>
                )}
              </p>
              <div className="contact-us-form">

                <div className="filters-panel">
                  <iframe title="Feedback Form" class="freshwidget-embedded-form" id="freshwidget-embedded-form" src="https://kncc.freshdesk.com/widgets/feedback_widget/new?&amp;widgetType=embedded" scrolling="no" height="500px" width="100%" frameborder="0">

                  </iframe>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* <section className="location-section padbothvertical-40">
        <div className="home-content-containers">
                  <div className="main-head justify-flex">
                     <h3>
                        {localStorage.getItem("language") !== "ar" ? (
                           "cinescape locations"
                        ) : (
                           <Text>مواقع سينسكيب</Text>
                        )}
                     </h3>
                     <div className="view-all">
                        <a href="/cinelocation">
                           {localStorage.getItem("language") !== "ar" ? (
                              "view all locations"
                           ) : (
                              <Text>عرض كل المواقع</Text>
                           )}
                        </a>
                     </div>
                  </div>
                  <div className="location-container justify-flex-2">
                     {this.state.cinemas.map(function (item, i) {
                        let url = "/cinemasessions/" + item.id
                        return (

                           <>
                              <div className="location-preview">
                                 <div className="location-preview-img">
                                    <a href={url}>   <img src={item.webThumbImageUrl} /></a>
                                 </div>
                                 <div className="location-details">
                                    <p>{item.city}</p>
                                    <p>{item.name}</p>
                                 </div>
                              </div>

                           </>
                        );
                     })}
                  </div>
                  <div className="view-all view-all-mobile">
                     <a href="/cinelocation">
                        {localStorage.getItem("language") !== "ar" ? (
                           "VIEW ALL LOCATIONS"
                        ) : (
                           <Text>مشاهدة الكل</Text>
                        )}
                     </a>
                  </div>
                  </div>
        </section> */}
        <div className="home-content-containers">
          <div className="our-location">
            <h1>
              {" "}
              {localStorage.getItem("language") !== "ar" ? (
                "OUR LOCATIONS"
              ) : (
                <Text>مواقعنا</Text>
              )}
            </h1>
            <div className="cinescap_info">
              {this.state.cinemas.map(function (item, i) {
                let url = "/cinemasessions/" + item.id;
                return (
                  <>
                    <div className="cinescap_location">
                      <div className="flim_info">
                        <a href={url}>
                          {" "}
                          <img
                            src={item.webThumbImageUrl}
                            alt="img"
                            className="contact-locations"
                          />
                        </a>
                        <div className="house_text">
                          <h3>{item.name}</h3>
                          <div className="location_info">
                            <h4>
                              {" "}
                              {localStorage.getItem("language") !== "ar" ? (
                                "Location"
                              ) : (
                                <Text>موقع</Text>
                              )}
                            </h4>
                            <p>{item.city} </p>
                            <p
                              className="map"
                              onClick={() =>
                                openmap(item.latitude, item.longitude)
                              }
                            >
                              <div className="img-map">
                                <img
                                  src={
                                    require("../assets/location.png").default
                                  }
                                />
                              </div>
                              <div className="text-map">
                                {" "}
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Maps"
                                ) : (
                                  <Text>خرائط</Text>
                                )}
                              </div>
                            </p>
                          </div>

                          <div className="cinescap_imgs">
                            <div className="cinescap_img-logo">
                              <img
                                src={
                                  require("../assets/Standard Normal@3x.png").default
                                }
                                alt="img"
                              />
                            </div>
                            <div className="cinescap_img-logo">
                              <img
                                src={require("../assets/VIP Normal@3x.png").default}
                                alt="img"
                              />
                            </div>
                            <div className="cinescap_img-logo">
                              <img
                                src={
                                  require("../assets/Premium Normal@3x.png").default
                                }
                                alt="img"
                              />
                            </div>
                            <div className="cinescap_img-logo">
                              <img
                                src={require("../assets/Dolby Cinema Normal@3x.png").default}
                                alt="img"
                              />
                            </div>
                            <div className="cinescap_img-logo">
                              <img
                                src={require("../assets/IMAX Normal@3x.png").default}
                                alt="img"
                              />
                            </div>
                            <div className="cinescap_img-logo">
                              <img
                                src={require("../assets/4DX Normal@3x.png").default}
                                alt="img"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Contact;
